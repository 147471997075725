/*

Template: Medic - Health and Medical HTML Template
Version: 1.0.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.

*/
/*!
 * Bootstrap v5.0.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #28a745;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #28a745;
  --bs-info: #17a2b8;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }

*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }
  a:hover {
    color: #024dbc; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  -ms-overflow-style: scrollbar; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  white-space: normal; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1200px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
    border-bottom-width: 1px; }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #bbd6fe;
  --bs-table-striped-bg: #b3cdf3;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #acc4e9;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #afc9ee;
  --bs-table-hover-color: #212529;
  color: #212529;
  border-color: #acc4e9; }

.table-secondary {
  --bs-table-bg: #d6d8db;
  --bs-table-striped-bg: #cdcfd2;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #c4c6c9;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #c8cbce;
  --bs-table-hover-color: #212529;
  color: #212529;
  border-color: #c4c6c9; }

.table-success {
  --bs-table-bg: #c3e6cb;
  --bs-table-striped-bg: #bbdcc3;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #b3d3bb;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #b7d8bf;
  --bs-table-hover-color: #212529;
  color: #212529;
  border-color: #b3d3bb; }

.table-info {
  --bs-table-bg: #bee5eb;
  --bs-table-striped-bg: #b6dbe1;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #aed2d8;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #b2d7dc;
  --bs-table-hover-color: #212529;
  color: #212529;
  border-color: #aed2d8; }

.table-warning {
  --bs-table-bg: #ffeeba;
  --bs-table-striped-bg: #f4e4b3;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e9daac;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #eedfaf;
  --bs-table-hover-color: #212529;
  color: #212529;
  border-color: #e9daac; }

.table-danger {
  --bs-table-bg: #f5c6cb;
  --bs-table-striped-bg: #eabec3;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e0b6bb;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #e5babf;
  --bs-table-hover-color: #212529;
  color: #212529;
  border-color: #e0b6bb; }

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #edeef0;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e3e4e5;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #e8e9ea;
  --bs-table-hover-color: #212529;
  color: #212529;
  border-color: #e3e4e5; }

.table-dark {
  --bs-table-bg: #343a40;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #43494e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #484e53; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #8bbafe;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem; }

.form-control-color::-moz-color-swatch {
  border-radius: 0.25rem; }

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none; }
  .form-select:focus {
    border-color: #8bbafe;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
    .form-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.75em; }

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.125em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-check-input {
      transition: none; } }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #8bbafe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), var(--bs-gradient); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"), var(--bs-gradient); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"), var(--bs-gradient); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: .5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em; }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238bbafe'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"), var(--bs-gradient); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.form-file {
  --bs-form-file-height: calc(1.5em + 0.75rem + 2px);
  position: relative; }

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--bs-form-file-height);
  margin: 0;
  opacity: 0; }
  .form-file-input:focus-within ~ .form-file-label {
    border-color: #8bbafe;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-file-input[disabled] ~ .form-file-label .form-file-text,
  .form-file-input:disabled ~ .form-file-label .form-file-text {
    background-color: #e9ecef; }

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: var(--bs-form-file-height);
  border-color: #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit; }

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  background-image: var(--bs-gradient);
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit; }

.form-file-sm {
  --bs-form-file-height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.875rem; }
  .form-file-sm .form-file-text,
  .form-file-sm .form-file-button {
    padding: 0.25rem 0.5rem; }

.form-file-lg {
  --bs-form-file-height: calc(1.5em + 1rem + 2px);
  font-size: 1.25rem; }
  .form-file-lg .form-file-text,
  .form-file-lg .form-file-button {
    padding: 0.5rem 1rem; }

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: none; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    background-image: var(--bs-gradient);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #bed8fe;
      background-image: var(--bs-gradient); }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    background-image: var(--bs-gradient);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #bed8fe;
      background-image: var(--bs-gradient); }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0d6efd;
    background-image: var(--bs-gradient);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-ms-thumb {
        transition: none; } }
    .form-range::-ms-thumb:active {
      background-color: #bed8fe;
      background-image: var(--bs-gradient); }
  .form-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-ms-thumb {
      background-color: #adb5bd; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-file .form-file-input:focus ~ .form-file-label {
    z-index: 3; }
  .input-group > .form-file > .form-file-input:focus {
    z-index: 4; }
  .input-group > .form-file:not(:last-child) > .form-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-file:not(:first-child) > .form-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control {
  min-height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control {
  min-height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem; }

.input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #28a745; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #28a745; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #28a745; }

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #dc3545; }

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .btn-check:checked + .btn,
  .btn-check:active + .btn, .btn:active, .btn.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn-check:checked + .btn:focus,
    .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
    box-shadow: none; }

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  background-image: var(--bs-gradient);
  border-color: #0d6efd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background-color: #025ce2;
    background-image: var(--bs-gradient);
    border-color: #0257d5; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #025ce2;
    background-image: var(--bs-gradient);
    border-color: #0257d5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(49, 132, 253, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0257d5;
    background-image: none;
    border-color: #0252c9; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(49, 132, 253, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #0d6efd;
    background-image: none;
    border-color: #0d6efd; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  background-image: var(--bs-gradient);
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    background-image: var(--bs-gradient);
    border-color: #545b62; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #5a6268;
    background-image: var(--bs-gradient);
    border-color: #545b62;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    background-image: none;
    border-color: #4e555b; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #6c757d;
    background-image: none;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #28a745;
  background-image: var(--bs-gradient);
  border-color: #28a745;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    background-image: var(--bs-gradient);
    border-color: #1e7e34; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #218838;
    background-image: var(--bs-gradient);
    border-color: #1e7e34;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    background-image: none;
    border-color: #1c7430; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #28a745;
    background-image: none;
    border-color: #28a745; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  background-image: var(--bs-gradient);
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    background-image: var(--bs-gradient);
    border-color: #117a8b; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #fff;
    background-color: #138496;
    background-image: var(--bs-gradient);
    border-color: #117a8b;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    background-image: none;
    border-color: #10707f; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #fff;
    background-color: #17a2b8;
    background-image: none;
    border-color: #17a2b8; }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  background-image: var(--bs-gradient);
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    background-image: var(--bs-gradient);
    border-color: #d39e00; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #212529;
    background-color: #e0a800;
    background-image: var(--bs-gradient);
    border-color: #d39e00;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    background-image: none;
    border-color: #c69500; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #212529;
    background-color: #ffc107;
    background-image: none;
    border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  background-image: var(--bs-gradient);
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    background-image: var(--bs-gradient);
    border-color: #bd2130; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #c82333;
    background-image: var(--bs-gradient);
    border-color: #bd2130;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    background-image: none;
    border-color: #b21f2d; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    background-image: none;
    border-color: #dc3545; }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  background-image: var(--bs-gradient);
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    background-image: var(--bs-gradient);
    border-color: #dae0e5; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #212529;
    background-color: #e2e6ea;
    background-image: var(--bs-gradient);
    border-color: #dae0e5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    background-image: none;
    border-color: #d3d9df; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #212529;
    background-color: #f8f9fa;
    background-image: none;
    border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  background-image: var(--bs-gradient);
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    background-image: var(--bs-gradient);
    border-color: #1d2124; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #23272b;
    background-image: var(--bs-gradient);
    border-color: #1d2124;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    background-image: none;
    border-color: #171a1d; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #343a40;
    background-image: none;
    border-color: #343a40; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(13, 110, 253, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #0d6efd;
    background-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #6c757d;
    background-color: transparent; }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #28a745;
    background-color: transparent; }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #17a2b8;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f8f9fa;
    background-color: transparent; }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #343a40;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline; }
  .btn-link:hover {
    color: #024dbc; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    background-color: #f8f9fa;
    background-image: var(--bs-gradient); }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd;
    background-image: var(--bs-gradient); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
  background-image: var(--bs-gradient); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-image: var(--bs-gradient); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  align-items: center;
  width: 100%; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.2rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-collapse {
    display: flex !important; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #024dbc;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  background-image: var(--bs-gradient);
  border-color: #0d6efd; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-image: var(--bs-gradient); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.5rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1rem;
    color: inherit; }

.alert-primary {
  color: #073984;
  background-color: #cfe2ff;
  background-image: var(--bs-gradient);
  border-color: #bbd6fe; }
  .alert-primary .alert-link {
    color: #042454; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  background-image: var(--bs-gradient);
  border-color: #d6d8db; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  background-image: var(--bs-gradient);
  border-color: #c3e6cb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  background-image: var(--bs-gradient);
  border-color: #bee5eb; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  background-image: var(--bs-gradient);
  border-color: #ffeeba; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  background-image: var(--bs-gradient);
  border-color: #f5c6cb; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  background-image: var(--bs-gradient);
  border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  background-image: var(--bs-gradient);
  border-color: #c6c8ca; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #073984;
  background-color: #bbd6fe; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #073984;
    background-color: #a2c7fe; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #073984;
    border-color: #073984; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (min-width: 1200px) {
    .close {
      font-size: 1.5rem; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:hover, .close:focus {
    opacity: .75; }
  .close:disabled, .close.disabled {
    pointer-events: none; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-next {
  right: 0;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #0d6efd; }
  .link-primary:hover, .link-primary:focus {
    color: #024dbc; }

.link-secondary {
  color: #6c757d; }
  .link-secondary:hover, .link-secondary:focus {
    color: #494f54; }

.link-success {
  color: #28a745; }
  .link-success:hover, .link-success:focus {
    color: #19692c; }

.link-info {
  color: #17a2b8; }
  .link-info:hover, .link-info:focus {
    color: #0f6674; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ba8b00; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #a71d2a; }

.link-light {
  color: #f8f9fa; }
  .link-light:hover, .link-light:focus {
    color: #cbd3da; }

.link-dark {
  color: #343a40; }
  .link-dark:hover, .link-dark:focus {
    color: #121416; }

.embed-responsive {
  position: relative;
  width: 100%; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only,
.sr-only-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.ml-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -3rem !important; }

.mr-n1 {
  margin-right: -0.25rem !important; }

.mr-n2 {
  margin-right: -0.5rem !important; }

.mr-n3 {
  margin-right: -1rem !important; }

.mr-n4 {
  margin-right: -1.5rem !important; }

.mr-n5 {
  margin-right: -3rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -3rem !important; }

.ml-n1 {
  margin-left: -0.25rem !important; }

.ml-n2 {
  margin-left: -0.5rem !important; }

.ml-n3 {
  margin-left: -1rem !important; }

.ml-n4 {
  margin-left: -1.5rem !important; }

.ml-n5 {
  margin-left: -3rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-primary {
  color: #0d6efd !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

.text-info {
  color: #17a2b8 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #343a40 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.bg-primary {
  background-color: #0d6efd !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #343a40 !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.font-italic {
  font-style: italic !important; }

.font-normal {
  font-style: normal !important; }

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n1 {
    margin-right: -0.25rem !important; }
  .mr-sm-n2 {
    margin-right: -0.5rem !important; }
  .mr-sm-n3 {
    margin-right: -1rem !important; }
  .mr-sm-n4 {
    margin-right: -1.5rem !important; }
  .mr-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n1 {
    margin-left: -0.25rem !important; }
  .ml-sm-n2 {
    margin-left: -0.5rem !important; }
  .ml-sm-n3 {
    margin-left: -1rem !important; }
  .ml-sm-n4 {
    margin-left: -1.5rem !important; }
  .ml-sm-n5 {
    margin-left: -3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n1 {
    margin-right: -0.25rem !important; }
  .mr-md-n2 {
    margin-right: -0.5rem !important; }
  .mr-md-n3 {
    margin-right: -1rem !important; }
  .mr-md-n4 {
    margin-right: -1.5rem !important; }
  .mr-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n1 {
    margin-left: -0.25rem !important; }
  .ml-md-n2 {
    margin-left: -0.5rem !important; }
  .ml-md-n3 {
    margin-left: -1rem !important; }
  .ml-md-n4 {
    margin-left: -1.5rem !important; }
  .ml-md-n5 {
    margin-left: -3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n1 {
    margin-right: -0.25rem !important; }
  .mr-lg-n2 {
    margin-right: -0.5rem !important; }
  .mr-lg-n3 {
    margin-right: -1rem !important; }
  .mr-lg-n4 {
    margin-right: -1.5rem !important; }
  .mr-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n1 {
    margin-left: -0.25rem !important; }
  .ml-lg-n2 {
    margin-left: -0.5rem !important; }
  .ml-lg-n3 {
    margin-left: -1rem !important; }
  .ml-lg-n4 {
    margin-left: -1.5rem !important; }
  .ml-lg-n5 {
    margin-left: -3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n1 {
    margin-right: -0.25rem !important; }
  .mr-xl-n2 {
    margin-right: -0.5rem !important; }
  .mr-xl-n3 {
    margin-right: -1rem !important; }
  .mr-xl-n4 {
    margin-right: -1.5rem !important; }
  .mr-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n1 {
    margin-left: -0.25rem !important; }
  .ml-xl-n2 {
    margin-left: -0.5rem !important; }
  .ml-xl-n3 {
    margin-left: -1rem !important; }
  .ml-xl-n4 {
    margin-left: -1.5rem !important; }
  .ml-xl-n5 {
    margin-left: -3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-0 {
    margin-right: 0 !important; }
  .mr-xxl-1 {
    margin-right: 0.25rem !important; }
  .mr-xxl-2 {
    margin-right: 0.5rem !important; }
  .mr-xxl-3 {
    margin-right: 1rem !important; }
  .mr-xxl-4 {
    margin-right: 1.5rem !important; }
  .mr-xxl-5 {
    margin-right: 3rem !important; }
  .mr-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-0 {
    margin-left: 0 !important; }
  .ml-xxl-1 {
    margin-left: 0.25rem !important; }
  .ml-xxl-2 {
    margin-left: 0.5rem !important; }
  .ml-xxl-3 {
    margin-left: 1rem !important; }
  .ml-xxl-4 {
    margin-left: 1.5rem !important; }
  .ml-xxl-5 {
    margin-left: 3rem !important; }
  .ml-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xxl-n3 {
    margin-top: -1rem !important; }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mr-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mr-xxl-n3 {
    margin-right: -1rem !important; }
  .mr-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mr-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n1 {
    margin-left: -0.25rem !important; }
  .ml-xxl-n2 {
    margin-left: -0.5rem !important; }
  .ml-xxl-n3 {
    margin-left: -1rem !important; }
  .ml-xxl-n4 {
    margin-left: -1.5rem !important; }
  .ml-xxl-n5 {
    margin-left: -3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-0 {
    padding-right: 0 !important; }
  .pr-xxl-1 {
    padding-right: 0.25rem !important; }
  .pr-xxl-2 {
    padding-right: 0.5rem !important; }
  .pr-xxl-3 {
    padding-right: 1rem !important; }
  .pr-xxl-4 {
    padding-right: 1.5rem !important; }
  .pr-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-0 {
    padding-left: 0 !important; }
  .pl-xxl-1 {
    padding-left: 0.25rem !important; }
  .pl-xxl-2 {
    padding-left: 0.5rem !important; }
  .pl-xxl-3 {
    padding-left: 1rem !important; }
  .pl-xxl-4 {
    padding-left: 1.5rem !important; }
  .pl-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

/*****************************
  Typo
*****************************/
body {
  font-family: "Fira Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: #666666;
  overflow-x: hidden; }

a {
  outline: medium none !important;
  text-decoration: none !important;
  color: #0576ee;
  transition: all 0.3s ease-in-out; }
  a:focus {
    color: #0576ee;
    text-decoration: none !important; }
  a:hover {
    color: #0576ee;
    text-decoration: none !important; }

input {
  outline: medium none !important;
  color: #0576ee; }

h1, .h1, .h1, h2, .h2, .h2, h3, .h3, .h3, h4, .h4, .h4, h5, .h5, .h5, h6, .h6, .h6 {
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  color: #333333;
  margin-top: 0px;
  text-transform: capitalize; }
  h1 a, .h1 a, .h1 a,
  h2 a,
  .h2 a, .h2 a,
  h3 a,
  .h3 a, .h3 a,
  h4 a,
  .h4 a, .h4 a,
  h5 a,
  .h5 a, .h5 a,
  h6 a,
  .h6 a, .h6 a {
    color: inherit; }

section {
  position: relative; }

label {
  font-weight: normal;
  margin-bottom: 10px; }

h1, .h1, .h1 {
  font-size: 48px;
  font-style: normal; }

h2, .h2, .h2 {
  font-size: 40px; }

h3, .h3, .h3 {
  font-size: 30px; }

h4, .h4, .h4 {
  font-size: 24px;
  font-weight: bold; }

h5, .h5, .h5 {
  font-size: 20px;
  font-weight: bold; }

h6, .h6, .h6 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5; }

p {
  font-weight: normal;
  line-height: 1.8; }

*::-moz-selection {
  background: #0576ee;
  color: #ffffff;
  text-shadow: none; }

::-moz-selection {
  background: #0576ee;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background: #0576ee;
  color: #ffffff;
  text-shadow: none; }

.container-fluid.container-space, .container-space.container-sm, .container-space.container-md, .container-space.container-lg, .container-space.container-xl, .container-space.container-xxl {
  padding: 0 100px; }

.form-control {
  border-color: #dfdfdf;
  border-radius: 0px;
  height: auto;
  padding: 10px 20px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  background-clip: inherit !important;
  border-radius: 4px; }
  .form-control:focus {
    box-shadow: none;
    border-color: #0576ee; }

.form-control::-moz-placeholder {
  color: #999999; }

.form-control::-ms-input-placeholder {
  color: #999999; }

.form-control::-webkit-input-placeholder {
  color: #999999; }

form .form-group .input-group-append {
  display: flex; }
  form .form-group .input-group-append .input-group-text {
    border: 1px solid #dfdfdf;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.custom-file {
  height: 50px; }
  .custom-file .custom-file-input {
    height: 50px;
    color: #333333;
    border-radius: 4px;
    border-color: #eeeeee; }
    .custom-file .custom-file-input:focus {
      box-shadow: none;
      border-color: #0576ee; }
  .custom-file .custom-file-label {
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    border-color: #eeeeee;
    padding: 15px 90px 15px 20px;
    border-radius: 4px; }
    .custom-file .custom-file-label:after {
      height: 48px;
      padding: 14px 25px; }

.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none;
  border-color: #0576ee; }

.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
  width: 50px;
  background: transparent;
  border-color: #eeeeee;
  box-shadow: none;
  justify-content: center; }

.custom-control-input:checked ~ .custom-control-label:before {
  background: #0576ee;
  border-color: #0576ee; }

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  background: transparent;
  border-color: transparent; }

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none; }

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #eeeeee; }

.custom-control-label:before {
  top: 2px; }

.custom-control-label:after {
  top: 2px; }

.custom-checkbox .custom-control-label:before {
  border-radius: 0;
  border: 1px solid #dfdfdf; }

.border {
  border-color: #eeeeee !important; }

.border-top {
  border-top-color: #eeeeee !important; }

.border-left {
  border-left-color: #eeeeee !important; }

.border-right {
  border-right-color: #eeeeee !important; }

.border-bottom {
  border-bottom-color: #eeeeee !important; }

.badge {
  border-radius: 4px; }
  .badge + .badge {
    margin-left: 6px; }

.badge-md {
  padding: 5px 8px;
  font-size: 13px;
  font-weight: normal; }

.badge-primary {
  background: #0576ee; }

.back-to-top {
  background: #0576ee;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  z-index: 9; }
  .back-to-top:hover {
    color: #ffffff; }
  .back-to-top:focus {
    color: #ffffff; }

.breadcrumb {
  z-index: 9;
  font-weight: 700;
  background: #ffffff;
  display: inline-flex;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  font-family: "Fira Sans", sans-serif;
  position: absolute;
  bottom: -20px; }
  .breadcrumb .breadcrumb-item {
    padding-right: 5px;
    align-items: center; }
    .breadcrumb .breadcrumb-item a {
      color: #333333;
      font-size: 16px; }
      .breadcrumb .breadcrumb-item a:hover {
        color: #0576ee; }
    .breadcrumb .breadcrumb-item i {
      padding-right: 10px;
      font-size: 14px;
      color: #333333; }
    .breadcrumb .breadcrumb-item:before {
      display: none; }
  .breadcrumb .active span {
    color: #0576ee;
    font-size: 16px; }

.pagination .page-item .page-link {
  font-size: 18px;
  font-weight: 700;
  padding: 12px 20px;
  color: #666666;
  border: 2px solid #dfdfdf;
  margin-right: 15px;
  border-radius: 4px; }
  .pagination .page-item .page-link:hover {
    background: #0576ee;
    border-color: #0576ee;
    color: #ffffff; }
  .pagination .page-item .page-link:focus {
    box-shadow: none; }

.pagination .page-item:last-child .page-link {
  margin-right: 0; }

.pagination .page-item.active .page-link {
  background: #0576ee;
  border-color: #0576ee;
  color: #ffffff; }

.blockquote {
  font-size: 16px;
  border-left: 10px solid #0576ee;
  padding-left: 30px;
  font-style: italic;
  display: block; }

.blockquote-quote {
  position: relative;
  font-size: 18px;
  border-left: 0 solid #0576ee;
  padding-left: 30px;
  font-style: italic;
  display: block;
  z-index: 1; }
  .blockquote-quote p {
    position: relative; }
  .blockquote-quote i {
    position: absolute;
    z-index: 0;
    top: 20px;
    left: 0;
    font-size: 60px;
    line-height: 60px;
    color: #dfdfdf; }

.table-bordered td {
  border-color: #eeeeee; }

.table-bordered th {
  border-color: #eeeeee; }

.table thead th {
  border-bottom-color: #eeeeee; }

.table-striped tbody tr:nth-of-type(odd) {
  background: #f6f6f6; }

.table td, .table th {
  vertical-align: middle; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 96%; } }

@media (max-width: 767px) {
  h1, .h1 {
    font-size: 32px; }
  h2, .h2 {
    font-size: 28px; }
  h3, .h3 {
    font-size: 24px; }
  h4, .h4 {
    font-size: 20px; }
  h5, .h5 {
    font-size: 18px; }
  h6, .h6 {
    font-size: 16px; }
  .container {
    max-width: 100%; } }

@media (max-width: 575px) {
  h1, .h1 {
    font-size: 30px; }
  h2, .h2 {
    font-size: 26px; }
  h3, .h3 {
    font-size: 24px; } }

/*****************************
  Helper Classes
*****************************/
/* Text Color */
.text-primary {
  color: #0576ee !important; }

.text-secondary {
  color: #0576ee !important; }

.text-dark {
  color: #333333 !important; }

.text-light {
  color: #f6f6f6 !important; }

.text-muted {
  color: #1d2936 !important; }

/* Background Color */
.bg-primary {
  background-color: #0576ee !important; }

.bg-secondary {
  background-color: #0576ee !important; }

.bg-dark {
  background-color: #000f2d !important; }

.bg-gray {
  background-color: #dfdfdf !important; }

.bg-light {
  background-color: #f6f6f6 !important; }

/* Border radius */
.border-radius-10 {
  border-radius: 10px; }

.shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05) !important; }

/* Page section margin padding */
.space-lg-ptb {
  padding: 160px 0; }

.space-lg-pt {
  padding-top: 160px; }

.space-lg-pb {
  padding-bottom: 160px; }

.space-ptb {
  padding: 100px 0; }

.space-pt {
  padding-top: 100px; }

.space-pb {
  padding-bottom: 100px; }

.space-sm-ptb {
  padding: 70px 0; }

.space-sm-pt {
  padding-top: 70px; }

.space-sm-pb {
  padding-bottom: 70px; }

.space-lg-mt {
  margin-top: 150px; }

/* position */
.top-0 {
  top: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.top-50 {
  top: 50%;
  transform: translateY(-50%); }

.left-50 {
  left: 50%;
  transform: translateX(-50%); }

/* z index */
.z-index-1 {
  z-index: 1; }

.z-index-9 {
  z-index: 9; }

.z-index-99 {
  z-index: 99; }

.z-index-999 {
  z-index: 999; }

.z-index-n1 {
  z-index: -1; }

.z-index-n9 {
  z-index: -9; }

/* Opacity */
.opacity-10 {
  opacity: 0.1; }

.opacity-20 {
  opacity: 0.2; }

.opacity-30 {
  opacity: 0.3; }

.opacity-40 {
  opacity: 0.4; }

.opacity-50 {
  opacity: 0.5; }

.opacity-60 {
  opacity: 0.6; }

.opacity-70 {
  opacity: 0.7; }

.opacity-80 {
  opacity: 0.8; }

.opacity-90 {
  opacity: 0.9; }

.opacity-100 {
  opacity: 1; }

/* Font Size */
.display-1 {
  font-size: 9rem;
  line-height: 1;
  font-weight: 800; }

.display-2 {
  font-size: 8rem;
  line-height: 1.2;
  font-weight: 800; }

.display-3 {
  font-size: 7rem;
  line-height: 1.2;
  font-weight: 800; }

.display-4 {
  font-size: 6rem;
  line-height: 1.2;
  font-weight: 800; }

.display-5 {
  font-size: 5rem;
  line-height: 1.2; }

.display-6 {
  font-size: 4rem;
  line-height: 1.2; }

.display-7 {
  font-size: 3rem;
  line-height: 1.2; }

.display-8 {
  font-size: 2.5rem;
  line-height: 1.2; }

.display-9 {
  font-size: 1.5rem;
  line-height: 1.4; }

.display-10 {
  font-size: 1rem;
  line-height: 1.4; }

/* Overflow hidden */
.overflow-y-hidden {
  overflow-y: hidden; }

.overflow-x-hidden {
  overflow-x: hidden; }

.grayscale, .img-grayscale {
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out; }

.grayscale:hover {
  filter: inherit; }

/*****************************
  Display Responsive
*****************************/
@media (max-width: 1199px) {
  /* Font Size */
  .display-1 {
    font-size: 8rem; }
  .display-2 {
    font-size: 7rem; }
  .display-3 {
    font-size: 6rem; }
  .display-4 {
    font-size: 5rem; }
  .display-5 {
    font-size: 4.5rem; }
  .display-6 {
    font-size: 4rem; }
  .display-7 {
    font-size: 2.5rem; }
  .display-8 {
    font-size: 2rem; } }

@media (max-width: 991px) {
  /* Font Size */
  .display-1 {
    font-size: 7rem; }
  .display-2 {
    font-size: 6rem; }
  .display-3 {
    font-size: 5rem; }
  .display-4 {
    font-size: 4.5rem; }
  .display-5 {
    font-size: 4rem; }
  .display-6 {
    font-size: 3rem; } }

@media (max-width: 767px) {
  /* Font Size */
  .display-1 {
    font-size: 6rem; }
  .display-2 {
    font-size: 5rem; }
  .display-3 {
    font-size: 4rem; }
  .display-4 {
    font-size: 3.5rem; }
  .display-5 {
    font-size: 3rem; }
  .display-6 {
    font-size: 2.5rem; }
  .display-7 {
    font-size: 2.3rem; }
  .display-9 {
    font-size: 1.3rem; } }

@media (max-width: 575px) {
  /* Font Size */
  .display-1 {
    font-size: 5rem; }
  .display-2 {
    font-size: 4rem; }
  .display-3 {
    font-size: 3rem; }
  .display-4 {
    font-size: 2.5rem; }
  .display-5 {
    font-size: 2rem; }
  .display-6 {
    font-size: 1.8rem; }
  .display-7 {
    font-size: 1.6rem; }
  .display-8 {
    font-size: 1.5rem; }
  .display-9 {
    font-size: 1.2rem; } }

/* Height */
.h-100vh {
  height: 100vh !important; }

.h-900 {
  height: 900px; }

.h-800 {
  height: 800px; }

.h-700 {
  height: 700px; }

.h-600 {
  height: 600px; }

.h-500 {
  height: 500px; }

.h-400 {
  height: 400px; }

.h-300 {
  height: 300px; }

/*****************************
  Height Responsive
*****************************/
@media (max-width: 1199px) {
  /* Height */
  .h-lg-800 {
    height: 800px; }
  .h-lg-700 {
    height: 700px; }
  .h-lg-600 {
    height: 600px; }
  .h-lg-500 {
    height: 500px; }
  .h-lg-400 {
    height: 400px; }
  .h-lg-300 {
    height: 300px; } }

@media (max-width: 991px) {
  /* Height */
  .h-md-800 {
    height: 800px; }
  .h-md-700 {
    height: 700px; }
  .h-md-600 {
    height: 600px; }
  .h-md-500 {
    height: 500px; }
  .h-md-400 {
    height: 400px; }
  .h-md-300 {
    height: 300px; }
  .h-md-auto {
    height: auto; } }

@media (max-width: 767px) {
  /* Height */
  .h-sm-600 {
    height: 600px; }
  .h-sm-500 {
    height: 500px; }
  .h-sm-400 {
    height: 400px; }
  .h-sm-300 {
    height: 300px; }
  .h-sm-auto {
    height: auto; } }

/* padding */
.p-6 {
  padding: 5rem !important; }

.pt-6,
.py-6 {
  padding-top: 5rem !important; }

.pr-6,
.px-6 {
  padding-right: 5rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 5rem !important; }

.pl-6,
.px-6 {
  padding-left: 5rem !important; }

/* margin */
.m-6 {
  margin: 5rem !important; }

.mr-6,
.mx-6 {
  margin-right: 5rem !important; }

.ml-6,
.mx-6 {
  margin-left: 5rem !important; }

.mt-6,
.my-6 {
  margin-top: 5rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 5rem !important; }

.m-7 {
  margin: 7rem !important; }

.mr-7,
.mx-7 {
  margin-right: 7rem !important; }

.ml-7,
.mx-7 {
  margin-left: 7rem !important; }

.mt-7,
.my-7 {
  margin-top: 7rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 7rem !important; }

.m-8 {
  margin: 10rem !important; }

.mr-8,
.mx-8 {
  margin-right: 10rem !important; }

.ml-8,
.mx-8 {
  margin-left: 10rem !important; }

.mt-8,
.my-8 {
  margin-top: 10rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 10rem !important; }

.m-9 {
  margin: 12rem !important; }

.mr-9,
.mx-9 {
  margin-right: 12rem !important; }

.ml-9,
.mx-9 {
  margin-left: 12rem !important; }

.mt-9,
.my-9 {
  margin-top: 12rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 12rem !important; }

.m-10 {
  margin: 14rem !important; }

.mr-10,
.mx-10 {
  margin-right: 14rem !important; }

.ml-10,
.mx-10 {
  margin-left: 14rem !important; }

.mt-10,
.my-10 {
  margin-top: 14rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 14rem !important; }

.m-n6 {
  margin: -5rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -5rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -5rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -5rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -5rem !important; }

.m-n7 {
  margin: -7rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -7rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -7rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -7rem !important; }

.m-n8 {
  margin: -10rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -10rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -10rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -10rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -10rem !important; }

.m-n9 {
  margin: -12rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -12rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -12rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -12rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -12rem !important; }

.m-n10 {
  margin: -14rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -14rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -14rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -14rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -14rem !important; }

@media (min-width: 576px) {
  /* Margin */
  .m-sm-6 {
    margin: 5rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 5rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 5rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 5rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 5rem !important; }
  .m-sm-n6 {
    margin: -5rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -5rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -5rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -5rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -5rem !important; }
  .m-sm-7 {
    margin: 7rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important; }
  .m-sm-n7 {
    margin: -7rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important; }
  .m-sm-8 {
    margin: 10rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 10rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 10rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 10rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 10rem !important; }
  .ml-sm-n9 {
    margin-left: -12rem !important; }
  .ml-sm-n10 {
    margin-left: -14rem !important; }
  /* Padding */
  .p-sm-6 {
    padding: 5rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important; }
  .pr-sm-6,
  .py-sm-6 {
    padding-right: 5rem !important; }
  /* border */
  .border-sm-0 {
    border: 0 !important; }
  .border-sm-top {
    border-top: 1px solid #eeeeee !important; }
  .border-sm-right {
    border-right: 1px solid #eeeeee !important; }
  .border-sm-bottom {
    border-bottom: 1px solid #eeeeee !important; }
  .border-sm-left {
    border-left: 1px solid #eeeeee !important; } }

@media (min-width: 768px) {
  /* Margin */
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important; }
  .m-md-6 {
    margin: 5rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 5rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 5rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 5rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 5rem !important; }
  .m-md-n6 {
    margin: -5rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -5rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -5rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -5rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -5rem !important; }
  .m-md-7 {
    margin: 7rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important; }
  .m-md-n7 {
    margin: -7rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important; }
  .m-md-8 {
    margin: 10rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 10rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 10rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 10rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 10rem !important; }
  .ml-md-n9 {
    margin-left: -12rem !important; }
  .ml-md-n10 {
    margin-left: -14rem !important; }
  /* Padding */
  .py-md-0 {
    padding-bottom: 0rem !important;
    padding-top: 0rem !important; }
  .p-md-6 {
    padding: 5rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important; }
  /* border */
  .border-md-0 {
    border: 0 !important; }
  .border-md-top {
    border-top: 1px solid #eeeeee !important; }
  .border-md-right {
    border-right: 1px solid #eeeeee !important; }
  .border-md-bottom {
    border-bottom: 1px solid #eeeeee !important; }
  .border-md-left {
    border-left: 1px solid #eeeeee !important; } }

@media (min-width: 992px) {
  /* Margin */
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important; }
  .m-lg-6 {
    margin: 5rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 5rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 5rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 5rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 5rem !important; }
  .m-lg-n6 {
    margin: -5rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -5rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -5rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -5rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -5rem !important; }
  .m-lg-7 {
    margin: 7rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important; }
  .m-lg-n7 {
    margin: -7rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important; }
  .m-lg-8 {
    margin: 10rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 10rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 10rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 10rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 10rem !important; }
  .ml-lg-n9 {
    margin-left: -12rem !important; }
  .ml-lg-n10 {
    margin-left: -14rem !important; }
  /* Padding */
  .p-lg-6 {
    padding: 5rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important; }
  /* border */
  .border-lg-0 {
    border: 0 !important; }
  .border-lg-top {
    border-top: 1px solid #eeeeee !important; }
  .border-lg-right {
    border-right: 1px solid #eeeeee !important; }
  .border-lg-bottom {
    border-bottom: 1px solid #eeeeee !important; }
  .border-lg-left {
    border-left: 1px solid #eeeeee !important; } }

@media (min-width: 1200px) {
  /* Margin */
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important; }
  .m-xl-6 {
    margin: 5rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 5rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 5rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 5rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 5rem !important; }
  .m-xl-n6 {
    margin: -5rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -5rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -5rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -5rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -5rem !important; }
  .m-xl-7 {
    margin: 7rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important; }
  .m-xl-n7 {
    margin: -7rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important; }
  .m-xl-8 {
    margin: 10rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 10rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 10rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 10rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 10rem !important; }
  .ml-xl-n9 {
    margin-left: -12rem !important; }
  .ml-xl-n10 {
    margin-left: -14rem !important; }
  /* Padding */
  .p-xl-6 {
    padding: 5rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important; }
  /* border */
  .border-xl-0 {
    border: 0 !important; }
  .border-xl-top {
    border-top: 1px solid #eeeeee !important; }
  .border-xl-right {
    border-right: 1px solid #eeeeee !important; }
  .border-xl-bottom {
    border-bottom: 1px solid #eeeeee !important; }
  .border-xl-left {
    border-left: 1px solid #eeeeee !important; } }

/* pre-loader */
#pre-loader {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999; }

#pre-loader img {
  text-align: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  margin: 0 auto; }

/* Background overlay */
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%; }

.bg-overlay-black-10 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-10:before {
    background: rgba(0, 15, 45, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-20:before {
    background: rgba(0, 15, 45, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-30 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-30:before {
    background: rgba(0, 15, 45, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-40 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-40:before {
    background: rgba(0, 15, 45, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-50:before {
    background: rgba(0, 15, 45, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-60 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-60:before {
    background: rgba(0, 15, 45, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-70:before {
    background: rgba(0, 15, 45, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-80 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-80:before {
    background: rgba(0, 15, 45, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-90:before {
    background: rgba(0, 15, 45, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-10 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-10:before {
    background: rgba(255, 255, 255, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-20:before {
    background: rgba(255, 255, 255, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-30 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-30:before {
    background: rgba(255, 255, 255, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-40 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-40:before {
    background: rgba(255, 255, 255, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-50:before {
    background: rgba(255, 255, 255, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-60 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-60:before {
    background: rgba(255, 255, 255, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-70:before {
    background: rgba(255, 255, 255, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-80 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-80:before {
    background: rgba(255, 255, 255, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-90:before {
    background: rgba(255, 255, 255, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-primary-10 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-10:before {
    background: rgba(5, 118, 238, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-primary-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-20:before {
    background: rgba(5, 118, 238, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-primary-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-50:before {
    background: rgba(5, 118, 238, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-primary-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-70:before {
    background: rgba(5, 118, 238, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-primary-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-90:before {
    background: rgba(5, 118, 238, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

/* avatar */
.avatar {
  width: 50px;
  height: 50px;
  display: inline-block; }

.avatar.avatar-sm {
  width: 20px;
  height: 20px; }

.avatar.avatar-md {
  width: 80px;
  height: 80px; }

.avatar.avatar-lg {
  width: 145px;
  height: 145px; }

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

/* pagination */
.pagination .page-link {
  color: #0576ee; }

/* Contact-info */
.contact-info ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px; }
  .contact-info ul li:last-child {
    margin-bottom: 0; }
  .contact-info ul li span {
    margin-left: 15px; }
  .contact-info ul li i {
    margin-top: 5px; }

.post-navigation .nav-previous a {
  padding: 0;
  text-align: left; }
  .post-navigation .nav-previous a span {
    margin-left: 0;
    margin-right: 15px; }
    .post-navigation .nav-previous a span:before {
      right: auto;
      left: 0;
      transform: rotate(-45deg); }
    .post-navigation .nav-previous a span:after {
      right: auto;
      left: 0;
      transform: rotate(45deg); }
  .post-navigation .nav-previous a:hover {
    color: #0576ee; }
  .post-navigation .nav-previous a:focus {
    color: #0576ee; }
  .post-navigation .nav-previous a:active {
    color: #0576ee; }

.post-navigation .nav-next a {
  padding: 0;
  text-align: right; }
  .post-navigation .nav-next a:hover {
    color: #0576ee; }
  .post-navigation .nav-next a:focus {
    color: #0576ee; }
  .post-navigation .nav-next a:active {
    color: #0576ee; }

.post-navigation .nav-title {
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
  display: inline-block; }

.post-tags a {
  color: #666666; }
  .post-tags a:hover {
    color: #0576ee; }

@media (max-width: 991px) {
  /* Page section margin padding */
  .space-lg-ptb {
    padding: 70px 0; }
  .space-lg-pt {
    padding-top: 70px; }
  .space-lg-pb {
    padding-bottom: 70px; }
  .space-ptb {
    padding: 70px 0; }
  .space-pt {
    padding-top: 70px; }
  .space-pb {
    padding-bottom: 70px; }
  .space-sm-ptb {
    padding: 50px 0; }
  .space-sm-pt {
    padding-top: 50px; }
  .space-sm-pb {
    padding-bottom: 50px; }
  .space-lg-mt {
    margin-top: 100px; } }

@media (max-width: 767px) {
  /* container */
  .container {
    max-width: 100%; }
  /* Page section margin padding */
  .space-lg-ptb {
    padding: 50px 0; }
  .space-lg-pt {
    padding-top: 50px; }
  .space-lg-pb {
    padding-bottom: 50px; }
  .space-ptb {
    padding: 50px 0; }
  .space-pt {
    padding-top: 50px; }
  .space-pb {
    padding-bottom: 50px; }
  .space-sm-ptb {
    padding: 30px 0; }
  .space-sm-pt {
    padding-top: 30px; }
  .space-sm-pb {
    padding-bottom: 30px; }
  .space-lg-mt {
    margin-top: 70px; } }

/*** SVG IE11 specific styles ***/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .clipped-title {
    background-image: none; }
  .team.team-02 .team-img .shape-04 {
    height: 168px; }
  .bg-primary-gradient .counter .counter-icon i {
    color: #000f2d;
    background: transparent !important; }
  .feature-step-item.bg-primary .step-number {
    background: transparent !important;
    color: #ffffff; } }

/*****************************
  Accordion
*****************************/
.accordion .card {
  border: none;
  padding: 0px;
  margin-bottom: 15px;
  border-radius: 4px;
  background: transparent; }
  .accordion .card .card-header {
    background: none;
    border-bottom: none;
    padding: 0;
    margin-bottom: 0; }
    .accordion .card .card-header button {
      position: relative;
      font-size: 18px;
      color: #0576ee;
      padding: 15px 25px 15px 25px;
      width: 100%;
      text-align: left;
      border: none;
      background: #ffffff;
      border: 1px solid #eeeeee;
      text-decoration: none; }
      .accordion .card .card-header button:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        content: "\f146";
        font-size: 16px;
        font-family: "Font Awesome 5 Free";
        border-radius: 50%;
        z-index: 9;
        font-weight: 900;
        text-align: center;
        color: #0576ee; }
    .accordion .card .card-header button.collapsed {
      color: #000f2d;
      background: #ffffff;
      border: 1px solid #eeeeee; }
      .accordion .card .card-header button.collapsed:before {
        color: #000f2d; }

.accordion .card .card-header button.collapsed:before {
  content: "\f0fe"; }

/* accordion-02 */
.accordion.accordion-02 .card {
  padding: 8px 0px; }
  .accordion.accordion-02 .card .card-header button {
    font-size: 14px;
    padding: 0px 0px 0px 30px;
    border: none; }
    .accordion.accordion-02 .card .card-header button:before {
      width: 15px;
      height: 15px;
      line-height: 14px;
      font-size: 14px;
      left: 0px; }
    .accordion.accordion-02 .card .card-header button:after {
      display: none; }
  .accordion.accordion-02 .card .card-body {
    padding: 10px 0px 0px 30px; }

/*****************************
  Button
*****************************/
.btn {
  transition: all 0.3s ease-in-out; }

button {
  outline: medium none !important;
  color: #0576ee; }

/* btn */
.btn {
  font-size: 14px;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 4px; }
  .btn:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
    outline: none; }
  .btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff; }
  .btn:hover {
    box-shadow: none;
    outline: none; }
  .btn:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff; }
  .btn:active {
    box-shadow: none;
    outline: none;
    color: #ffffff; }

.show > .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
  color: #ffffff; }

.show > .btn-primary.dropdown-toggle {
  background: #0576ee;
  border-color: #0576ee; }

.btn-primary {
  background: #0576ee;
  border-color: #0576ee; }
  .btn-primary:hover {
    background: #0467d0;
    border-color: #0467d0; }
    .btn-primary:hover:not(:disabled):not(.disabled).active {
      background: #0576ee;
      border-color: #0576ee; }
    .btn-primary:hover:not(:disabled):not(.disabled):active {
      background: #0576ee;
      border-color: #0576ee; }
  .btn-primary:focus {
    background: #0467d0;
    border-color: #0467d0; }
  .btn-primary:focus {
    background: #0467d0;
    border-color: #0467d0; }

.btn-secondory {
  background: #0576ee;
  border-color: #0576ee;
  color: #ffffff; }
  .btn-secondory:hover {
    background: #0467d0;
    border-color: #0467d0; }
    .btn-secondory:hover:not(:disabled):not(.disabled).active {
      background: #0576ee;
      border-color: #0576ee; }
    .btn-secondory:hover:not(:disabled):not(.disabled):active {
      background: #0576ee;
      border-color: #0576ee; }
  .btn-secondory:focus {
    background: #0467d0;
    border-color: #0467d0; }
  .btn-secondory:focus {
    background: #0467d0;
    border-color: #0467d0; }

.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #0576ee;
  border-color: #0576ee; }

.btn-dark {
  background: #000f2d;
  border-color: #000f2d; }
  .btn-dark:hover {
    background: #001847;
    border-color: #001847; }

.btn:not(:disabled):not(.disabled).active:focus {
  color: #ffffff; }

.btn i {
  padding-right: 10px; }

.btn + .btn {
  margin-left: 3px; }

.btn-link {
  color: #0576ee;
  padding: 0;
  text-decoration: underline; }
  .btn-link:hover {
    color: #000f2d;
    text-decoration: none;
    outline: none; }
  .btn-link:focus {
    color: #000f2d;
    text-decoration: none;
    outline: none; }

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
  color: #000f2d; }

.btn-white {
  background: #ffffff;
  border-color: #ffffff;
  color: #000f2d; }
  .btn-white:hover {
    background: #dfdfdf;
    border-color: #dfdfdf;
    color: #000f2d; }
  .btn-white:active {
    color: #000f2d; }
  .btn-white:focus {
    color: #000f2d; }

.btn.btn-white:not(:disabled):not(.disabled):active:focus {
  color: #000f2d; }

.btn-light:active {
  color: #000f2d; }

.btn-light:focus {
  color: #000f2d; }

.btn.btn-light:not(:disabled):not(.disabled):active:focus {
  color: #000f2d; }

.btn.btn-sm, .btn-group-sm > .btn {
  padding: 6px 24px; }

.btn.btn-md {
  padding: 9px 26px; }

.btn.btn-lg, .btn-group-lg > .btn {
  padding: 13px 30px; }

.btn.btn-xl {
  padding: 16px 40px; }

.btn-app {
  display: flex; }
  .btn-app i {
    font-size: 40px; }

.btn-outline-primary {
  border: 2px solid #0576ee;
  color: #0576ee; }
  .btn-outline-primary:hover {
    background: #0576ee;
    color: #ffffff;
    border-color: #0576ee; }
  .btn-outline-primary:focus {
    background: #0576ee;
    color: #ffffff;
    border-color: #0576ee; }

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #0576ee;
  border-color: #0576ee; }

.btn-outline-secondary {
  border: 2px solid #0576ee;
  color: #333333; }
  .btn-outline-secondary:hover {
    background: #0576ee;
    color: #ffffff;
    border-color: #0576ee; }
  .btn-outline-secondary:focus {
    background: #0576ee;
    color: #ffffff;
    border-color: #0576ee; }

.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background: #0576ee;
  color: #ffffff;
  border-color: #0576ee; }

.btn-round {
  border-radius: 50px; }

/*****************************
  Countdown
*****************************/
.countdown {
  padding: 20px;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  text-align: center;
  background: #0576ee;
  border-radius: 4px; }
  .countdown span {
    font-size: 49px;
    line-height: 60px;
    font-weight: 700;
    color: #ffffff; }
  .countdown p {
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0;
    font-weight: 700;
    color: #ffffff; }

@media (max-width: 991px) {
  .countdown span {
    font-size: 36px; } }

@media (max-width: 767px) {
  .countdown {
    padding: 20px 20px; }
    .countdown span {
      font-size: 32px;
      font-weight: 600; } }

/*****************************
  Counter
*****************************/
.counter {
  align-items: center; }
  .counter .counter-icon {
    margin-bottom: 15px; }
    .counter .counter-icon i {
      color: #0576ee;
      margin-right: 20px;
      font-size: 50px;
      line-height: 50px; }
  .counter .counter-content {
    align-self: center; }
    .counter .counter-content .timer {
      position: relative;
      font-size: 40px;
      line-height: 40px;
      font-weight: 700;
      color: #333333;
      margin-bottom: 20px;
      display: block; }
    .counter .counter-content label {
      display: block;
      color: #666666;
      margin-bottom: 0; }

/* counter with border */
.counter.rounded {
  border-color: rgba(255, 255, 255, 0.2) !important; }

@media (max-width: 767px) {
  .counter {
    margin-bottom: 30px; } }

/*****************************
  Datetimepicker
*****************************/
.bootstrap-datetimepicker-widget table td.active {
  background-color: #0576ee; }
  .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #0576ee; }

.bootstrap-datetimepicker-widget table td.day {
  height: 30px;
  line-height: 30px;
  width: 40px;
  font-size: 14px; }

.bootstrap-datetimepicker-widget table th {
  font-size: 14px; }

.bootstrap-datetimepicker-widget table td span.active {
  background: #0576ee; }

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 19rem; }

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #0576ee; }

/*****************************
  Feature Info
*****************************/
.feature-items {
  text-align: center;
  padding: 40px 20px;
  background: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px; }
  .feature-items .feature-icon {
    margin-bottom: 10px;
    font-size: 60px;
    line-height: 60px; }
    .feature-items .feature-icon i {
      font-size: 60px;
      color: #0576ee; }
  .feature-items .feature-content h6, .feature-items .feature-content .h6 {
    margin-bottom: 7px;
    color: #000f2d;
    font-weight: 500; }

.feature-items-style-02 {
  background: #ffffff;
  padding: 40px 20px 40px 20px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  transition: all 0.5s ease;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  cursor: pointer; }
  .feature-items-style-02 .feature-number {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 16px;
    color: rgba(5, 118, 238, 0.5); }
  .feature-items-style-02:hover {
    background-color: #0576ee;
    color: #ffffff; }
    .feature-items-style-02:hover .feature-icon i {
      color: #ffffff; }
    .feature-items-style-02:hover h6, .feature-items-style-02:hover .h6 {
      color: #ffffff; }
    .feature-items-style-02:hover .feature-number {
      color: rgba(5, 118, 238, 0.5); }

.feature-items-style-03 [class*="col-"] {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .feature-items-style-03 [class*="col-"]:nth-child(3) {
    border-right: none; }
  .feature-items-style-03 [class*="col-"]:nth-child(n+4) {
    border-bottom: none; }
  .feature-items-style-03 [class*="col-"]:last-child {
    border-right: none; }

.feature-items-style-03 .feature-items {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px; }

/*service-items*/
.service-items.active {
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1); }
  .service-items.active .service-content .icon-btn {
    opacity: 1; }

.service-items {
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 30px; }
  .service-items .service-img img {
    border-radius: 4px 4px 0 0; }
  .service-items:hover {
    box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08); }
    .service-items:hover .service-content {
      border-radius: 0 0 4px 4px; }
      .service-items:hover .service-content .icon-btn {
        opacity: 1; }
  .service-items .service-content {
    padding: 30px; }
    .service-items .service-content span {
      color: #0576ee;
      margin-bottom: 5px;
      display: block;
      font-size: 12px; }
    .service-items .service-content h5, .service-items .service-content .h5 {
      color: #0576ee;
      margin-bottom: 20px; }
    .service-items .service-content .icon-btn {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 40px;
      line-height: 40px;
      background: #0576ee;
      color: #ffffff;
      border-radius: 50px;
      opacity: 0; }

.service-items-style-02 {
  border-radius: 10px;
  overflow: hidden; }
  .service-items-style-02 .service-content {
    position: absolute;
    top: 50%;
    background: rgba(5, 118, 238, 0.7);
    width: 100%;
    height: 101%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease; }
    .service-items-style-02 .service-content .icon-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      color: #0576ee;
      border-radius: 10px;
      transform: translate(-50%, -50%);
      top: 50%; }
  .service-items-style-02:hover .service-content {
    opacity: 1;
    visibility: visible; }
  .service-items-style-02:hover .icon-btn {
    bottom: 15px; }

/* service-detail */
.service-detail .service-items {
  margin-bottom: 30px; }

.sidebar .widget .widget-services ul.list-style-underline li {
  padding-bottom: 0;
  margin-bottom: 0; }
  .sidebar .widget .widget-services ul.list-style-underline li a {
    padding: 15px;
    transition: all 0.5s ease; }
    .sidebar .widget .widget-services ul.list-style-underline li a:hover {
      background-color: #0576ee;
      color: #ffffff; }

.sidebar .widget .widget-worktime ul li {
  display: flex;
  justify-content: space-between; }

.sidebar .widget .widget-services-care .services-care-icon i {
  font-size: 80px;
  color: #ffffff;
  line-height: 80px; }

.sidebar .widget .widget-services-care .services-care-title {
  font-weight: 700; }

.departments-items {
  overflow: hidden;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  border-radius: 4px; }
  .departments-items .departments-content {
    padding: 75px 30px 40px;
    position: relative; }
    .departments-items .departments-content .departments-icon {
      height: 70px;
      width: 70px;
      line-height: 70px;
      display: inline-block;
      text-align: center;
      background: #0576ee;
      margin-bottom: 25px;
      border-radius: 4px;
      position: absolute;
      top: -35px; }
      .departments-items .departments-content .departments-icon i {
        font-size: 40px;
        color: #ffffff; }
    .departments-items .departments-content .departments-icon-right i {
      font-size: 120px;
      line-height: 1;
      color: rgba(5, 118, 238, 0.05);
      position: absolute;
      right: -25px;
      top: 20px; }

@media (max-width: 767px) {
  .feature-items-style-03 [class*="col-"]:nth-child(3) {
    border-right: 1px solid rgba(0, 0, 0, 0.05); }
  .feature-items-style-03 [class*="col-"]:nth-child(n+4) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .feature-items-style-03 [class*="col-"]:nth-child(n+5) {
    border-bottom: none; }
  .feature-items-style-03 [class*="col-"]:nth-child(2n-2) {
    border-right: none; } }

@media (max-width: 575px) {
  .feature-items-style-03 [class*="col-"]:nth-child(1n-1) {
    border-right: none; }
  .feature-items-style-03 [class*="col-"]:nth-child(n+5) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .feature-items-style-03 [class*="col-"]:last-child {
    border-bottom: none; } }

/*****************************
	Owl Carousel
*****************************/
.owl-carousel .owl-item img {
  width: auto;
  display: inherit; }

.owl-carousel .owl-nav {
  cursor: pointer;
  z-index: 999;
  width: 100%; }
  .owl-carousel .owl-nav .owl-prev {
    display: block;
    position: absolute;
    text-indent: inherit;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    left: -15px;
    display: block;
    position: absolute;
    text-indent: inherit;
    top: 55%;
    transform: translateY(-50%);
    margin: 0 !important;
    display: inline-block;
    margin: 14px 2px 0; }
    .owl-carousel .owl-nav .owl-prev i {
      border-radius: 5px !important;
      font-size: 22px;
      width: 40px;
      height: 80px;
      line-height: 80px; }
  .owl-carousel .owl-nav .owl-next {
    display: block;
    position: absolute;
    text-indent: inherit;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    right: -15px;
    display: block;
    position: absolute;
    text-indent: inherit;
    top: 55%;
    transform: translateY(-50%);
    margin: 0 !important;
    display: inline-block;
    margin: 14px 2px 0; }
    .owl-carousel .owl-nav .owl-next i {
      border-radius: 5px !important;
      font-size: 22px;
      width: 40px;
      height: 80px;
      line-height: 80px; }
  .owl-carousel .owl-nav i {
    font-size: 36px;
    width: 60px;
    height: 145px;
    line-height: 145px;
    display: inline-block;
    color: #ffffff;
    background: #0576ee;
    border: 0px solid #eeeeee;
    text-align: center;
    transition: all 0.5s ease-in-out; }
    .owl-carousel .owl-nav i:hover {
      background: #000f2d;
      color: #0576ee;
      box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08); }

.owl-carousel .owl-controls .owl-dot {
  margin: 0;
  display: inline-block; }

.owl-carousel .owl-dots {
  display: inline-block;
  margin-top: 10px;
  text-indent: inherit;
  width: 100%;
  cursor: pointer;
  text-align: center; }
  .owl-carousel .owl-dots .owl-dot span {
    background: #ffffff;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border: 1px solid #0576ee;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    cursor: pointer; }
  .owl-carousel .owl-dots .owl-dot:hover span {
    background: #0576ee; }
  .owl-carousel .owl-dots .owl-dot.active span {
    background: #0576ee; }

.owl-carousel .owl-dot {
  display: inline-block;
  margin: 14px 4px 0; }

.owl-carousel .testimonial-item {
  padding-bottom: 0px;
  padding-top: 0px; }

/*****************************
	Portfolio
*****************************/
.my-shuffle-container {
  margin-left: -15px;
  margin-right: -15px; }
  .my-shuffle-container .grid-item {
    width: 33.33%;
    padding: 15px; }

.portfolio-item {
  position: relative; }
  .portfolio-item .portfolio-overlay {
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    align-items: flex-end;
    transition: all 0.5s ease; }
    .portfolio-item .portfolio-overlay .portfolio-description {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0%; }
      .portfolio-item .portfolio-overlay .portfolio-description .portfolio-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        transition: all 0.5s ease;
        text-align: center; }
        .portfolio-item .portfolio-overlay .portfolio-description .portfolio-info .portfolio-title {
          font-size: 20px;
          font-weight: 700;
          display: block;
          color: #ffffff;
          margin-bottom: 15px; }
        .portfolio-item .portfolio-overlay .portfolio-description .portfolio-info .icon-btn {
          width: 40px;
          height: 40px;
          line-height: 40px;
          display: inline-block;
          text-align: center;
          background: #0576ee;
          color: #ffffff;
          border-radius: 50px; }
          .portfolio-item .portfolio-overlay .portfolio-description .portfolio-info .icon-btn i {
            font-size: 14px;
            color: #ffffff; }
  .portfolio-item:hover .portfolio-overlay {
    opacity: 1;
    background: rgba(5, 118, 238, 0.5); }

.filters-group {
  display: table;
  margin: 0 auto 50px;
  text-align: center; }
  .filters-group button {
    margin: 0 10px 10px;
    padding: 14px 25px;
    cursor: pointer;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    background-color: #ffffff;
    color: #333333;
    border: 2px solid #dfdfdf;
    border-radius: 4px;
    transition: all 0.3s ease; }
    .filters-group button:hover {
      background-color: #0576ee;
      color: #ffffff;
      border: 2px solid #0576ee; }
  .filters-group button.active {
    background-color: #0576ee;
    color: #ffffff;
    border: 2px solid #0576ee; }

/*************************************
         shuffle
 *************************************/
.my-shuffle-container.columns-1 .grid-item {
  width: 100%;
  padding: 15px; }

.my-shuffle-container.columns-2 .grid-item {
  width: 50%;
  padding: 15px;
  float: left; }

.my-shuffle-container.columns-3 .grid-item {
  width: 33.33333333%;
  padding: 15px; }

.my-shuffle-container.columns-4 .grid-item {
  width: 25%;
  padding: 10px; }

.my-shuffle-container.columns-5 .grid-item {
  width: 20%;
  padding: 15px; }

.my-shuffle-container.columns-6 .grid-item {
  width: 16.666666%;
  padding: 0px; }

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
  width: 100%; }

/* Responsive */
@media (max-width: 991px) {
  .filters-group button {
    margin: 0 5px 10px; }
  .filters-group button:first-child {
    margin-left: 0; }
  .filters-group button:last-child {
    margin-right: 0; } }

@media (max-width: 767px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 50%; } }

@media (max-width: 575px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 100%; } }

/*****************************
  Pricing Table
*****************************/
.pricing {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08); }
  .pricing .pricing-header {
    padding: 40px 30px 30px; }
    .pricing .pricing-header i {
      line-height: 1; }
    .pricing .pricing-header .pricing-title {
      color: #333333;
      font-weight: normal;
      margin-top: 10px; }
    .pricing .pricing-header .pricing-price {
      position: relative;
      display: inline-block;
      font-size: 18px;
      color: #333333; }
      .pricing .pricing-header .pricing-price sup {
        position: absolute;
        top: 30px;
        left: -15px;
        font-size: 18px;
        color: #333333; }
      .pricing .pricing-header .pricing-price strong {
        font-size: 48px;
        line-height: 48px;
        font-weight: normal;
        color: #333333; }
  .pricing .pricing-body {
    padding-top: 20px;
    padding-bottom: 40px; }
    .pricing .pricing-body .pricing-list {
      margin-bottom: 30px; }
      .pricing .pricing-body .pricing-list li {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eeeeee;
        color: #666666;
        font-weight: 600; }
        .pricing .pricing-body .pricing-list li i {
          font-size: 12px;
          min-width: 16px;
          margin-right: 10px;
          color: #0576ee; }
        .pricing .pricing-body .pricing-list li:last-child {
          margin-bottom: 0;
          border-bottom: none; }
    .pricing .pricing-body .btn {
      border: 2px solid #666666;
      color: #666666;
      background-color: #ffffff; }
      .pricing .pricing-body .btn:hover {
        border: 2px solid #0576ee;
        color: #ffffff;
        background-color: #0576ee; }

.pricing.active .pricing-body .btn {
  border: 2px solid #0576ee;
  color: #ffffff;
  background-color: #0576ee; }
  .pricing.active .pricing-body .btn:hover {
    background: #0467d0;
    border-color: #0467d0; }
    .pricing.active .pricing-body .btn:hover:not(:disabled):not(.disabled).active {
      background: #0576ee;
      border-color: #0576ee; }
    .pricing.active .pricing-body .btn:hover:not(:disabled):not(.disabled):active {
      background: #0576ee;
      border-color: #0576ee; }

.pricing.active {
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.1); }
  .pricing.active .pricing-title {
    color: #0576ee; }
  .pricing.active .pricing-price {
    color: #0576ee; }
    .pricing.active .pricing-price sup {
      color: #0576ee; }
    .pricing.active .pricing-price strong {
      color: #0576ee; }
    .pricing.active .pricing-price span {
      color: #0576ee; }

.asked-questions .title {
  font-size: 16px;
  line-height: 24px; }

.asked-questions .number {
  height: 30px;
  width: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border: 2px solid #333333;
  color: #333333;
  margin-right: 12px;
  border-radius: 4px; }

/* Responsive */
@media (max-width: 1199px) {
  .pricing-table-content {
    margin-right: 10px !important;
    margin-left: 10px !important; }
  .pricing-prize h2, .pricing-prize .h2 {
    font-size: 62px; }
  .pricing-table-title {
    flex: 0 0 18%; } }

@media (max-width: 991px) {
  .pricing .btn {
    padding: 14px 15px; }
  .pricing-section-title {
    padding-top: 60px;
    padding-bottom: 150px; } }

/*****************************
	Range Slider
*****************************/
.irs--flat .irs-line {
  height: 4px;
  background: #0576ee;
  border-radius: 0; }

.irs--flat .irs-bar {
  background: #0576ee;
  height: 4px; }

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  background: transparent;
  color: #0576ee;
  font-size: 13px;
  font-weight: 600; }

.irs--flat .irs-bar--single {
  border-radius: 0; }

.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-handle > i:first-child {
  background: #0576ee;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #0576ee;
  cursor: pointer;
  transition: all 0.3s ease-in-out; }

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  display: none; }

.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background: #0576ee; }

/*****************************
	Select Dropdown
*****************************/
.select2-container .select2-selection--single {
  height: auto;
  outline: none; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0px; }

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  border: none; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #999999 !important;
    font-weight: bold;
    line-height: inherit; }

.select2-container--default.select2-container--above .select2-selection--single .select2-selection__rendered {
  color: #333333 !important; }

.select2-container--default.select2-container--below .select2-selection--single .select2-selection__rendered {
  color: #333333 !important; }

.bigdrop {
  min-width: 196px !important; }

.select2-results__options {
  padding: 8px 2px; }

.select2-container {
  width: 100% !important; }

.select2-container--default .select2-results__option--highlighted {
  border-radius: 0;
  transition: all 0.2s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  padding-left: 10px;
  font-weight: 600; }

/* Select Dropdown Arrow */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 1px;
  right: 0px;
  height: 21px;
  width: 14px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none; }
  .select2-container--default .select2-selection--single .select2-selection__arrow:before {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    color: #999999; }

.select2-container--default .select2-search--dropdown {
  padding: 0; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #0576ee; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f6f6f6; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(5, 118, 238, 0.1);
  color: #0576ee; }

.select2-dropdown {
  border: none;
  border-radius: 0px;
  z-index: 9; }

.select-border .select2-container--default .select2-selection--single {
  border: 1px solid #dfdfdf;
  height: 50px;
  padding: 15px 20px;
  border-radius: 0px;
  border-radius: 5px; }
  .select-border .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 16px;
    right: 20px; }

.select2-container--default .select2-results > .select2-results__options {
  border: none; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #999999; }

.select2-container--open .select2-dropdown {
  background: #ffffff;
  padding: 7px;
  border: 1px solid #eeeeee; }

.select2-search--dropdown .select2-search__field {
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04); }

/*****************************
	Skill
*****************************/
.skill {
  position: relative;
  width: 100%;
  height: 4px;
  margin: 50px 0 20px 0;
  background-color: rgba(5, 118, 238, 0.21);
  border-radius: 4px; }
  .skill:first-child {
    margin-top: 30px; }
  .skill .skill-bar {
    position: relative;
    height: 100%;
    border-radius: 4px;
    padding: 0px;
    background: #0576ee;
    box-shadow: none;
    transition: width .9s ease; }
    .skill .skill-bar .skill-title {
      color: #333333;
      font-size: 18px;
      font-weight: 500;
      position: absolute;
      margin-left: 0;
      margin-top: -28px;
      text-align: left; }
      .skill .skill-bar .skill-title i {
        margin-right: 8px; }
    .skill .skill-bar .progress-number {
      color: #333333;
      float: right;
      margin-top: -18px;
      position: absolute;
      right: 13px;
      top: -10px;
      font-size: 15px;
      font-weight: 600; }
    .skill .skill-bar .progress-type {
      color: #333333;
      float: right;
      margin-top: -18px;
      position: absolute;
      right: 0;
      top: -10px;
      font-size: 15px;
      font-weight: 600; }
    .skill .skill-bar:before {
      content: "";
      position: absolute;
      right: 0;
      background: #0576ee;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      height: 10px;
      width: 10px; }

/*****************************
	Tabs
*****************************/
.nav-tabs {
  border: none; }
  .nav-tabs .nav-item {
    margin-bottom: 0px;
    width: 20%;
    margin-right: 30px; }
    .nav-tabs .nav-item .nav-link {
      border: none;
      border: 2px solid #dfdfdf;
      font-size: 18px;
      padding: 20px 18px;
      font-family: "Fira Sans", sans-serif;
      font-weight: 700;
      color: #333333;
      background-color: #ffffff;
      box-shadow: inherit;
      border-radius: 4px;
      text-align: center;
      transition: all 0.5s ease; }
      .nav-tabs .nav-item .nav-link i {
        font-size: 28px; }
      .nav-tabs .nav-item .nav-link:hover {
        color: #ffffff;
        border: 2px solid #0576ee;
        background: #0576ee;
        transition: all 0.5s ease-in-out; }
    .nav-tabs .nav-item .nav-link.active {
      background: #0576ee;
      border: 2px solid #0576ee;
      color: #ffffff; }
  .nav-tabs .nav-item:last-child {
    margin-right: 0px; }

.tab-content #choose-us {
  margin-top: 70px; }

.tab-content #question {
  margin-top: 55px; }

.nav-tabs.nav-tabs-02 {
  position: relative; }
  .nav-tabs.nav-tabs-02:before {
    content: "";
    width: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%; }
  .nav-tabs.nav-tabs-02:after {
    content: "";
    width: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%; }
  .nav-tabs.nav-tabs-02 .nav-item {
    width: auto; }
    .nav-tabs.nav-tabs-02 .nav-item .nav-link {
      border: none;
      border-bottom: none;
      font-size: 18px;
      padding: 10px 40px 10px 0px;
      font-family: "Fira Sans", sans-serif;
      color: #000f2d;
      background-color: inherit;
      border: 2px solid #dfdfdf;
      margin: 0px 20px 10px 8px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      transition: all 0.5s ease; }
      .nav-tabs.nav-tabs-02 .nav-item .nav-link:hover {
        color: #000f2d;
        border: 2px solid #0576ee; }
        .nav-tabs.nav-tabs-02 .nav-item .nav-link:hover .feature-icon {
          background: #0576ee;
          color: #ffffff; }
    .nav-tabs.nav-tabs-02 .nav-item .nav-link.active {
      color: #ffffff;
      border: 2px solid #0576ee;
      background: #0576ee; }
      .nav-tabs.nav-tabs-02 .nav-item .nav-link.active .feature-icon {
        background: #ffffff;
        color: #0576ee;
        box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08); }
  .nav-tabs.nav-tabs-02 .feature-icon {
    background: #f6f6f6;
    color: #0576ee;
    padding: 10px 20px;
    display: inline-block;
    text-align: center;
    position: relative;
    left: -34px;
    border-radius: 4px;
    transition: all 0.5s ease; }
    .nav-tabs.nav-tabs-02 .feature-icon i.active {
      color: #ffffff; }

/* Responsive */
@media (max-width: 1199px) {
  .nav-tabs .nav-item .nav-link {
    padding: 10px 45px 10px 0px; }
  .nav-tabs.nav-tabs-02 .nav-item .nav-link {
    font-size: 16px; } }

@media (max-width: 991px) {
  .tab-content #choose-us {
    margin-top: 50px; }
  .tab-content #question {
    margin-top: 35px; }
  .nav-tabs.nav-tabs-02 .nav-item .nav-link {
    padding: 8px 45px 8px 0px;
    font-size: 16px; } }

@media (max-width: 767px) {
  .tab-content #choose-us {
    margin-top: 40px; }
  .tab-content #question {
    margin-top: 25px; }
  .nav-tabs.nav-tabs-02 .nav-item {
    margin-right: 20px; }
    .nav-tabs.nav-tabs-02 .nav-item .nav-link {
      padding: 8px 20px 8px 0px; }
      .nav-tabs.nav-tabs-02 .nav-item .nav-link .feature-icon {
        left: -15px; } }

@media (max-width: 575px) {
  .nav-tabs .nav-item .nav-link {
    border: none;
    font-size: 14px;
    padding: 15px 10px; } }

@media (max-width: 479px) {
  .nav-tabs {
    display: block; }
  .nav-tabs .nav-item {
    margin: 0 0 10px 0; }
  .nav-tabs.nav-tabs-02 {
    display: inline-block;
    left: 23px; } }

/*****************************
	Team
*****************************/
.team {
  position: relative;
  transition: all 0.3s ease-in-out;
  text-align: center;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08); }
  .team .team-image {
    position: relative;
    overflow: hidden;
    padding: 10px; }
    .team .team-image .team-social {
      position: absolute;
      top: 50%;
      left: -50px;
      transform: translatey(-50%);
      opacity: 1;
      transition: all 0.5s ease-in-out; }
      .team .team-image .team-social ul {
        display: inline-block;
        margin-bottom: 0;
        padding-left: 0; }
        .team .team-image .team-social ul li {
          list-style-type: none;
          margin-bottom: 10px; }
          .team .team-image .team-social ul li:last-child {
            margin-bottom: 0; }
            .team .team-image .team-social ul li:last-child a {
              margin-right: 0; }
          .team .team-image .team-social ul li a {
            display: block;
            background-color: #ffffff;
            color: #999999;
            height: 45px;
            width: 45px;
            line-height: 45px;
            text-align: center;
            border: 2px solid #0576ee;
            border-radius: 0;
            margin-right: 20px;
            transition: all 0.5s ease; }
            .team .team-image .team-social ul li a:hover {
              background-color: #0576ee;
              color: #ffffff; }
            .team .team-image .team-social ul li a i {
              font-size: 16px; }
  .team .team-detail {
    position: relative;
    padding: 20px 30px 40px 30px;
    margin: -30px 10px 0px 10px;
    background: #ffffff;
    transition: all 0.5s ease-in-out;
    height: 120px;
    border-radius: 4px;
    box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
    top: 0; }
    .team .team-detail .team-title {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
      color: #000f2d;
      margin-top: 5px; }
    .team .team-detail .team-label {
      font-size: 12px;
      color: #999999; }
    .team .team-detail .team-content {
      margin-top: 15px; }
    .team .team-detail .team-phone {
      font-size: 16px;
      color: #333333;
      display: block;
      margin-bottom: 10px;
      transition: all 0.6s ease; }
    .team .team-detail .team-email {
      font-size: 16px;
      color: #333333;
      display: none;
      transition: all 0.6s ease; }
  .team .icon-btn {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #0576ee;
    color: #ffffff;
    border-radius: 50px; }
  .team:hover .team-image .team-social {
    left: 25px;
    z-index: 9; }
  .team:hover .team-detail {
    top: -25px;
    transition: all 0.3s ease; }
    .team:hover .team-detail .team-phone {
      display: block;
      transition: all 0.6s ease; }
    .team:hover .team-detail .team-email {
      display: block; }
  .team:hover .icon-btn {
    background: #333333;
    color: #ffffff; }

.team-style-02 {
  box-shadow: none; }
  .team-style-02 .team-image {
    padding: 0;
    border-radius: 4px; }

.team-single {
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08); }
  .team-single .team-single-detail .team-single-img {
    margin-bottom: 40px; }
  .team-single .team-single-detail ul {
    padding-left: 30px; }
    .team-single .team-single-detail ul .media {
      margin-bottom: 13px;
      display: flex; }
      .team-single .team-single-detail ul .media span {
        flex: 0 0 100px;
        color: #0576ee;
        font-weight: 700; }
      .team-single .team-single-detail ul .media label {
        margin-bottom: 0; }
      .team-single .team-single-detail ul .media .social a {
        padding: 0px 10px;
        color: #333333; }
        .team-single .team-single-detail ul .media .social a:hover {
          color: #0576ee; }
        .team-single .team-single-detail ul .media .social a:last-child {
          padding-right: 0; }
        .team-single .team-single-detail ul .media .social a:first-child {
          padding-left: 0; }

.quick-contact-form {
  padding: 40px 30px;
  background-color: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px; }

/* Responsive */
@media (max-width: 1200px) {
  .team .team-detail {
    padding: 20px 20px 20px 20px; } }

@media (max-width: 991px) {
  .team .team-social ul li a {
    height: 35px !important;
    width: 35px !important;
    line-height: 35px !important; }
  .team .team-detail .team-email {
    font-size: 14px; } }

@media (max-width: 479px) {
  .team-single .team-single-detail ul {
    padding-left: 5px; } }

/*****************************
	Testimonial
*****************************/
.testimonial-item {
  position: relative;
  align-items: center;
  padding: 60px 55px; }
  .testimonial-item .testimonial-avatar {
    flex: 0 0 105px;
    width: 105px;
    height: 105px;
    border: 5px solid #ffffff;
    border-radius: 100%;
    margin: 0 auto;
    margin-bottom: 20px; }
  .testimonial-item .testimonial-content {
    flex: 0 0 67%;
    font-size: 18px;
    line-height: 24px;
    font-style: italic;
    margin-left: 30px;
    margin-right: 50px; }
  .testimonial-item .testimonial-author .testimonial-name .testimonial-quote {
    font-size: 48px;
    line-height: 48px;
    color: rgba(5, 118, 238, 0.3); }
  .testimonial-item .testimonial-author .testimonial-name h6, .testimonial-item .testimonial-author .testimonial-name .h6 {
    color: #0576ee;
    font-size: 18px; }
  .testimonial-item .testimonial-author .testimonial-name span {
    color: #000f2d;
    font-weight: 700; }

.testimonial-item.text-center {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 30px; }
  .testimonial-item.text-center .testimonial-avatar {
    display: inline-block;
    flex: inherit;
    margin-bottom: 20px;
    box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08); }
  .testimonial-item.text-center .testimonial-content {
    flex: inherit;
    color: #333333;
    margin-bottom: 30px;
    margin-right: 0;
    margin-left: 0; }
  .testimonial-item.text-center .testimonial-author .testimonial-name span {
    color: #0576ee; }

.testimonial-style-02 .testimonial-item {
  padding: 0;
  text-align: left; }
  .testimonial-style-02 .testimonial-item .testimonial-content {
    background-color: #ffffff;
    font-size: 15px;
    padding: 30px;
    line-height: 26px;
    margin: 0;
    font-style: normal;
    border-radius: 10px;
    position: relative; }
    .testimonial-style-02 .testimonial-item .testimonial-content:after {
      top: 100%;
      left: 60px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #ffffff;
      border-width: 10px; }
    .testimonial-style-02 .testimonial-item .testimonial-content:before {
      top: 100%;
      left: 50%;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(194, 225, 245, 0);
      border-top-color: #c2e1f5;
      border-width: 36px;
      margin-left: -36px; }
    .testimonial-style-02 .testimonial-item .testimonial-content p {
      margin-bottom: 0; }
  .testimonial-style-02 .testimonial-item .testimonial-author {
    display: flex;
    align-items: center;
    margin: 30px 0px 0 30px; }
    .testimonial-style-02 .testimonial-item .testimonial-author .testimonial-avatar {
      flex: 0 0 80px;
      margin: 0px 20px 0px 0px;
      width: 80px;
      height: 80px;
      border: none; }
    .testimonial-style-02 .testimonial-item .testimonial-author .testimonial-name h6, .testimonial-style-02 .testimonial-item .testimonial-author .testimonial-name .h6 {
      color: #ffffff;
      font-size: 16px;
      font-weight: normal; }
    .testimonial-style-02 .testimonial-item .testimonial-author .testimonial-name span {
      color: #ffffff;
      font-weight: normal; }

.testimonial-style-03 .testimonial-item .testimonial-content {
  margin-left: 0px; }
  .testimonial-style-03 .testimonial-item .testimonial-content p {
    font-size: 22px;
    color: #ffffff; }

.testimonial-style-03 .testimonial-item .testimonial-author {
  display: flex;
  align-items: center; }
  .testimonial-style-03 .testimonial-item .testimonial-author .testimonial-avatar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 105px;
    flex: 0 0 75px;
    width: 75px;
    height: 75px;
    border: 5px solid #ffffff;
    border-radius: 100%;
    margin: 0;
    margin-bottom: 0px;
    margin-right: 20px; }
  .testimonial-style-03 .testimonial-item .testimonial-author .testimonial-name span {
    color: #ffffff; }
  .testimonial-style-03 .testimonial-item .testimonial-author .testimonial-name h6, .testimonial-style-03 .testimonial-item .testimonial-author .testimonial-name .h6 {
    color: #000f2d; }

@media (max-width: 1350px) {
  .testimonial-item .testimonial-content {
    flex: 0 0 68%; } }

@media (max-width: 1199px) {
  .testimonial-item .testimonial-content {
    flex: 0 0 60%; } }

@media (max-width: 991px) {
  .testimonial-item .testimonial-content {
    flex: 0 0 56%; }
  .testimonial-style-02 .owl-nav {
    display: none; } }

@media (max-width: 767px) {
  .testimonial-item {
    display: block;
    text-align: center;
    padding: 50px 30px; }
  .testimonial-item .testimonial-avatar {
    margin: 0 auto;
    margin-bottom: 20px; }
  .testimonial-item .testimonial-content {
    margin: 0 auto;
    margin-bottom: 20px; }
  .testimonial-style-02 .testimonial-item .testimonial-content {
    font-size: 14px;
    padding: 15px;
    line-height: 24px; }
  .testimonial-style-03 .testimonial-item .testimonial-content {
    text-align: left; }
  .testimonial-style-03 .testimonial-item {
    text-align: left; } }

@media (max-width: 575px) {
  .testimonial-item {
    padding: 60px 20px; }
  .testimonial-item .testimonial-content {
    margin: 0 auto; } }

/*****************************
  Header
*****************************/
.header {
  background: #ffffff;
  z-index: 99;
  position: relative; }
  .header .container-fluid, .header .container-sm, .header .container-md, .header .container-lg, .header .container-xl, .header .container-xxl {
    padding: 0 60px;
    align-items: initial; }

.navbar-toggler:focus {
  box-shadow: none; }

/* topbar */
.header .topbar {
  background: #0576ee;
  padding: 10px 20px; }

.header .topbar a {
  color: #ffffff; }
  .header .topbar a:hover {
    color: #0576ee; }

.header .topbar .dropdown {
  margin-right: 20px; }
  .header .topbar .dropdown .dropdown-toggle {
    padding: 12px 0; }
    .header .topbar .dropdown .dropdown-toggle i {
      font-size: 10px; }
  .header .topbar .dropdown .dropdown-menu a {
    color: #333333; }
    .header .topbar .dropdown .dropdown-menu a:hover {
      color: #0576ee; }

.header .topbar .social {
  display: inline-block; }
  .header .topbar .social ul {
    margin: 0; }
    .header .topbar .social ul li {
      display: inline-block;
      padding: 0 4px; }
      .header .topbar .social ul li a {
        color: #ffffff; }
        .header .topbar .social ul li a:hover {
          color: #0576ee; }

.header .topbar .login {
  display: inline-block; }
  .header .topbar .login a {
    color: #ffffff; }
    .header .topbar .login a:hover {
      color: #0576ee; }

/* topbar dropdown-menu */
.topbar .dropdown .dropdown-menu {
  border-radius: 0;
  border: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 10px; }

.topbar .dropdown .dropdown-menu a {
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 3px; }

.topbar .dropdown .dropdown-menu a:hover {
  background: rgba(5, 118, 238, 0.1);
  color: #0576ee; }

/* navbar */
.navbar {
  padding: 0; }

.navbar .navbar-nav .nav-link {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
  color: #000f2d;
  text-transform: capitalize; }
  .navbar .navbar-nav .nav-link:hover {
    color: #0576ee; }

.dropdown-menu {
  z-index: 9999; }

.navbar-collapse {
  position: relative; }

.navbar-light {
  color: #ffffff;
  background-color: #f6f6f6;
  border-color: #eeeeee; }

.navbar-light .navbar-nav > li > a {
  color: #ffffff; }

.navbar-light .navbar-nav > .dropdown > a .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff; }

.navbar-brand {
  padding: 30px 0px;
  margin-right: 0; }

.navbar-brand img {
  height: 40px; }

.navbar .navbar-brand {
  color: #ffffff;
  flex: 0 0 200px; }

.header .navbar-nav > li {
  display: flex; }

.header .navbar-nav li > a {
  align-items: center;
  display: flex;
  padding: 3px 0px;
  color: #333333;
  text-transform: capitalize;
  font-size: 14px; }

.header .navbar-nav li > a:hover {
  color: #0576ee; }

.header .navbar-nav li > a i {
  margin-left: 5px;
  font-size: 13px;
  color: #000f2d; }

.header .add-listing {
  align-self: center;
  flex: 0 0 170px;
  text-align: right; }

.megamenu {
  padding: 20px 20px;
  width: 100%; }

.megamenu > div > li > ul {
  padding: 0;
  margin: 0; }

.megamenu > div > li > ul > li {
  list-style: none; }

.megamenu .card {
  outline: none; }

.megamenu .card:hover,
.megamenu .card:focus {
  outline: 1px solid #000000; }

.megamenu > div > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #000f2d;
  white-space: normal; }

.megamenu > div > li > ul > li > a:hover,
.megamenu > div > li > ul > li > a:focus {
  text-decoration: none;
  color: #000f2d;
  background-color: #f6f6f6; }

.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  color: #f6f6f6; }

.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed; }

.megamenu.dropdown-header {
  color: #0576ee;
  font-size: 18px; }

.header li > .dropdown-item:focus,
.header li > .dropdown-item:hover {
  color: #0576ee;
  background: none; }

.header .dropdown-item.active,
.header .dropdown-item:active,
.header .dropdown-item:focus,
.header .dropdown-item:hover {
  background: none; }

.header .dropdown-toggle::after {
  content: none; }

.header .navbar-collapse {
  align-items: inherit; }

.header .megamenu .dropdown-item {
  padding: 0px; }

.header .navbar .dropdown-menu a.dropdown-item {
  min-width: 180px; }

.header .navbar .dropdown-menu {
  padding: 15px; }

.header .navbar .dropdown-menu li a i {
  margin-left: auto; }

.navbar .dropdown > .dropdown-menu li > a {
  font-size: 14px;
  padding: 10px 20px 10px 0px;
  border-radius: 4px;
  position: relative; }

.navbar .dropdown > .dropdown-menu li > a:hover {
  padding-left: 15px; }

.navbar .dropdown > .dropdown-menu li > a:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 0;
  height: 100%;
  transition: all 0.5s ease; }

.navbar .dropdown > .dropdown-menu li > a:hover:before {
  width: 100%;
  background: rgba(5, 118, 238, 0.1);
  color: #0576ee;
  border-radius: 4px; }

/* header-transparent */
.header-transparent {
  position: absolute;
  background: transparent;
  width: 100%;
  z-index: 999; }

.header-transparent .navbar .navbar-nav .nav-link {
  color: #333333; }
  .header-transparent .navbar .navbar-nav .nav-link:hover {
    color: #0576ee; }

.navbar .navbar-nav .nav-item.active .nav-link {
  color: #0576ee; }

.navbar .navbar-nav .nav-item.active .nav-link i {
  color: #0576ee; }

.navbar .dropdown > .dropdown-menu li.active > a {
  padding-left: 15px;
  color: #0576ee; }

.navbar .navbar-nav .dropdown-menu li.active > a:before {
  background: rgba(5, 118, 238, 0.1);
  color: #0576ee;
  border-radius: 4px; }

.navbar .navbar-nav .dropdown-menu li.active > a:before {
  width: 100%; }

.header-transparent .add-listing {
  align-self: inherit;
  flex: inherit;
  text-align: right; }

.header-transparent .login a {
  color: #ffffff; }
  .header-transparent .login a:hover {
    color: #0576ee; }

.header-transparent.is-sticky .main-header {
  background: #ffffff; }

.header.header-03.header-transparent.header-sticky:before {
  content: "";
  background-color: #0576ee !important;
  position: absolute;
  height: 100%;
  width: 50%;
  right: 0; }

.header.header-03.is-sticky .main-header .navbar:before {
  content: "";
  background-color: #0576ee !important;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 100%; }

/* sticky header */
.header.is-sticky .main-header {
  position: fixed !important;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  top: 0;
  z-index: 999;
  left: 0;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08); }
  .header.is-sticky .main-header .header-contact-info {
    display: none !important; }
  .header.is-sticky .main-header .navbar {
    position: relative;
    top: 0px;
    right: 0px;
    left: 0;
    border-top: none !important;
    width: 100%; }
    .header.is-sticky .main-header .navbar .navbar-brand {
      bottom: 20px; }

/* sticky header */
.header-transparent .is-sticky {
  position: fixed !important;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  top: 0;
  z-index: 9; }
  .header-transparent .is-sticky .header-contact-info {
    display: none !important; }
  .header-transparent .is-sticky .navbar {
    position: relative;
    top: 0px;
    right: 0px;
    left: 0;
    border-top: none !important;
    width: 100%; }

.medi-tools-actions {
  padding: 0;
  display: flex;
  margin: 0;
  align-items: center; }
  .medi-tools-actions .medi-tools-action {
    margin: 0 15px;
    position: relative; }
    .medi-tools-actions .medi-tools-action i {
      font-size: 24px; }

.medi-tools-wishlist .wishlist.medistore-wishlist-count {
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  position: absolute;
  top: 0;
  right: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  margin-top: -2px;
  margin-right: -20px;
  color: #ffffff;
  background: #0576ee;
  border-radius: 50%; }

.medi-tools-cart .cart-link {
  display: flex;
  align-items: center; }
  .medi-tools-cart .cart-link .cart-count {
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
    position: absolute;
    top: 0;
    right: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    margin-top: -2px;
    margin-right: -20px;
    color: #ffffff;
    background: #0576ee;
    border-radius: 50%; }

.medi-tools-cart .cart-contents {
  box-shadow: none;
  color: #333333;
  position: absolute;
  top: 100%;
  right: -13px;
  width: 360px;
  z-index: 9999;
  margin-top: 5px;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  padding: 0px;
  transition: all .3s ease-in-out; }
  .medi-tools-cart .cart-contents .shopping_cart .shopping_cart_content {
    background-color: #ffffff;
    padding: 15px;
    box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
    text-align: center; }
    .medi-tools-cart .cart-contents .shopping_cart .shopping_cart_content p {
      color: #333333;
      margin: 15px 0; }

.medi-tools-cart:hover .cart-contents {
  opacity: 1;
  visibility: visible; }

/* header-02 */
.header.header-02 .navbar.navbar-light {
  background-color: transparent;
  background-image: none; }

.header.header-02 .navbar .navbar-nav .nav-link {
  color: #ffffff;
  transition: all 0.3s ease; }

.header.header-02 .navbar-nav .nav-link:hover,
.header.header-02 .navbar-nav .nav-link:focus {
  color: #343a40; }

.header.header-02 .navbar-nav .nav-link:hover i,
.header.header-02 .navbar-nav .nav-link:focus i {
  color: #343a40; }

.header.header-02 .add-listing .btn {
  padding: 12px 30px; }

.header.header-02 .navbar-nav li > a i {
  color: #ffffff;
  transition: all 0.3s ease; }

.header.header-02 .navbar .navbar-nav .nav-item.active .nav-link {
  color: #343a40; }

.header.header-02 .navbar .navbar-nav .nav-item.active .nav-link i {
  color: #343a40; }

.header.header-02 .add-listing {
  flex: 0 0 160px; }

.navbar .dropdown > .dropdown-menu li > a:before {
  content: none; }

.header.header-02.is-sticky .main-header {
  background-color: #0576ee; }

/* header-03 */
.header.header-03 .navbar-nav li > a i {
  color: #333333; }

.header.header-03 .navbar.is-sticky .navbar-brand {
  padding: 20px 0px; }

.header.header-03 .navbar .navbar-nav .nav-item.active .nav-link,
.header.header-03 .navbar .navbar-nav li.active > a i,
.header.header-03 .navbar .navbar-nav .nav-link:hover,
.header.header-03 .navbar .navbar-nav .nav-link:hover i {
  color: #0576ee; }

.header.header-03 .navbar.is-sticky .navbar-nav .nav-item.active .nav-link,
.header.header-03 .navbar.is-sticky .navbar-nav li.active > a i,
.header.header-03 .navbar.is-sticky .navbar-nav .nav-link:hover,
.header.header-03 .navbar.is-sticky .navbar-nav .nav-link:hover i {
  color: #0576ee; }

.header.header-03 .navbar {
  position: relative;
  background-color: #ffffff; }

.header.header-03 .navbar .navbar-nav .nav-link {
  padding: 10px 15px; }

.header.header-03 .navbar .navbar-brand {
  padding-left: 25px; }

.header.header-03 .navbar .navbar-brand {
  margin-right: 0px;
  display: block; }

.header.header-03 .navbar .add-listing {
  display: flex;
  flex: 0 0 298px;
  align-items: center;
  margin-right: -1px; }

.header.header-03 .navbar .add-listing .btn {
  padding: 39px 30px;
  border-radius: 0px; }

/* shop-header */
.header.shop-header .navbar.is-sticky .navbar-brand {
  padding: 30px 0px; }

/* Responsive */
@media (min-width: 992px) {
  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    left: 100%;
    transform: translate3d(-50%, 10px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    display: block !important; }
  .header .navbar .dropdown-menu.megamenu {
    left: 50%; }
  .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 600px; }
  .navbar-nav .mega-menu {
    position: static; }
  .header .navbar .dropdown:hover > .dropdown-menu {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
    opacity: 1; }
  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
    left: 100%;
    right: auto;
    transform: translate3d(15px, 10px, 0); }
  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
    right: 100%;
    left: auto;
    transform: translate3d(-15px, 10px, 0); }
  .navbar-nav li:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1; }
  .dropdown-submenu {
    position: relative; }
  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px; }
  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg); } }

@media (max-width: 1400px) {
  .navbar .navbar-nav .nav-link {
    padding: 10px 12px; } }

@media (max-width: 1199px) {
  .navbar .navbar-nav .nav-link {
    padding: 10px 7px;
    font-size: 14px; }
  .header-transparent .login {
    display: none; }
  .header-transparent .navbar-brand {
    margin-right: 20px; }
  .header.header-02 .navbar .navbar-nav .nav-link {
    padding: 10px 12px; }
  .header.header-03 .container-fluid, .header.header-03 .container-sm, .header.header-03 .container-md, .header.header-03 .container-lg, .header.header-03 .container-xl, .header.header-03 .container-xxl {
    padding: 0 20px; }
  .header.header-03 .add-listing .btn {
    padding: 12px 20px; }
  .header.header-03 .navbar .add-listing {
    flex: 0 0 159px; } }

@media (max-width: 1023px) {
  .header.header-03 .container-fluid, .header.header-03 .container-sm, .header.header-03 .container-md, .header.header-03 .container-lg, .header.header-03 .container-xl, .header.header-03 .container-xxl {
    padding: 0 15px; }
  .header.header-03 .add-listing .btn {
    padding: 12px 15px; }
  .header.header-03 .navbar.is-sticky {
    padding: 0 15px; } }

@media (max-width: 991px) {
  /* sticky header */
  .header .is-sticky .navbar {
    top: 0;
    right: 0; }
  .header .container-fluid, .header .container-sm, .header .container-md, .header .container-lg, .header .container-xl, .header .container-xxl {
    padding: 0 20px; }
  .navbar-toggler {
    border: none;
    position: absolute;
    height: 75px;
    right: 0; }
  .navbar-brand {
    padding: 20px 0px; }
  .header-sticky.is-sticky .navbar-brand {
    padding: 20px 0px 20px 15px; }
  .megamenu {
    margin-left: 0;
    margin-right: 0; }
  .megamenu > li {
    margin-bottom: 30px; }
  .megamenu > li:last-child {
    margin-bottom: 0; }
  .megamenu.dropdown-header {
    padding: 3px 15px !important; }
  .navbar-nav .open .dropdown-menu .dropdown-header {
    color: #ffffff; }
  .header .navbar-nav > li {
    display: block; }
  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    box-shadow: none;
    background: #f6f6f6;
    padding: 14px 30px;
    font-size: 13px;
    max-height: 300px;
    overflow-x: scroll; }
  .header .navbar .dropdown-menu .dropdown-menu {
    background: #ffffff; }
  .header .navbar .dropdown-menu .dropdown-menu .dropdown-menu {
    background: #f6f6f6; }
  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 9999;
    background: #ffffff;
    width: 100%;
    left: 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); }
  .navbar-brand img {
    height: 34px; }
  .navbar .navbar-nav {
    padding: 10px 0; }
  .navbar .navbar-nav .nav-link {
    padding: 10px 20px; }
  .navbar .navbar-nav .nav-link i {
    margin-left: auto; }
  .header .navbar .nav-title {
    margin-bottom: 10px !important;
    margin-top: 20px; }
  .add-listing .btn {
    font-size: 12px;
    padding: 6px 17px; }
  .navbar .dropdown > .dropdown-menu li > a {
    font-size: 13px; }
  .header .navbar-nav > li {
    border-bottom: 1px solid #eeeeee; }
  .header .navbar-nav > li:last-child {
    border-bottom: 0; }
  .header-transparent {
    position: relative;
    background: #333333; }
  .header-transparent .navbar .navbar-nav .nav-link {
    color: #333333; }
  .header .add-listing {
    margin-right: 60px; }
  .header.header-02 .add-listing {
    margin-right: 60px; }
  .header-02 .navbar-toggler i {
    color: #ffffff; }
  .header.header-02 .navbar .navbar-nav .nav-link {
    color: #0576ee;
    padding: 10px 20px; }
  .header.header-02 .navbar .navbar-nav .nav-link {
    color: #343a40; }
  .header.header-02 .navbar .navbar-nav .nav-link:hover,
  .header.header-02 .navbar .navbar-nav .nav-item.active .nav-link {
    color: #0576ee; }
  .header.header-02 .navbar .navbar-nav .nav-item.active .nav-link i {
    color: #0576ee; }
  .header.header-02 .navbar-nav li > a i {
    color: #343a40; }
  /* Header-03 */
  .header-03.header-transparent {
    background: #ffffff; }
  .header.header-03 .navbar .add-listing {
    margin-right: 0px;
    display: none; }
  .header.header-03 .is-sticky .add-listing {
    margin-right: 50px; }
  .header.header-03 .navbar .navbar-nav .nav-link,
  .header.header-03 .navbar-nav li > a i {
    color: #343a40; }
  .header.header-03 .navbar.is-sticky .navbar-nav .nav-item.active .nav-link,
  .header.header-03 .navbar.is-sticky .navbar-nav li.active > a i,
  .header.header-03 .navbar.is-sticky .navbar-nav .nav-link:hover,
  .header.header-03 .navbar.is-sticky .navbar-nav .nav-link:hover i {
    color: #0576ee; }
  .header.header-03 .navbar:after {
    background-color: #ffffff !important; }
  .header.shop-header .navbar.is-sticky .navbar-brand {
    padding: 15px 0px; }
  .header.header-03.header-transparent.header-sticky:before {
    background-color: #ffffff !important; }
  .header.is-sticky .main-header .navbar:before {
    background-color: #ffffff !important; } }

@media (max-width: 767px) {
  .header .topbar .dropdown .dropdown-toggle {
    padding: 4px 0;
    display: inline-block; } }

@media (max-width: 575px) {
  .navbar-light .navbar-brand {
    flex: none; }
  .header .add-listing {
    flex: none; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar .navbar-nav .nav-item .nav-link {
    padding-top: 27px;
    padding-bottom: 27px; }
  .navbar .navbar-nav .nav-link i {
    margin-top: 5px; } }

/*****************************
  Banner
*****************************/
.banner {
  overflow: hidden; }

.banner-img1 {
  left: -200px;
  top: -120px; }

.banner-img2 {
  right: 70px; }

.banner-img3 {
  left: -250px; }

.banner-img4 {
  right: 0px; }

.banner-img5 {
  right: -70px;
  bottom: -70px; }

.banner-01 {
  background-image: linear-gradient(to left top, #f5faff, #edf5ff, #d2e8ff, #c3e0ff, #c3e0ff); }
  .banner-01 .banner-content h1, .banner-01 .banner-content .h1 {
    font-size: 54px;
    font-weight: 800; }

.banner-02 {
  padding-top: 170px; }
  .banner-02:before {
    position: absolute;
    content: "";
    left: 0;
    width: 50%;
    height: 100%;
    top: 0; }
  .banner-02 .banner-img1 {
    position: absolute;
    top: 20px;
    left: auto;
    right: 10.5%; }
  .banner-02 .banner-img2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    right: auto; }
  .banner-02 .banner-img3 {
    right: 15px;
    position: absolute;
    top: 10px;
    left: auto; }
  .banner-02 .banner-img4 {
    right: 13%;
    position: absolute;
    bottom: 10%; }

.banner-03 .swiper-container {
  height: 100%; }

.banner-03 .banner-content {
  padding: 50px 0px; }
  .banner-03 .banner-content .banner-img1 {
    top: 0;
    left: 0; }
  .banner-03 .banner-content .banner-img2 {
    top: 0;
    right: 30%; }
  .banner-03 .banner-content .banner-img3 {
    bottom: 0;
    left: 0; }
  .banner-03 .banner-content .banner-img4 {
    bottom: 0;
    right: 30%; }
  .banner-03 .banner-content h1, .banner-03 .banner-content .h1 {
    font-size: 72px;
    line-height: 72px;
    font-weight: 500; }

.banner-03 .banner-round-bg {
  top: -50px;
  right: 3%; }

.banner-03 .banner-bg2 {
  bottom: 0;
  right: -280px; }

.banner-03 .service-icon {
  display: flex; }
  .banner-03 .service-icon li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin-right: 20px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px; }

.banner-03 .swiper-button-next {
  background-color: rgba(255, 255, 255, 0.3);
  color: #0576ee;
  border-radius: 4px; }
  .banner-03 .swiper-button-next:hover {
    background-color: white;
    color: #0576ee; }

.banner-03 .swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.3);
  color: #0576ee;
  border-radius: 4px; }
  .banner-03 .swiper-button-prev:hover {
    background-color: white;
    color: #0576ee; }

/*swiper-slide*/
.swiper-button-next,
.swiper-button-prev {
  background-image: none !important;
  z-index: 1;
  background: rgba(5, 118, 238, 0.05);
  line-height: 50px;
  height: 50px;
  width: 30px;
  text-align: center;
  color: #0576ee;
  transition: all 0.5s ease;
  border-radius: 4px; }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background: #0576ee;
    color: #ffffff; }
  .swiper-button-next:focus,
  .swiper-button-prev:focus {
    border: none;
    border-radius: 0;
    outline: none; }

.banner-03 .slide-02 .banner-bg2 {
  right: auto;
  left: -300px; }

.banner-03 .slide-02 .banner-round-bg {
  left: 3%;
  right: auto; }

.inner-banner {
  z-index: 9;
  position: relative; }
  .inner-banner .inner-subtitle {
    font-size: 18px;
    font-weight: 500; }

@media (max-width: 1199px) {
  .banner-03 .banner-content h1, .banner-03 .banner-content .h1,
  .banner-03 .banner-content .h1 {
    font-size: 60px;
    line-height: 60px; } }

@media (max-width: 991px) {
  .banner-02 {
    padding-top: 60px; }
  .swiper-slide .slider-1 h1, .swiper-slide .slider-1 .h1 {
    font-size: 36px; }
  .banner-03.h-100vh {
    height: auto !important; }
  .banner-03 .banner-content h1, .banner-03 .banner-content .h1,
  .banner-03 .banner-content .h1,
  .banner-03 .banner-content .h1 {
    font-size: 50px;
    line-height: 50px; }
  .banner-03 .banner-content .lead {
    font-size: 16px; }
  .banner-03 .banner-content {
    padding: 100px 0px; } }

@media (max-width: 767px) {
  .banner-03 .banner-round-bg {
    top: -65px; }
  .banner-03 .banner-content h1, .banner-03 .banner-content .h1 {
    font-size: 40px;
    line-height: 40px; }
  .swiper-button-next,
  .swiper-button-prev {
    display: none; }
  .banner-03 .service-icon li {
    width: 80px;
    height: 80px; }
  .banner-03 .service-icon li .fa-4x {
    font-size: 3em; } }

@media (max-width: 479px) {
  .banner-03 .banner-content h1, .banner-03 .banner-content .h1 {
    font-size: 30px;
    line-height: 30px; }
  .banner-03 .banner-round-bg {
    top: 0; }
  .swiper-slide .slider-1 h1, .swiper-slide .slider-1 .h1 {
    font-size: 30px; } }

/*****************************
  Leyout
*****************************/
.section-title {
  margin-bottom: 40px; }
  .section-title span {
    margin-bottom: 10px;
    display: inline-block;
    color: #0576ee;
    font-size: 18px; }
  .section-title h2, .section-title .h2 {
    margin-bottom: 25px; }

.btn-video {
  width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 60px;
  background: #0576ee;
  color: #ffffff;
  display: inline-block;
  border-radius: 50%; }
  .btn-video:hover {
    background: #ffffff;
    color: #0576ee; }
  .btn-video:focus {
    background: #ffffff;
    color: #0576ee; }

.schedule {
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.1); }

.schedule-morden {
  padding: 50px 40px;
  position: relative;
  overflow: hidden;
  border-right: 1px solid #f6f6f6;
  height: 100%; }
  .schedule-morden .icon {
    position: absolute;
    top: 0px;
    right: -30px; }
    .schedule-morden .icon i {
      font-size: 90px;
      color: rgba(5, 118, 238, 0.3); }
  .schedule-morden .schedule-morden-content h6, .schedule-morden .schedule-morden-content .h6 {
    color: #000f2d;
    margin-bottom: 15px; }
  .schedule-morden .schedule-morden-content .phone-number {
    font-size: 24px;
    font-weight: bold;
    color: #000f2d;
    margin-bottom: 20px;
    display: block; }

.opening-hourse ul li {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #f6f6f6; }
  .opening-hourse ul li span {
    color: #333333; }
  .opening-hourse ul li .time {
    color: #000f2d;
    font-size: 16px;
    font-weight: bold; }

.position-left-bg {
  position: relative;
  z-index: 9; }
  .position-left-bg:before {
    position: absolute;
    top: 0;
    left: -98%;
    content: "";
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: -12px 4px 21px rgba(0, 0, 0, 0.1); }

.position-right-bg {
  position: relative;
  z-index: 9; }
  .position-right-bg:before {
    position: absolute;
    top: 0;
    right: -98%;
    content: "";
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 20px 4px 21px rgba(0, 0, 0, 0.1); }

.stethoscope-img {
  position: absolute;
  top: 60px;
  right: 0px;
  z-index: 9; }
  .stethoscope-img img {
    width: 90%; }

.bg-primary-half-lg:before {
  content: "";
  background: #0576ee;
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1; }

.bg-dark-half-lg:before {
  content: "";
  background: #000f2d;
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1; }

.overleaping-section-title {
  padding-top: 100px;
  padding-bottom: 140px; }

.overleaping-section {
  position: relative;
  z-index: 9;
  top: -137px;
  margin-bottom: -137px; }

.video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  border: 7px solid #ffffff;
  border-radius: 50px;
  width: 75px;
  height: 75px;
  line-height: 60px;
  text-align: center;
  color: #ffffff; }
  .video-btn i {
    margin-left: 5px; }
  .video-btn:hover {
    color: #ffffff; }

.video-btn-02 a {
  display: flex;
  align-items: center; }
  .video-btn-02 a span {
    font-weight: 600; }

.video-btn-02 .btn-animation {
  position: inherit;
  transform: translate(0%, 0%); }

/* Half-overlay */
.half-overlay::before {
  content: "";
  position: absolute;
  top: 0px;
  width: 28%;
  height: 100%;
  background-position: center center; }

.half-overlay.left-position::before {
  left: 0px;
  background-color: #ffffff;
  width: 19.4%; }

.half-overlay.right-position::before {
  right: 0px;
  background-color: #ffffff; }

.left-half-light-bg {
  position: relative; }
  .left-half-light-bg:before {
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #f6f6f6; }

.right-half-light-bg {
  position: relative; }
  .right-half-light-bg:before {
    position: absolute;
    content: "";
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background: #f6f6f6; }

.right-dot-shape {
  margin-right: -70px;
  margin-top: -70px; }

/* our-clients */
.our-clients .owl-carousel img {
  padding: 15px;
  background: #ffffff;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
  height: 75px; }

.our-clients .owl-carousel img:hover {
  filter: grayscale(0); }

.our-clients .owl-carousel .owl-dots {
  margin-top: 0;
  margin-bottom: 0px; }

.contact-info ul li {
  font-size: 16px; }

.form-group {
  margin-bottom: 1rem; }

/* Social Square */
.social-icon-square ul {
  margin: 0;
  padding: 0; }
  .social-icon-square ul li {
    position: relative;
    list-style-type: none;
    display: inline-block; }
    .social-icon-square ul li a {
      display: block;
      background-color: #ffffff;
      color: #999999;
      height: 45px;
      width: 45px;
      line-height: 45px;
      text-align: center;
      border: 2px solid #0576ee;
      border-radius: 4px;
      margin-right: 15px;
      transition: all 0.5s ease; }
      .social-icon-square ul li a:hover {
        background-color: #0576ee;
        color: #ffffff; }
      .social-icon-square ul li a i {
        font-size: 16px; }

/* Sticky Image */
.sticky-img {
  position: fixed;
  top: 260px;
  width: 50%; }

.sticky-img.right-split {
  right: 0; }

.sticky-img.left-split {
  left: 0; }

/* Appointment Form */
.appointment-form {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: relative; }

/* Contact */
.contact-info .feature-item .feature-item-icon {
  background-color: #0576ee;
  display: inline-block;
  text-align: center;
  flex: 0 0 80px;
  height: 80px;
  width: 80px;
  line-height: 80px;
  margin-bottom: 15px;
  border-radius: 4px; }
  .contact-info .feature-item .feature-item-icon i {
    font-size: 40px;
    color: #ffffff; }

.contact-info .feature-item .feature-item-content .feature-item-title {
  color: #0576ee;
  font-size: 20px;
  font-weight: 700; }

.contact-info .feature-item .feature-item-content span {
  display: block; }

.author-text {
  border-left: 4px solid #0576ee !important;
  padding: 5px 0px; }

/* Login */
.login-form {
  padding: 50px 40px;
  background-color: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px; }

.login-social-media {
  margin-top: 50px;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 4px; }

/* Social Bg Color */
.facebook-bg {
  background-color: #ffffff;
  color: #445c8e;
  border: 1px solid #445c8e; }
  .facebook-bg:hover {
    background-color: #445c8e;
    color: #ffffff; }
  .facebook-bg:focus {
    background-color: #445c8e;
    color: #ffffff; }
  .facebook-bg:active {
    background-color: #445c8e;
    color: #ffffff; }

.twitter-bg {
  background-color: #ffffff;
  color: #43afe9;
  border: 1px solid #43afe9; }
  .twitter-bg:hover {
    background-color: #43afe9;
    color: #ffffff; }
  .twitter-bg:focus {
    background-color: #43afe9;
    color: #ffffff; }
  .twitter-bg:active {
    background-color: #43afe9;
    color: #ffffff; }

.instagram-bg {
  background-color: #ffffff;
  color: #dd2a7b;
  border: 1px solid #dd2a7b; }
  .instagram-bg:hover {
    background-color: #dd2a7b;
    color: #ffffff; }
  .instagram-bg:focus {
    background-color: #dd2a7b;
    color: #ffffff; }
  .instagram-bg:active {
    background-color: #dd2a7b;
    color: #ffffff; }

.linkedin-bg {
  background-color: #ffffff;
  color: #007eb3;
  border: 1px solid #007eb3; }
  .linkedin-bg:hover {
    background-color: #007eb3;
    color: #ffffff; }
  .linkedin-bg:focus {
    background-color: #007eb3;
    color: #ffffff; }
  .linkedin-bg:active {
    background-color: #007eb3;
    color: #ffffff; }

/* Time Table */
.time-table .table {
  font-size: 16px;
  font-weight: bold; }
  .time-table .table thead tr th {
    text-align: center;
    vertical-align: middle;
    background-color: #333333; }
    .time-table .table thead tr th span {
      display: block;
      color: #ffffff; }
  .time-table .table tbody tr td {
    text-align: center;
    vertical-align: middle; }
    .time-table .table tbody tr td span {
      display: block;
      margin-bottom: 10px; }
      .time-table .table tbody tr td span:last-child {
        margin-bottom: 0; }
    .time-table .table tbody tr td .space-line {
      width: 15px;
      height: 2px;
      background-color: #333333;
      display: inline-block; }

/* Before After */
.before-after-section {
  width: 100%;
  height: 600px;
  position: relative; }

/* Our normalize css */
* {
  margin: 0;
  box-sizing: border-box; }

/* Our wrapper */
.before-after-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  overflow: hidden; }

/* Our image information */
.before,
.after {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden; }

.content-image {
  height: 100%; }

.after {
  width: 125px; }

.before-after-scroller {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0%;
  background-color: transparent;
  opacity: 1;
  pointer-events: auto;
  cursor: pointer; }

.before-after-scroller:hover {
  opacity: 1; }

.scrolling {
  pointer-events: none;
  opacity: 1; }

.scroller__thumb {
  padding: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .scroller__thumb i {
    color: #0576ee; }

.before-after-scroller:before,
.before-after-scroller:after {
  content: " ";
  display: block;
  width: 5px;
  height: 9999px;
  position: absolute;
  left: 50%;
  margin-left: -3.5px;
  z-index: 30;
  transition: 0.1s; }

.before-after-scroller:before {
  top: 100%; }

.before-after-scroller:after {
  bottom: 100%; }

.before-after-scroller {
  border: 2px solid #0576ee;
  background-color: #ffffff; }

.before-after-scroller:before,
.before-after-scroller:after {
  background: #ffffff; }

/* Cost Calculator */
.blood {
  padding: 50px 40px;
  background-color: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px; }
  .blood label {
    font-weight: 700;
    color: #333333; }

.blood-total {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  margin-right: 30px; }

.blood-amount {
  background-color: #0576ee;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  padding: 15px 60px;
  border-radius: 4px; }

/* 404 */
.contant-404 h2, .contant-404 .h2 {
  font-size: 80px;
  font-weight: 700; }

.contant-404 h3, .contant-404 .h3 {
  font-weight: 700; }

.contant-404 h6, .contant-404 .h6 {
  color: #666666; }

/* coming-soon */
.coming-soon-content .coming-soon-logo {
  width: 250px;
  margin-bottom: 50px;
  display: inline-block; }

.coming-soon-content h1, .coming-soon-content .h1 {
  font-size: 80px;
  font-weight: bold; }

.coming-soon-content .newsletter {
  position: relative; }
  .coming-soon-content .newsletter .form-control {
    padding-right: 150px;
    height: 50px;
    border-radius: 4px; }
  .coming-soon-content .newsletter .btn {
    position: absolute;
    right: 0;
    line-height: 20px;
    top: 0;
    padding: 14px 30px; }

.coming-soon-content .social-media ul {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 0; }
  .coming-soon-content .social-media ul li {
    list-style-type: none;
    margin-bottom: 10px;
    display: inline-block; }
    .coming-soon-content .social-media ul li:last-child {
      margin-bottom: 0; }
      .coming-soon-content .social-media ul li:last-child a {
        margin-right: 0; }
    .coming-soon-content .social-media ul li a {
      display: block;
      background-color: #ffffff;
      color: #999999;
      height: 45px;
      width: 45px;
      line-height: 45px;
      text-align: center;
      border: 2px solid #0576ee;
      border-radius: 4px;
      margin-right: 20px;
      transition: all 0.5s ease; }
      .coming-soon-content .social-media ul li a:hover {
        background-color: #0576ee;
        color: #ffffff; }
      .coming-soon-content .social-media ul li a i {
        font-size: 16px; }

.choose-people {
  padding-top: 100px;
  padding-bottom: 160px; }

.medistore_banner {
  position: relative;
  overflow: hidden;
  background: none; }

.medistore-banner-1 {
  background-color: rgba(40, 167, 69, 0.2); }

.medistore-banner-2 {
  background-color: #d0edeb; }

.medistore_banner .medistore_banner-content {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 10;
  padding: 40px 25px;
  -webkit-transition: background .5s ease;
  -o-transition: background .5s ease;
  transition: background .5s ease; }

.medistore_banner .medistore_banner-content-wrapper {
  display: table;
  height: 100%;
  width: 100%; }

.medistore_banner .medistore_banner-content-inner-wrapper {
  display: table-cell; }

.medistore_banner .medistore_banner-content .medistore_banner-content-inner-wrapper {
  vertical-align: top; }

.medistore_banner .medistore_banner-content-vbottom .medistore_banner-content-inner-wrapper {
  vertical-align: bottom; }

.medistore_banner .medistore_banner-content-vmiddle .medistore_banner-content-inner-wrapper {
  vertical-align: middle; }

.medistore_banner .medistore_banner-content-hleft {
  text-align: left; }

.medistore_banner .medistore_banner-content-hcenter {
  text-align: center; }

.medistore_banner .medistore_banner-content-hright {
  text-align: right; }

.medistore_banner-btn-wrap {
  margin-top: 20px; }

.medistore_banner-text {
  font-weight: 500; }

/* Home 03 */
.client-info .opening-time {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 23%;
  border-bottom-left-radius: 0; }

.about-video {
  display: flex;
  align-items: center;
  transition: all 0.5s ease; }
  .about-video .video {
    position: relative;
    width: auto;
    border-radius: 5px; }
    .about-video .video span {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 56px;
      margin-left: 0;
      color: #fff;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }

.category .feature-icon {
  position: relative;
  margin-top: -60px;
  background: #f6f6f6;
  color: #0576ee;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  line-height: 120px;
  font-size: 14px;
  text-align: center;
  display: inline-block; }

@media (max-width: 1380px) {
  .right-dot-shape {
    margin-right: 0px;
    margin-top: 0px; } }

@media (max-width: 1199px) {
  .choose-people {
    padding-top: 80px;
    padding-bottom: 150px; }
  .schedule-morden {
    padding: 30px 20px; }
  .client-info .opening-time {
    width: 31.5555%; } }

@media (max-width: 991px) {
  .half-overlay.right-position::before {
    display: none; }
  .half-overlay.left-position::before {
    display: none; }
  .choose-people {
    padding-top: 60px;
    padding-bottom: 80px; }
  .schedule-morden {
    border-bottom: 1px solid #f6f6f6; } }

@media (max-width: 767px) {
  .sticky-img.right-split,
  .sticky-img.left-split {
    position: inherit;
    top: 0;
    width: 90%;
    right: 0;
    left: 0;
    margin: auto;
    margin-bottom: 30px; }
  .coming-soon-content h1, .coming-soon-content .h1 {
    font-size: 60px;
    font-weight: bold; }
  .schedule {
    margin-bottom: 30px; }
  .choose-people {
    padding-top: 50px;
    padding-bottom: 50px; }
  .contant-404 h2, .contant-404 .h2 {
    font-size: 60px;
    font-weight: 600; }
  .client-info .opening-time {
    width: 100%;
    position: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px; }
  .position-right-bg:before {
    display: none; } }

@media (max-width: 575px) {
  .login-form {
    padding: 40px 20px; }
  .feature-item-content {
    margin-left: 0px; }
  .contact-info .feature-item .feature-item-content {
    margin-left: 0px !important;
    text-align: center; }
  .section-title {
    margin-bottom: 20px; } }

@media (max-width: 479px) {
  .section-title span {
    font-size: 16px; } }

/*****************************
  Blog
*****************************/
.blog-post {
  margin-bottom: 25px; }
  .blog-post .blog-post-image {
    flex: 0 0 200px;
    position: relative; }
    .blog-post .blog-post-image img {
      border-radius: 4px; }
  .blog-post .blog-post-meta {
    padding: 0px 0px 12px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .blog-post .blog-post-meta .blog-post-time,
    .blog-post .blog-post-meta .blog-post-author,
    .blog-post .blog-post-meta .blog-post-comment,
    .blog-post .blog-post-meta .share-box {
      margin: 0 8px 0 0; }
    .blog-post .blog-post-meta .blog-post-author img {
      margin: 0 5px;
      height: 20px;
      border-radius: 50%; }
    .blog-post .blog-post-meta span {
      font-size: 13px; }
    .blog-post .blog-post-meta a {
      font-size: 13px;
      color: #666666; }
      .blog-post .blog-post-meta a:hover {
        color: #0576ee; }
    .blog-post .blog-post-meta i {
      padding-right: 5px; }
  .blog-post .blog-post-content {
    padding: 20px 15px;
    background: #fff;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
    position: relative;
    top: -70px;
    margin: 15px;
    border-radius: 5px; }
    .blog-post .blog-post-content .blog-post-details {
      padding: 25px 5px; }
      .blog-post .blog-post-content .blog-post-details .blog-post-title {
        margin-bottom: 0px;
        color: #0576ee; }
        .blog-post .blog-post-content .blog-post-details .blog-post-title a {
          color: #000f2d; }
    .blog-post .blog-post-content .blog-post-info {
      display: flex;
      margin-bottom: 30px; }
      .blog-post .blog-post-content .blog-post-info div {
        margin-right: 20px; }
        .blog-post .blog-post-content .blog-post-info div:last-child {
          margin-right: 0px; }
        .blog-post .blog-post-content .blog-post-info div a i {
          margin-right: 10px; }
    .blog-post .blog-post-content .blog-post-social {
      display: flex;
      margin-bottom: 30px; }
      .blog-post .blog-post-content .blog-post-social span {
        padding-right: 15px; }
      .blog-post .blog-post-content .blog-post-social ul {
        margin-bottom: 0; }
        .blog-post .blog-post-content .blog-post-social ul li {
          display: inline-block;
          float: left;
          padding: 0 10px; }
          .blog-post .blog-post-content .blog-post-social ul li a {
            color: #333333;
            font-size: 18px; }
            .blog-post .blog-post-content .blog-post-social ul li a:hover {
              color: #0576ee; }
  .blog-post .blockquote {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
    z-index: 9;
    border-radius: 4px; }
    .blog-post .blockquote .blockquote-content {
      position: relative; }
      .blog-post .blockquote .blockquote-content .blockquote-quote {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0; }
        .blog-post .blockquote .blockquote-content .blockquote-quote i {
          font-size: 78px;
          line-height: 78px;
          top: 0;
          color: rgba(5, 118, 238, 0.2); }
    .blog-post .blockquote .blockquote-author .blockquote-name {
      display: flex; }
      .blog-post .blockquote .blockquote-author .blockquote-name .text-muted {
        margin-left: 10px; }

/* blog post style 02 */
.blog-post-style-02 {
  position: relative;
  overflow: hidden;
  border-radius: 4px; }
  .blog-post-style-02:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, black 100%);
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 1; }
  .blog-post-style-02 .blog-post-img img {
    transition: all 0.3s ease-in-out; }
  .blog-post-style-02 .blog-post-info {
    position: absolute;
    display: block;
    bottom: 0;
    padding: 30px;
    width: 100%;
    z-index: 2; }
    .blog-post-style-02 .blog-post-info .blog-post-category {
      display: inline-block;
      background: #0576ee;
      padding: 3px 10px;
      font-size: 12px;
      margin-bottom: 10px;
      border-radius: 4px; }
      .blog-post-style-02 .blog-post-info .blog-post-category a {
        display: inline-block;
        color: #ffffff;
        margin-right: 3px; }
        .blog-post-style-02 .blog-post-info .blog-post-category a:last-child {
          margin-right: 0px; }
        .blog-post-style-02 .blog-post-info .blog-post-category a:hover {
          color: #1d2936; }
    .blog-post-style-02 .blog-post-info .blog-post-title {
      color: #ffffff;
      margin: 0; }
      .blog-post-style-02 .blog-post-info .blog-post-title:hover a {
        color: #0576ee; }
      .blog-post-style-02 .blog-post-info .blog-post-title a:focus {
        color: #0576ee; }
  .blog-post-style-02:hover .blog-post-img img {
    transform: scale(1.1); }

/* blog-post-02 */
.blog-post-02 .blog-post-meta .blog-post-author {
  position: relative;
  padding-right: 30px; }
  .blog-post-02 .blog-post-meta .blog-post-author:before {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    background: #dfdfdf;
    top: 50%;
    transform: translateY(-50%);
    right: 0; }

/* blog post style 03 */
.blog-post-style-03 {
  margin-bottom: 0;
  border-radius: 4px;
  overflow: hidden; }
  .blog-post-style-03 .blog-post-image img {
    border-radius: 0px; }
  .blog-post-style-03 .blog-post-content {
    position: inherit;
    top: inherit;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 25px 25px; }
    .blog-post-style-03 .blog-post-content .blog-post-details {
      padding: 0; }

.blog-post-carousel .blog-post-style-03 {
  margin-bottom: 20px; }

/* blog-post-quote */
.blog-post-quote .blockquote {
  padding: 40px 40px 0 40px; }
  .blog-post-quote .blockquote p {
    font-size: 14px;
    font-style: italic; }
  .blog-post-quote .blockquote i {
    font-size: 30px;
    margin-bottom: 20px;
    color: #045dbc; }
  .blog-post-quote .blockquote cite {
    font-size: 15px; }

.blog-post-quote .blog-post-link a {
  color: #ffffff; }
  .blog-post-quote .blog-post-link a:hover {
    color: #333333; }

.blog-post-quote .blog-post-content .blog-post-footer {
  border-color: #0465cb; }
  .blog-post-quote .blog-post-content .blog-post-footer a {
    color: #ffffff;
    transition: all 0.3s ease-in-out; }
    .blog-post-quote .blog-post-content .blog-post-footer a i {
      transition: all 0.3s ease-in-out; }
    .blog-post-quote .blog-post-content .blog-post-footer a:hover {
      color: #333333; }
      .blog-post-quote .blog-post-content .blog-post-footer a:hover i {
        color: #333333; }
  .blog-post-quote .blog-post-content .blog-post-footer span {
    color: #ffffff; }

.blog-post-quote .share-box .share-box-social {
  background: #ffffff; }
  .blog-post-quote .share-box .share-box-social li a {
    color: #333333; }
    .blog-post-quote .share-box .share-box-social li a:hover {
      color: #0576ee; }
  .blog-post-quote .share-box .share-box-social:before {
    border-color: #ffffff transparent transparent transparent; }

.blog-post-navigation .post-navigation .nav-link {
  position: relative; }
  .blog-post-navigation .post-navigation .nav-link a i {
    position: absolute;
    font-size: 24px;
    color: #333333;
    top: 50%;
    transform: translateY(-50%); }
  .blog-post-navigation .post-navigation .nav-link a span {
    display: block;
    color: #333333;
    transition: all 0.5s ease-in-out; }
  .blog-post-navigation .post-navigation .nav-link a .nav-title {
    font-size: 18px; }
  .blog-post-navigation .post-navigation .nav-link .nav-previous {
    position: absolute;
    left: 0; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a {
      position: relative;
      display: block;
      padding-left: 90px;
      padding-top: 11px;
      padding-bottom: 11px; }
      .blog-post-navigation .post-navigation .nav-link .nav-previous a i {
        left: 20px; }
      .blog-post-navigation .post-navigation .nav-link .nav-previous a:before {
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 60px;
        height: 100%;
        background-color: #f6f6f6;
        border-radius: 4px;
        transition: all 0.5s ease-in-out; }
      .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover i {
        color: #ffffff; }
      .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover span {
        color: #0576ee; }
      .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover:before {
        background-color: #0576ee; }
  .blog-post-navigation .post-navigation .nav-link .nav-next {
    position: absolute;
    right: 0;
    text-align: right; }
    .blog-post-navigation .post-navigation .nav-link .nav-next a {
      position: relative;
      display: block;
      padding-right: 90px;
      padding-top: 11px;
      padding-bottom: 11px; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a i {
        right: 20px; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:before {
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 60px;
        height: 100%;
        background-color: #f6f6f6;
        border-radius: 4px;
        transition: all 0.5s ease-in-out; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:hover i {
        color: #ffffff; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:hover span {
        color: #0576ee; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:hover:before {
        background-color: #0576ee; }

.blog-detail .comments-01 {
  display: flex; }
  .blog-detail .comments-01 .comment-author {
    width: 90px; }
    .blog-detail .comments-01 .comment-author img {
      width: 100%;
      border-radius: 4px; }
  .blog-detail .comments-01 .comment-content {
    border: 1px solid #f6f6f6;
    background-color: #f6f6f6;
    padding: 15px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 4px; }
    .blog-detail .comments-01 .comment-content .reviews {
      display: flex;
      margin-bottom: 10px;
      align-items: center; }
      .blog-detail .comments-01 .comment-content .reviews .meta {
        font-size: 16px; }
        .blog-detail .comments-01 .comment-content .reviews .meta strong {
          color: #333333; }
      .blog-detail .comments-01 .comment-content .reviews p {
        margin-bottom: 0; }
      .blog-detail .comments-01 .comment-content .reviews .port-social {
        margin-left: auto;
        border-radius: 0;
        color: #0576ee;
        border: 1px solid #0576ee;
        padding: 5px 15px;
        display: block;
        border-radius: 4px; }

.blog-detail .comments-02 {
  display: flex;
  padding-left: 100px; }
  .blog-detail .comments-02 .comment-author {
    width: 90px; }
    .blog-detail .comments-02 .comment-author img {
      width: 100%;
      border-radius: 4px; }
  .blog-detail .comments-02 .comment-content {
    border: 1px solid #f6f6f6;
    padding: 15px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 4px; }
    .blog-detail .comments-02 .comment-content .reviews {
      display: flex;
      margin-bottom: 10px;
      align-items: center; }
      .blog-detail .comments-02 .comment-content .reviews .meta {
        font-size: 16px; }
        .blog-detail .comments-02 .comment-content .reviews .meta strong {
          color: #333333; }
      .blog-detail .comments-02 .comment-content .reviews p {
        margin-bottom: 0; }
      .blog-detail .comments-02 .comment-content .reviews .port-social {
        margin-left: auto;
        border-radius: 0;
        color: #0576ee;
        border: 1px solid #0576ee;
        padding: 5px 15px;
        display: block;
        border-radius: 4px; }

.blog-detail .leave-reply-form {
  background-color: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 40px 30px; }

/* Sidebar */
.sidebar .widget {
  margin-bottom: 30px;
  padding: 40px 30px;
  background-color: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px; }
  .sidebar .widget:last-child {
    margin-bottom: 0; }
  .sidebar .widget .widget-title {
    margin-bottom: 20px; }
  .sidebar .widget .search {
    position: relative; }
    .sidebar .widget .search input {
      padding-right: 64px;
      height: 50px;
      padding-left: 20px; }
    .sidebar .widget .search i {
      position: absolute;
      right: 0;
      top: 0;
      background: #0576ee;
      color: #ffffff;
      padding: 18px 20px;
      cursor: pointer;
      margin: 0;
      border-radius: 0 4px 4px 0px; }
  .sidebar .widget .recent-posts a {
    color: #333333; }
    .sidebar .widget .recent-posts a:hover {
      color: #0576ee; }
  .sidebar .widget .recent-posts .date {
    color: #999999;
    margin-top: 10px; }
  .sidebar .widget .widget-contact-info ul li {
    margin-bottom: 30px; }
    .sidebar .widget .widget-contact-info ul li i {
      font-size: 16px; }
    .sidebar .widget .widget-contact-info ul li span {
      margin-left: 20px; }
  .sidebar .widget .widget-follow ul {
    margin-bottom: 0; }
    .sidebar .widget .widget-follow ul li {
      display: inline-block;
      margin-right: 20px; }
      .sidebar .widget .widget-follow ul li:last-child {
        margin-right: 0px; }
      .sidebar .widget .widget-follow ul li a {
        font-size: 18px;
        color: #666666; }
        .sidebar .widget .widget-follow ul li a:hover {
          color: #0576ee; }
  .sidebar .widget ul.list-style li a {
    display: flex;
    color: #666666;
    width: 100%; }
    .sidebar .widget ul.list-style li a:hover {
      color: #0576ee; }
  .sidebar .widget ul.list-style li:last-child {
    margin-bottom: 0; }
  .sidebar .widget .gallery ul {
    display: flex;
    flex-wrap: wrap; }
    .sidebar .widget .gallery ul li {
      flex: 0 0 19%;
      margin: 0 1% 1% 0; }
      .sidebar .widget .gallery ul li:nth-child(5n+5) {
        margin-right: 0; }
  .sidebar .widget .social ul li {
    padding: 10px 10px 10px 20px;
    border: 1px solid #eeeeee;
    display: flex;
    margin-bottom: 10px;
    align-items: center; }
    .sidebar .widget .social ul li a {
      color: #333333;
      font-weight: 600;
      text-transform: capitalize; }
      .sidebar .widget .social ul li a i {
        width: 15px; }
    .sidebar .widget .social ul li .follow {
      background: #f6f6f6;
      padding: 3px 18px;
      font-size: 12px; }
      .sidebar .widget .social ul li .follow:hover {
        background: #333333;
        color: #ffffff; }
    .sidebar .widget .social ul li:last-child {
      margin-bottom: 0; }
  .sidebar .widget .social ul .facebook a {
    color: #466ca9; }
  .sidebar .widget .social ul .facebook .follow:hover {
    background: #466ca9; }
  .sidebar .widget .social ul .twitter a {
    color: #20b5e6; }
  .sidebar .widget .social ul .twitter .follow:hover {
    background: #20b5e6; }
  .sidebar .widget .social ul .youtube a {
    color: #d92c20; }
  .sidebar .widget .social ul .youtube .follow:hover {
    background: #d92c20; }
  .sidebar .widget .social ul .linkedIn a {
    color: #13799f; }
  .sidebar .widget .social ul .linkedIn .follow:hover {
    background: #13799f; }
  .sidebar .widget .popular-tag ul li {
    display: inline-block;
    margin-bottom: 4px; }
    .sidebar .widget .popular-tag ul li a {
      padding: 6px 20px;
      display: block;
      border: 1px solid #eeeeee;
      color: #666666; }
      .sidebar .widget .popular-tag ul li a:hover {
        color: #0576ee;
        border-color: #0576ee; }

ul.list-style-underline li {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  margin-bottom: 15px; }
  ul.list-style-underline li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0px; }

.blog-detail .blog-post-content {
  text-align: left; }

.navigation .nav-links {
  display: flex; }
  .navigation .nav-links .nav-previous {
    width: 50%;
    border: 1px solid #eeeeee;
    margin-right: 30px;
    transition: all 0.5s ease-in-out; }
    .navigation .nav-links .nav-previous a {
      display: flex;
      color: #666666; }
      .navigation .nav-links .nav-previous a:hover .pagi-text {
        color: #ffffff;
        background: #0576ee; }
    .navigation .nav-links .nav-previous .nav-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .navigation .nav-links .nav-previous .pagi-text {
      border-right: 1px solid #eeeeee;
      transition: all 0.5s ease-in-out; }
    .navigation .nav-links .nav-previous:hover {
      background: #f6f6f6; }
  .navigation .nav-links .nav-next {
    width: 50%;
    border: 1px solid #eeeeee;
    margin-right: 30px;
    margin-right: 0;
    text-align: right;
    transition: all 0.5s ease-in-out; }
    .navigation .nav-links .nav-next a {
      display: flex;
      color: #666666;
      padding-left: 15px; }
      .navigation .nav-links .nav-next a:hover .pagi-text {
        color: #ffffff;
        background: #0576ee; }
    .navigation .nav-links .nav-next .nav-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-left: auto; }
    .navigation .nav-links .nav-next .pagi-text {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border-left: 1px solid #eeeeee; }
    .navigation .nav-links .nav-next:hover {
      background: #f6f6f6; }
  .navigation .nav-links .pagi-text {
    display: inline-block;
    padding: 12px 25px;
    color: #666666;
    transition: all 0.5s ease-in-out; }
  .navigation .nav-links .nav-title {
    margin: 12px 20px; }

@media (max-width: 1199px) {
  .blog-post .blog-post-content .blog-post-details {
    padding: 10px 10px; }
  .blog-post-style-03 .blog-post-content .blog-post-details {
    padding: 0; } }

@media (max-width: 767px) {
  .blog-post-navigation .post-navigation .nav-link .nav-previous a {
    padding-left: 70px; }
  .blog-post-navigation .post-navigation .nav-link .nav-next a {
    padding-right: 70px; }
  .blog-post-style-03 .blog-post-content {
    padding: 20px 15px; } }

@media (max-width: 575px) {
  .blog-post-navigation .post-navigation .nav-link .nav-previous {
    position: relative; }
  .blog-post-navigation .post-navigation .nav-link .nav-previous a {
    padding-left: 75px; }
  .blog-post-navigation .post-navigation .nav-link .nav-next a {
    padding-right: 75px;
    margin-top: 15px; }
  .blog-detail .comments-01 .comment-content .reviews .meta,
  .blog-detail .comments-02 .comment-content .reviews .meta {
    font-size: 14px; }
  .blog-detail .comments-02 {
    padding-left: 10px; }
  .pagination .page-item .page-link {
    font-size: 14px;
    font-weight: 600;
    padding: 8px 10px;
    margin-right: 12px; } }

/*****************************
  Footer
*****************************/
.footer h6, .footer .h6 {
  margin-bottom: 45px; }

.footer .footer-logo {
  margin-bottom: 30px;
  display: inline-block; }
  .footer .footer-logo img {
    width: 70%; }

.footer .footer-contact-info ul {
  padding-top: 0px;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%; }
  .footer .footer-contact-info ul li {
    display: flex;
    margin-bottom: 20px; }
    .footer .footer-contact-info ul li i {
      font-size: 18px;
      color: #0576ee;
      min-width: 25px;
      margin-right: 15px; }
    .footer .footer-contact-info ul li span {
      align-self: top; }
    .footer .footer-contact-info ul li:last-child {
      margin-bottom: 0; }

.footer .footer-services-List {
  display: flex; }
  .footer .footer-services-List ul {
    width: 50%;
    display: inline-block; }

.footer .footer-useful-List {
  display: flex; }
  .footer .footer-useful-List ul {
    width: 50%;
    display: inline-block; }

.footer ul li {
  margin-bottom: 15px; }
  .footer ul li:last-child {
    margin-bottom: 0; }
  .footer ul li a {
    font-size: 12px;
    color: #ffffff; }
    .footer ul li a:hover {
      color: #0576ee; }

.footer .social-icon ul {
  margin: 0;
  padding: 0; }
  .footer .social-icon ul li {
    list-style-type: none;
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0; }
    .footer .social-icon ul li:last-child {
      margin-right: 0px; }
    .footer .social-icon ul li a {
      font-size: 16px;
      height: 35px;
      width: 35px;
      line-height: 35px;
      display: inline-block;
      text-align: center;
      background-color: #0576ee;
      color: #ffffff;
      border-radius: 4px;
      margin-bottom: 10px; }
      .footer .social-icon ul li a:hover {
        background: #0467d0;
        border-color: #0467d0; }

.footer .footer-subscribe {
  margin-top: 40px; }
  .footer .footer-subscribe form {
    position: relative; }
    .footer .footer-subscribe form .form-control {
      padding-right: 60px;
      border-radius: 4px;
      border: none; }
    .footer .footer-subscribe form .btn {
      position: absolute;
      right: 0;
      line-height: 20px;
      top: 0;
      padding: 13px 20px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
      .footer .footer-subscribe form .btn i {
        padding-right: 0; }

.footer .opening-time ul li {
  border-bottom: 1px solid #1d2936;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #ffffff; }
  .footer .opening-time ul li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none; }

.footer .footer-bottom {
  border-top: 1px solid #1d2936;
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 30px; }
  .footer .footer-bottom .copyright {
    font-size: 12px; }

/* footer-02 */
.footer-02 {
  background-color: #f6f6f6; }
  .footer-02 .footer-contact-info {
    color: #ffffff; }
    .footer-02 .footer-contact-info .phone {
      font-size: 24px;
      color: #ffffff; }
  .footer-02 .footer-bottom {
    border-top: none; }
  .footer-02 .social-icon ul li a {
    font-size: 18px;
    background-color: inherit;
    color: #ffffff; }
    .footer-02 .social-icon ul li a:hover {
      background: #0467d0;
      border-color: #0467d0; }

.footer-02.half-overlay::before {
  width: 35%; }

.footer-02.half-overlay.left-position::before {
  left: 0px;
  background: #0576ee; }

.footer-light {
  background-color: #f6f6f6; }
  .footer-light ul li a {
    color: #000f2d; }
  .footer-light .opening-time ul li {
    color: #000f2d;
    border-bottom: 1px solid #dfdfdf; }
  .footer-light .footer-subscribe .form-control {
    padding: 14px 20px 13px 20px; }

.footer-light .footer-bottom {
  border-top: 1px solid #dfdfdf; }

/* Responsive */
@media (max-width: 991px) {
  /* footer */
  .footer .footer-bottom {
    margin-top: 30px; }
  .footer .footer-middle {
    margin-top: 70px; }
  .footer h6, .footer .h6 {
    margin-bottom: 20px; }
  .footer .footer-bottom {
    padding: 30px 0px 30px; }
  .footer-02 {
    background-color: #0576ee; }
  .footer.footer-02 .footer-bottom .copyright,
  .footer.footer-02 ul li a {
    color: #ffffff; }
  .footer.footer-02 ul li a:hover {
    color: #0576ee; } }

@media (max-width: 767px) {
  .footer .footer-bottom {
    padding: 20px 0px 20px;
    margin-top: 20px; }
  .footer .footer-middle {
    margin-top: 50px; }
  .footer .footer-logo {
    margin-bottom: 20px; } }

/*****************************
  Shop
*****************************/
/* Product */
.product {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px; }
  .product .add-to-cart {
    padding-top: 20px; }
    .product .add-to-cart a {
      background: #0576ee;
      color: #ffffff;
      text-transform: capitalize;
      padding: 10px 20px;
      font-weight: bold;
      border-radius: 4px; }
  .product .product-description {
    text-align: center; }
    .product .product-description .product-title {
      margin: 20px 0px 10px; }
      .product .product-description .product-title a {
        font-size: 16px;
        font-weight: bold;
        color: #333333; }
        .product .product-description .product-title a:hover {
          color: #0576ee; }
    .product .product-description .product-rating {
      margin-bottom: 10px; }
    .product .product-description .product-price {
      font-size: 14px;
      font-weight: bold; }
      .product .product-description .product-price del {
        color: #dfdfdf; }
      .product .product-description .product-price ins {
        color: #0576ee;
        text-decoration: none; }

/* Product Detail */
.shop-single .nav-tabs-02 .nav-item .nav-link {
  font-size: 16px; }

.shop-single .product-detail .product-price-rating .product-price {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-price-rating .product-price h4, .shop-single .product-detail .product-price-rating .product-price .h4 {
    font-size: 20px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-price-rating .product-price h4 span, .shop-single .product-detail .product-price-rating .product-price .h4 span {
      font-size: 24px;
      color: #0576ee;
      margin-left: 8px; }

.shop-single .product-detail .product-price-rating .product-rating {
  margin: 20px 0; }
  .shop-single .product-detail .product-price-rating .product-rating i {
    color: #0576ee; }

.shop-single .product-detail .product-summary {
  margin-top: 30px; }
  .shop-single .product-detail .product-summary a {
    color: #333333;
    margin-right: 10px; }
    .shop-single .product-detail .product-summary a i {
      color: #0576ee;
      margin-right: 10px; }
    .shop-single .product-detail .product-summary a:hover {
      color: #0576ee; }

.shop-single .product-detail hr {
  margin: 30px 0; }

.shop-single .product-detail .product-detail-meta {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-detail-meta span {
    display: block;
    margin: 10px 0; }

.shop-single .product-detail .product-detail-social {
  display: flex;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 10px 0; }
  .shop-single .product-detail .product-detail-social span {
    font-weight: 800; }
  .shop-single .product-detail .product-detail-social ul {
    padding-left: 15px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-detail-social ul li {
      display: inline-block;
      list-style-type: none;
      float: left;
      margin-right: 20px;
      font-size: 16px; }

.shop-single .product-detail .input-group {
  width: 90px;
  float: left;
  margin-right: 20px; }

.form-group .product-rating {
  color: #0576ee; }

.slider-slick .slider-nav .slick-next:before {
  position: absolute;
  right: 5px;
  top: 50%;
  z-index: 999;
  color: #ffffff;
  font-size: 20px;
  transform: translatey(-50%); }

.slider-slick .slider-nav .slick-prev:before {
  position: absolute;
  left: 5px;
  top: 50%;
  z-index: 999;
  color: #ffffff;
  font-size: 20px;
  transform: translatey(-50%); }

.slider-slick .slider-nav:hover .slick-prev {
  left: 0;
  z-index: 99;
  opacity: 1;
  border-radius: 4px; }

.slider-slick .slider-nav:hover .slick-next {
  right: 0;
  opacity: 1;
  border-radius: 4px; }

.slider-slick .slider-nav .slick-prev {
  opacity: 0;
  width: 30px;
  height: 50%;
  background: #0576ee;
  color: #333333;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-next {
  opacity: 0;
  width: 30px;
  height: 50%;
  background: #0576ee;
  color: #333333;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-track .slick-slide {
  margin-left: 5px;
  margin-right: 5px; }

/* commentlist */
.commentlist {
  display: flex; }
  .commentlist .comment-author img {
    width: 70px; }
  .commentlist .comment-content {
    border: 1px solid #eeeeee;
    padding: 15px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 4px; }
    .commentlist .comment-content .reviews {
      display: flex; }
      .commentlist .comment-content .reviews .rating {
        margin-left: auto; }
        .commentlist .comment-content .reviews .rating i {
          color: #0576ee; }

.form-check-label {
  padding-left: 20px; }

/* Shop Sidebar */
.shop-sidebar .widget {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 4px; }
  .shop-sidebar .widget .widget-title {
    margin-bottom: 30px; }
  .shop-sidebar .widget .price-filter {
    display: flex; }
    .shop-sidebar .widget .price-filter a {
      margin-left: auto; }
  .shop-sidebar .widget .product-rating {
    margin-bottom: 10px; }
    .shop-sidebar .widget .product-rating:last-child {
      margin-bottom: 0; }
    .shop-sidebar .widget .product-rating .product-rating-nav {
      display: inline-block; }
    .shop-sidebar .widget .product-rating span {
      float: right; }
  .shop-sidebar .widget .product-banner-thumb {
    position: relative; }
    .shop-sidebar .widget .product-banner-thumb .product-banner-badge {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      background: #0576ee;
      color: #ffffff;
      height: 70px;
      width: 70px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      .shop-sidebar .widget .product-banner-thumb .product-banner-badge .badge-value {
        font-size: 20px;
        font-weight: 700;
        line-height: 1; }
  .shop-sidebar .widget .form-check {
    display: block; }
    .shop-sidebar .widget .form-check span {
      float: right; }
  .shop-sidebar .widget .social ul li {
    display: inline-block;
    list-style-type: none;
    margin-right: 20px;
    font-size: 16px; }
  .shop-sidebar .widget .tagcloud ul li {
    margin-bottom: 10px;
    display: inline-block; }
    .shop-sidebar .widget .tagcloud ul li a {
      color: #333333;
      line-height: 32px;
      border: 1px solid #eeeeee;
      padding: 0 12px;
      border-radius: 4px;
      display: block; }
      .shop-sidebar .widget .tagcloud ul li a:hover {
        background: #0576ee;
        border: 1px solid #0576ee;
        color: #ffffff; }
  .shop-sidebar .widget .widget-categories ul li a {
    font-size: 14px;
    color: #333333;
    line-height: 32px;
    display: block; }
    .shop-sidebar .widget .widget-categories ul li a:hover {
      color: #0576ee; }

.search-field {
  padding-left: 20px;
  box-shadow: none;
  border: 1px solid #dfdfdf;
  height: 50px;
  border-radius: 4px;
  width: 100%; }

/* Checkout */
.checkout-info {
  padding: 25px 25px 25px 120px;
  background: #f6f6f6;
  position: relative;
  border-radius: 4px; }
  .checkout-info:before {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 25px;
    content: "\f2f6";
    font-size: 60px;
    font-family: "Font Awesome 5 Free";
    z-index: 9;
    color: #dfdfdf;
    font-weight: 600; }

.checkout-info-coupon .checkout-info:before {
  content: "\f3ff"; }

.checkout-info-coupon .checkout-coupon {
  padding: 20px;
  border: 2px dashed #eeeeee;
  border-radius: 4px; }

.checkout-review {
  padding: 15px 30px 30px;
  border: 1px solid #eeeeee;
  border-radius: 4px; }
  .checkout-review .table tr th {
    padding: 15px 0;
    font-size: 18px; }
  .checkout-review .table tr th.shipping {
    font-size: 14px; }
  .checkout-review .table tr td {
    text-align: left;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 500; }
  .checkout-review .table tr td.amount {
    font-size: 18px; }

/* Cart */
.cart-table .table {
  border: 1px solid #eeeeee;
  margin-bottom: 0;
  font-size: 14px; }
  .cart-table .table tr th {
    border: none;
    padding: 15px 10px;
    color: #ffffff;
    background-color: #0576ee;
    text-align: left;
    vertical-align: middle; }
  .cart-table .table tr td {
    border: none;
    border-top: 1px solid #eeeeee;
    padding: 15px 10px;
    color: #333333;
    font-weight: 500;
    text-align: left; }
    .cart-table .table tr td.product-remove {
      padding: 15px 25px;
      text-align: center; }
    .cart-table .table tr td.product-quantity .form-control {
      width: 100px; }
    .cart-table .table tr td a {
      color: #333333; }
      .cart-table .table tr td a:hover {
        color: #0576ee; }
    .cart-table .table tr td .form-group {
      margin-bottom: 0px; }

.cart-table .actions {
  display: flex;
  border: 1px solid #eeeeee;
  border-top: none;
  padding: 15px 10px;
  align-items: center; }
  .cart-table .actions .coupon form {
    display: flex; }
  .cart-table .actions .coupon .input-text {
    border: 1px solid #dfdfdf;
    border-radius: 0px;
    height: 48px;
    padding: 10px 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    border-radius: 4px;
    margin-right: 10px; }
  .cart-table .actions .coupon .btn {
    padding: 12px 20px; }
  .cart-table .actions .update-cart {
    margin-left: auto; }

.cart-table .table td.product-thumbnail img {
  width: 70px;
  height: auto; }

.cart-totals {
  background: #f6f6f6;
  padding: 20px;
  border-radius: 4px; }
  .cart-totals .cart-totals-title {
    margin-bottom: 20px;
    text-align: center; }
  .cart-totals .table {
    font-size: 14px; }
    .cart-totals .table tr th {
      text-align: left;
      border: none;
      border-top: 1px solid #eeeeee;
      vertical-align: top;
      background: #ffffff; }
    .cart-totals .table tr td {
      text-align: right;
      border: none;
      border-top: 1px solid #eeeeee;
      background: #ffffff; }
    .cart-totals .table tr.shipping .form-group {
      margin-bottom: 0; }
    .cart-totals .table tr.shipping .form-check-label {
      padding-left: 0px; }
    .cart-totals .table tr.shipping p {
      margin-bottom: 0; }
    .cart-totals .table tr.order-total th {
      font-size: 18px;
      font-weight: bold; }
    .cart-totals .table tr.order-total td {
      font-size: 26px;
      font-weight: bold; }
    .cart-totals .table .cart-subtotal th {
      font-size: 18px;
      font-weight: bold;
      border: none; }
    .cart-totals .table .cart-subtotal td {
      font-size: 18px;
      font-weight: bold;
      border: none; }
  .cart-totals .checkout-button {
    width: 100%;
    text-align: center;
    padding: 15px 25px; }

/* shop-category */
.shop-category {
  padding: 25px 15px;
  border-right: 1px solid #f6f6f6; }
  .shop-category h5, .shop-category .h5 {
    margin-bottom: 20px; }
  .shop-category ul {
    margin-bottom: 0; }
    .shop-category ul li a {
      padding: 5px 0;
      display: block;
      color: #333333; }

@media (max-width: 768px) {
  .slider-slick .slider-nav .slick-prev {
    display: none !important; }
  .slider-slick .slider-nav .slick-next {
    display: none !important; }
  .shop-single .nav-tabs-02 .nav-item {
    width: 33% !important; } }
