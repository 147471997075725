/*****************************
  Header
*****************************/
.header {
  background: $white;
  z-index: 99;
  position: relative;
  .container-fluid {
    padding: 0 60px;
    align-items: initial;
  }
}

.navbar-toggler {
  &:focus {
    box-shadow: none;
  }
}

/* topbar */
.header .topbar {
  background: $primary;
  padding: 10px 20px;
}

.header .topbar {
  a {
    color: $white;
    &:hover {
      color: $primary;
    }
  }
}

.header .topbar  .dropdown {
  margin-right: 20px;
  .dropdown-toggle {
    padding: 12px 0;
    i {
      font-size: 10px;
    }
  }

  .dropdown-menu {
    a {
      color: $gray-8;
      &:hover {
        color: $primary;
      }
    }
  }
}

.header .topbar .social {
  display: inline-block;
  ul {
    margin: 0;
    li {
      display: inline-block;
      padding: 0 4px;
      a {
        color: $white;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.header .topbar .login {
  display: inline-block;
  a {
    color: $white;
    &:hover {
      color: $primary;
    }
  }
}


/* topbar dropdown-menu */
.topbar .dropdown .dropdown-menu {
  border-radius: 0;
  border: none;
  box-shadow: 0px 5px 10px rgba($black, 0.1);
  padding: 10px;
}

.topbar .dropdown .dropdown-menu a {
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 3px;
}

.topbar .dropdown .dropdown-menu a:hover {
  background: rgba($primary, 0.1);
  color: $primary;
}


/* navbar */
.navbar {
  padding: 0;
}

.navbar .navbar-nav .nav-link {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
  color: $gray-9;
  text-transform: capitalize;
  &:hover {
    color: $primary;
  }
}

.dropdown-menu {
  z-index: 9999;
}

.navbar-collapse {
  position: relative;
}

.navbar-light {
  color: $white;
  background-color: $gray-1;
  border-color: $border-color;
}

.navbar-light .navbar-nav > li > a {
  color: $white;
}

.navbar-light .navbar-nav > .dropdown > a .caret {
  border-top-color: $white;
  border-bottom-color: $white;
}

.navbar-brand {
  padding: 30px 0px;
  margin-right: 0;
}

.navbar-brand img {
  height: 40px;
}

.navbar .navbar-brand {
  color: $white;
  flex: 0 0 200px;
}

.header .navbar-nav > li {
  display: flex;
}

.header .navbar-nav li > a {
  align-items: center;
  display: flex;
  padding: 3px 0px;
  color: $gray-8;
  text-transform: capitalize;
  font-size: 14px;
}

.header .navbar-nav li > a:hover {
  color: $primary;
}

.header .navbar-nav li > a i {
  margin-left: 5px;
  font-size: 13px;
  color: $gray-9;
}

.header .add-listing {
  align-self: center;
  flex: 0 0 170px;
  text-align: right;
}

.megamenu {
  padding: 20px 20px;
  width: 100%;
}

.megamenu > div > li > ul {
  padding: 0;
  margin: 0;
}

.megamenu > div > li > ul > li {
  list-style: none;
}

.megamenu .card {
  outline: none;
}

.megamenu .card:hover,
.megamenu .card:focus {
  outline: 1px solid $black;
}

.megamenu > div > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: $gray-9;
  white-space: normal;
}

.megamenu > div > li > ul > li > a:hover,
.megamenu > div > li > ul > li > a:focus {
  text-decoration: none;
  color: $gray-9;
  background-color: $gray-1;
}

.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  color: $gray-1;
}

.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}

.megamenu.dropdown-header {
  color: $primary;
  font-size: 18px;
}

.header li > .dropdown-item:focus,
.header li > .dropdown-item:hover {
  color: $primary;
  background: none;
}

.header .dropdown-item.active,
.header .dropdown-item:active,
.header .dropdown-item:focus,
.header .dropdown-item:hover {
  background: none;
}

.header .dropdown-toggle::after {
  content: none;
}

.header .navbar-collapse {
  align-items: inherit;
}

.header .megamenu .dropdown-item {
  padding: 0px;
}

.header .navbar .dropdown-menu a.dropdown-item {
  min-width: 180px;
}

.header .navbar .dropdown-menu {
  padding: 15px;
}

.header .navbar .dropdown-menu li a i {
  margin-left: auto;
}

.navbar .dropdown > .dropdown-menu li > a {
  font-size: 14px;
  padding: 10px 20px 10px 0px;
  border-radius: $border-radius;
  position: relative;
}

.navbar .dropdown > .dropdown-menu li > a:hover {
  padding-left: 15px;
}

.navbar .dropdown > .dropdown-menu li > a:before{
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 0;
  height: 100%;
  transition: all 0.5s ease;
}

.navbar .dropdown > .dropdown-menu li > a:hover:before{
  width: 100%;
  background:rgba($primary, 0.1);
  color: $primary;
  border-radius: $border-radius;
}


/* header-transparent */
.header-transparent {
  position: absolute;
  background: transparent;
  width: 100%;
  z-index: 999;

}

.header-transparent .navbar .navbar-nav .nav-link {
  color: $gray-8;
  &:hover {
    color: $primary;
  }
}

.navbar .navbar-nav .nav-item.active .nav-link {
  color: $primary;
}

.navbar .navbar-nav .nav-item.active .nav-link i {
  color: $primary;
}


.navbar .dropdown > .dropdown-menu li.active > a{
  padding-left: 15px;
  color: $primary;
}

.navbar .navbar-nav .dropdown-menu li.active > a:before {
  background: rgba($primary, 0.1);
  color: $primary;
  border-radius: $border-radius;
}

.navbar .navbar-nav .dropdown-menu li.active > a:before {
  width: 100%;
}

.header-transparent .add-listing {
  align-self: inherit;
  flex: inherit;
  text-align: right;
}

.header-transparent .login a {
  color: $white;
  &:hover {
    color: $primary;
  }
}


.header-transparent.is-sticky .main-header{
  background:$white;
}

.header.header-03.header-transparent.header-sticky:before {
    content: "";
    background-color: $primary !important;
    position: absolute;
    height: 100%;
    width: 50%;
    right: 0;
}

.header.header-03.is-sticky .main-header .navbar:before {
    content: "";
    background-color: $primary !important;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 100%;
}


/* sticky header */
.header.is-sticky .main-header {
  position: fixed !important;
  width: 100%;
  background: $white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  top: 0;
  z-index: 999;
  left: 0;
  box-shadow:$box-shadow;

  .header-contact-info {
    display: none !important;
  }

  .navbar {
    position: relative;
    top: 0px;
    right: 0px;
    left: 0;
    border-top: none !important;
    width: 100%;
    .navbar-brand {
      bottom: 20px;
    }
  }
}


/* sticky header */
.header-transparent .is-sticky {
  position: fixed !important;
  width: 100%;
  background: $white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  top: 0;
  z-index: 9;

  .header-contact-info {
    display: none !important;
  }

  .navbar {
    position: relative;
    top: 0px;
    right: 0px;
    left: 0;
    border-top: none !important;
    width: 100%;
  }
}

.medi-tools-actions {
  padding: 0;
  display: flex;
  margin: 0;
  align-items: center;

  .medi-tools-action {
    margin: 0 15px;
    position: relative;
    i {
      font-size: 24px;
    }
  }
}

.medi-tools-wishlist {
  .wishlist.medistore-wishlist-count{
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
    position: absolute;
    top: 0;
    right: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    margin-top: -2px;
    margin-right: -20px;
    color: $white;
    background: $primary;
    border-radius: 50%;
  }
}

.medi-tools-cart{
  .cart-link{
    display: flex;
    align-items: center;

    .cart-count{
      font-weight: 500;
      font-style: normal;
      font-size: 11px;
      position: absolute;
      top: 0;
      right: 50%;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      margin-top: -2px;
      margin-right: -20px;
      color: $white;
      background: $primary;
      border-radius: 50%;
    }
  }
  .cart-contents{
    box-shadow: none;
    color: $gray-8;
    position: absolute;
    top: 100%;
    right: -13px;
    width: 360px;
    z-index: 9999;
    margin-top: 5px;
    opacity: 0;
    visibility: hidden;
    border-radius: 0;
    padding: 0px;
    transition: all .3s ease-in-out;
    .shopping_cart{
      .shopping_cart_content{
        background-color: $white;
        padding: 15px;
        box-shadow:$box-shadow ;
        text-align: center;
        p{
          color: $gray-8;
          margin:15px 0;
        }
      }
    }
  }
  &:hover{
    .cart-contents{
      opacity: 1;
      visibility:visible;
    }
  }
}

/* header-02 */
.header.header-02 .navbar.navbar-light {
  background-color: transparent;
  background-image: none;
}

.header.header-02 .navbar .navbar-nav .nav-link{
  color:$white;
  transition: all 0.3s ease;
}

.header.header-02 .navbar-nav .nav-link:hover,
.header.header-02 .navbar-nav .nav-link:focus {
  color: $dark;
}

.header.header-02 .navbar-nav .nav-link:hover i,
.header.header-02 .navbar-nav .nav-link:focus i {
  color: $dark;
}

.header.header-02 .add-listing .btn{
  padding: 12px 30px;
}

.header.header-02 .navbar-nav li > a i {
  color:$white;
  transition: all 0.3s ease;
}

.header.header-02 .navbar .navbar-nav .nav-item.active .nav-link {
  color: $dark;
}

.header.header-02 .navbar .navbar-nav .nav-item.active .nav-link i {
  color: $dark;
}

.header.header-02 .add-listing {
  flex: 0 0 160px;
}

.navbar .dropdown > .dropdown-menu li > a:before {
  content: none;
}

.header.header-02.is-sticky .main-header {
  background-color: $primary;
}



/* header-03 */
.header.header-03 .navbar-nav li > a i {
  color:$gray-8;
}

.header.header-03 .navbar.is-sticky .navbar-brand {
  padding: 20px 0px;
}

.header.header-03 .navbar .navbar-nav .nav-item.active .nav-link,
.header.header-03 .navbar .navbar-nav li.active > a i,
.header.header-03 .navbar .navbar-nav .nav-link:hover,
.header.header-03 .navbar .navbar-nav .nav-link:hover i{
  color: $primary;
}

.header.header-03 .navbar.is-sticky .navbar-nav .nav-item.active .nav-link,
.header.header-03 .navbar.is-sticky .navbar-nav li.active > a i,
.header.header-03 .navbar.is-sticky .navbar-nav .nav-link:hover,
.header.header-03 .navbar.is-sticky .navbar-nav .nav-link:hover i{
  color: $primary;
}

.header.header-03 .navbar {
  position: relative;
  background-color:$white;
}

.header.header-03 .navbar .navbar-nav .nav-link {
  padding: 10px 15px;
}

.header.header-03 .navbar .navbar-brand{
  padding-left:25px;
}

.header.header-03 .navbar .navbar-brand {
  margin-right: 0px;
  display: block;
}

.header.header-03 .navbar .add-listing {
  display: flex;
  flex: 0 0 298px;
  align-items: center;
  margin-right: -1px;
}

.header.header-03 .navbar .add-listing .btn {
  padding: 39px 30px;
  border-radius: 0px;
}

/* shop-header */
.header.shop-header .navbar.is-sticky .navbar-brand{
  padding: 30px 0px;
}


/* Responsive */
@media (min-width:992px) {

  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    left: 100%;
    transform: translate3d(-50%, 10px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    box-shadow: 0px 5px 10px rgba($black, 0.1);
    visibility: hidden;
    opacity: 0;
    display: block !important;
  }

  .header .navbar .dropdown-menu.megamenu {
    left: 50%;
  }

  .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 600px;
  }

  .navbar-nav .mega-menu {
    position: static;
  }

  .header .navbar .dropdown:hover > .dropdown-menu {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
    opacity: 1;
  }

  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
    left: 100%;
    right: auto;
    transform: translate3d(15px, 10px, 0);
  }

  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
    right: 100%;
    left: auto;
    transform: translate3d(-15px, 10px, 0);
  }

  .navbar-nav li:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1;
  }


  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
  }

  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
  }
}


@media (max-width:1400px) {

  .navbar .navbar-nav .nav-link {
    padding: 10px 12px;
  }

}

@media (max-width:1199px) {

  .navbar .navbar-nav .nav-link {
    padding: 10px 7px;
    font-size: 14px;
  }

  .header-transparent .login {
    display: none;
  }

  .header-transparent .navbar-brand {
    margin-right: 20px;
  }

  .header.header-02 .navbar .navbar-nav .nav-link {
    padding: 10px 12px;
  }

  .header.header-03 .container-fluid{
    padding: 0 20px;
  }
  .header.header-03 .add-listing .btn{
    padding: 12px 20px;
  }

  .header.header-03 .navbar .add-listing {
    flex: 0 0 159px;
  }


}

@media (max-width:1023px) {
  .header.header-03 .container-fluid{
    padding: 0 15px;
  }
  .header.header-03 .add-listing .btn{
    padding: 12px 15px;
  }
  .header.header-03 .navbar.is-sticky{
    padding: 0 15px;
  }
}

@media (max-width:991px) {


  /* sticky header */
  .header .is-sticky .navbar {
    top: 0;
    right: 0;
  }

  .header .container-fluid {
    padding: 0 20px;
  }

  .navbar-toggler {
    border: none;
    position: absolute;
    height: 75px;
    right: 0;
  }
  .navbar-brand {
    padding: 20px 0px;
  }

  .header-sticky.is-sticky .navbar-brand {
    padding: 20px 0px 20px 15px;
  }

  .megamenu {
    margin-left: 0;
    margin-right: 0;
  }

  .megamenu > li {
    margin-bottom: 30px;
  }

  .megamenu > li:last-child {
    margin-bottom: 0;
  }

  .megamenu.dropdown-header {
    padding: 3px 15px !important;
  }

  .navbar-nav .open .dropdown-menu .dropdown-header {
    color: $white;
  }

  .header .navbar-nav > li {
    display: block;
  }

  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    box-shadow: none;
    background: $gray-1;
    padding: 14px 30px;
    font-size: 13px;
    max-height: 300px;
    overflow-x: scroll;
  }

  .header .navbar .dropdown-menu .dropdown-menu {
    background: $white;
  }

  .header .navbar .dropdown-menu .dropdown-menu .dropdown-menu {
    background: $gray-1;
  }

  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 9999;
    background: $white;
    width: 100%;
    left: 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    box-shadow: 0px 5px 10px rgba(black, 0.1);
  }

  .navbar-brand img {
    height: 34px;
  }

  .navbar .navbar-nav {
    padding: 10px 0;
  }

  .navbar .navbar-nav .nav-link {
    padding: 10px 20px;
  }

  .navbar .navbar-nav .nav-link i {
    margin-left: auto;
  }

  .header .navbar .nav-title {
    margin-bottom: 10px !important;
    margin-top: 20px;
  }

  .add-listing .btn {
    font-size: 12px;
    padding: 6px 17px;
  }

  .navbar .dropdown > .dropdown-menu li > a {
    font-size: 13px;
  }

  .header .navbar-nav > li {
    border-bottom: 1px solid $border-color;
  }

  .header .navbar-nav > li:last-child {
    border-bottom: 0;
  }

  .header-transparent {
    position: relative;
    background: $gray-8;
  }

  .header-transparent .navbar .navbar-nav .nav-link {
    color: $gray-8;
  }

  .header .add-listing {
    margin-right: 60px;
  }

  .header.header-02 .add-listing {
    margin-right: 60px;
  }

  .header-02  .navbar-toggler i{
    color: $white;
  }

  .header.header-02 .navbar .navbar-nav .nav-link{
    color:$primary;
    padding:10px 20px;
  }

  .header.header-02 .navbar .navbar-nav .nav-link{
    color: $dark;
  }

  .header.header-02 .navbar .navbar-nav .nav-link:hover,
  .header.header-02 .navbar .navbar-nav .nav-item.active .nav-link{
    color:$primary;
  }

  .header.header-02 .navbar .navbar-nav .nav-item.active .nav-link i {
    color:$primary;
  }

  .header.header-02 .navbar-nav li > a i {
    color: $dark;
  }

  /* Header-03 */
  .header-03.header-transparent{
    background:$white;
  }

  .header.header-03 .navbar .add-listing {
    margin-right: 0px;
    display: none;
  }

  .header.header-03 .is-sticky .add-listing{
    margin-right: 50px;
  }

  .header.header-03 .navbar .navbar-nav .nav-link,
  .header.header-03 .navbar-nav li > a i {
    color: $dark;
  }

  .header.header-03 .navbar.is-sticky .navbar-nav .nav-item.active .nav-link,
  .header.header-03 .navbar.is-sticky .navbar-nav li.active > a i,
  .header.header-03 .navbar.is-sticky .navbar-nav .nav-link:hover,
  .header.header-03 .navbar.is-sticky .navbar-nav .nav-link:hover i {
    color: $primary;
  }

  .header.header-03 .navbar:after{
    background-color:$white !important;
  }

  .header.shop-header .navbar.is-sticky .navbar-brand {
    padding: 15px 0px;
  }
  .header.header-03.header-transparent.header-sticky:before{
    background-color: $white !important;
  }

  .header.is-sticky .main-header .navbar:before{
    background-color: $white !important;
  }

}


@media (max-width:767px) {

  .header .topbar .dropdown .dropdown-toggle {
    padding: 4px 0;
    display: inline-block;
  }

}


@media (max-width:575px) {

  .navbar-light .navbar-brand {
    flex: none;
  }

  .header .add-listing {
    flex: none;
  }
}

@media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {

  .navbar .navbar-nav .nav-item .nav-link {
    padding-top: 27px;
    padding-bottom: 27px;
  }

  .navbar .navbar-nav .nav-link i {
    margin-top: 5px;
  }

}
