/*****************************
  Banner
*****************************/
.banner{
  overflow: hidden;
}

.banner-img1{
  left: -200px;
  top: -120px;
}

.banner-img2{
  right: 70px;
}

.banner-img3{
  left: -250px;
}

.banner-img4{
  right: 0px;
}

.banner-img5{
  right: -70px;
  bottom: -70px;
}

.banner-01 {
  background-image: linear-gradient(to left top, #f5faff, #edf5ff, #d2e8ff, #c3e0ff, #c3e0ff);
  .banner-content {
    h1 {
      font-size: 54px;
      font-weight: 800;
    }
  }
}

.banner-02{
	padding-top: 170px;
	&:before{
    position: absolute;
    content: "";
    left: 0;
    width: 50%;
    height: 100%;
    top: 0;
	}

	.banner-img1{
		position:absolute;
		top: 20px;
		left: auto;
		right: 10.5%;;
	}

	.banner-img2{
		position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left:15px;
    right: auto;
	}

	.banner-img3 {
    right: 15px;
    position: absolute;
    top: 10px;
    left: auto;
	}

	.banner-img4 {
    right: 13%;
    position: absolute;
    bottom: 10%;
  }
}

.banner-03{
  .swiper-container {
    height: 100%;
  }
  .banner-content{
    padding:50px 0px;
    .banner-img1{
      top: 0;
      left: 0;
    }
    .banner-img2{
      top: 0;
      right: 30%;
    }
    .banner-img3{
      bottom:0;
      left: 0;
    }
    .banner-img4{
      bottom:0;
      right:30%;
    }
    h1{
      font-size: 72px;
      line-height: 72px;
      font-weight: 500;
    }
  }
  .banner-round-bg{
    top: -50px;
    right: 3%;
  }
  .banner-bg2{
    bottom:0;
    right: -280px;
  }
  .service-icon {
    display: flex;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      margin-right: 20px;
      background-color: rgba($white, .4);
      border-radius: $border-radius-10;
    }
  } 

  .swiper-button-next {
    background-color: rgba($white, .3);
    color: $primary;
    border-radius: $border-radius;
    &:hover {
      background-color: rgba($white, 1);    
      color: $primary;
    }
  }

  .swiper-button-prev {
    background-color: rgba($white, .3);
    color: $primary;
    border-radius: $border-radius;
    &:hover {
      background-color: rgba($white, 1); 
      color: $primary;
    }
  }

}

/*swiper-slide*/
.swiper-button-next,
.swiper-button-prev {
  background-image: none !important;
  z-index: 1;
  background:rgba($primary,0.05);
  line-height: 50px;
  height: 50px;
  width: 30px;
  text-align: center;
  color: $primary;
  transition: all 0.5s ease;
  border-radius: $border-radius;
  &:hover{
    background:rgba($primary,1);
    color: $white;
  }
  &:focus{
    border: none;
    border-radius: 0;
    outline: none;
  }
}

.banner-03 .slide-02 .banner-bg2 {
    right: auto;
    left: -300px;
}
.banner-03 .slide-02 .banner-round-bg{
    left: 3%;
    right: auto;
}


.inner-banner{
   z-index: 9;
   position: relative;
  .inner-subtitle{
    font-size: 18px;
    font-weight: 500;
  }
}

@media (max-width:1199px) {
  .banner-03 .banner-content h1, 
  .banner-03 .banner-content .h1 {
    font-size: 60px;
    line-height: 60px;
  }

}

@media (max-width:991px) {

  .banner-02{
    padding-top: 60px;
  }

  .swiper-slide .slider-1 h1{
    font-size: 36px;
  }

  .banner-03.h-100vh {
    height: auto !important;
  }

  .banner-03 .banner-content h1, 
  .banner-03 .banner-content .h1, 
  .banner-03 .banner-content .h1 {
    font-size: 50px;
    line-height: 50px;
  }

  .banner-03 .banner-content .lead {
    font-size: 16px;
  }
  .banner-03 .banner-content{
    padding:100px 0px;
  }

}

@media (max-width:767px) {
  .banner-03 .banner-round-bg {
    top: -65px;
  }

  .banner-03 .banner-content h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .banner-03 .service-icon li {
    width: 80px;
    height: 80px;
  }

  .banner-03 .service-icon li .fa-4x {
    font-size: 3em;
  }

}

@media (max-width:479px) {

  .banner-03 .banner-content h1 {
    font-size: 30px;
    line-height: 30px;
  }

  .banner-03 .banner-round-bg {
    top: 0;
  }

  .swiper-slide .slider-1 h1 {
    font-size: 30px;
  }

}