/*****************************
  Typo
*****************************/
body {
	font-family: $font-base;
	font-weight: normal;
	font-style: normal;
	font-size: 14px;
	color: $body-color;
	overflow-x: hidden;
}

a {
	outline: medium none !important;
	text-decoration: none !important;
	color: $primary;
	transition: all 0.3s ease-in-out;

	&:focus {
		color: $primary;
		text-decoration: none !important;
	}

	&:hover {
		color: $primary;
		text-decoration: none !important;
	}
}

input {
	outline: medium none !important;
	color: $primary;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	font-family: $font-hedding;
	font-weight: 500;
	color: $gray-8;
	margin-top: 0px;
	text-transform: capitalize;
	a {
		color: inherit;
	}

}

section{
	position: relative;
}

label {
	font-weight: normal;
	margin-bottom: 10px;
}

h1,.h1 {
	font-size: 48px;
	font-style: normal;
}

h2,.h2 {
	font-size: 40px;
}

h3,.h3 {
	font-size: 30px;
}

h4,.h4 {
	font-size: 24px;
	font-weight: bold;
}

h5,.h5 {
	font-size: 20px;
	font-weight: bold;
}

h6,.h6 {
	font-size: 18px;
	font-weight: bold;
	line-height: 1.5;
}

p {
	font-weight: normal;
	line-height: 1.8;
}

*::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}


// container-fluid
.container-fluid.container-space {
	padding: 0 100px;
}


// form-control
.form-control {
	border-color:$gray-2;
	border-radius: 0px;
	height: auto;
	padding: 10px 20px;
	box-shadow: none;
	font-size: 14px;
	font-weight: 600;
	color: $gray-8;
	background-clip: inherit !important;
	border-radius:$border-radius;
	&:focus {
		box-shadow: none;
		border-color: $secondary;
	}
}

.form-control::-moz-placeholder {
	color: $gray-4;
}

.form-control::-ms-input-placeholder {
	color: $gray-4;
}

.form-control::-webkit-input-placeholder {
	color: $gray-4;
}

form {
	.form-group{
		.input-group-append{
			display: flex;
			.input-group-text{
				border: 1px solid $gray-2;
				border-top-left-radius: 0;
	   		border-bottom-left-radius: 0;
			}
		}
	}
}


// custom-file
.custom-file {
	height: 50px;
	.custom-file-input {
		height: 50px;
		color: $gray-8;
		border-radius: $border-radius;
		border-color: $border-color;
		&:focus {
			box-shadow: none;
			border-color: $primary;
		}
	}

	.custom-file-label {
		height: 50px;
		font-size: 14px;
		font-weight: 600;
		color: $gray-8;
		border-color: $border-color;
		padding: 15px 90px 15px 20px;
		border-radius: $border-radius;
		&:after {
			height: 48px;
			padding: 14px 25px;
		}
	}
}

.custom-file-input:focus~.custom-file-label {
	box-shadow: none;
	border-color: $primary;
}


// input-group-prepend
.input-group-prepend,
.input-group-append {
	.input-group-text {
		width: 50px;
		background: transparent;
		border-color: $border-color;
		box-shadow: none;
		justify-content: center;
	}
}


// checkbox
.custom-control-input {
	&:checked~.custom-control-label {
		&:before {
			background: $primary;
			border-color: $primary;
		}
	}

	&:not(:disabled) {
		&:active~.custom-control-label {
			&:before {
				background: transparent;
				border-color: transparent;
			}
		}
	}

	&:focus~.custom-control-label {
		&:before {
			box-shadow: none;
		}
	}

	&:focus {
		&:not(:checked)~.custom-control-label {
			&:before {
				border-color: $border-color;
			}
		}
	}
}

.custom-control-label {
	&:before {
		top: 2px;
	}

	&:after {
		top: 2px;
	}
}

.custom-checkbox {
	.custom-control-label {
		&:before {
			border-radius: 0;
			border: 1px solid $gray-2;
		}
	}
}


// border
.border {
	border-color: $border-color !important;
}

.border-top {
	border-top-color: $border-color !important;
}

.border-left {
	border-left-color: $border-color !important;
}

.border-right {
	border-right-color: $border-color !important;
}

.border-bottom {
	border-bottom-color: $border-color !important;
}


// badge
.badge {
	border-radius: $border-radius;

	+ {
		.badge {
			margin-left: 6px;
		}
	}
}

.badge-md {
	padding: 5px 8px;
	font-size: 13px;
	font-weight: normal;
}

.badge-primary {
	background: $primary;
}


// back-to-to
.back-to-top {
	background: $primary;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-block;
  font-size: 16px;
  color: $white;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border-radius: $border-radius;

  z-index: 9;
	&:hover {
		color: $white;
	}

	&:focus {
		color: $white;
	}
}


// breadcrumb
.breadcrumb {
	z-index: 9;
	font-weight: 700;
	background: $white;
	display: inline-flex;
	box-shadow:$box-shadow;
	font-family: $font-hedding;
	position: absolute;
	bottom: -20px;

	.breadcrumb-item {
		padding-right: 5px;
		align-items: center;

		a {
			color: $gray-8;
			font-size: 16px;
			&:hover {
				color: $primary;
			}
		}

		i {
			padding-right: 10px;
			font-size: 14px;
			color: $gray-8;
		}

		&:before {
			display: none;
		}
	}

	.active {
		span {
			color: $primary;
			font-size: 16px;
		}
	}
}


// pagination
.pagination {
	.page-item {
		.page-link {
			font-size: 18px;
			font-weight: 700;
			padding: 12px 20px;
			color: $gray-3;
			border: 2px solid $gray-2;
			margin-right: 15px;
			border-radius: $border-radius;
			&:hover{
				background: $primary;
				border-color: $primary;
				color: $white;
			}
			&:focus {
				box-shadow: none;
			}
		}
		&:last-child{
			.page-link{
				
				margin-right: 0;
			}
		}
	}

	.page-item.active {
		.page-link {
			background: $primary;
			border-color: $primary;
			color: $white;
		}
	}
}


// blockquote
.blockquote {
	font-size: 16px;
	border-left: 10px solid $primary;
	padding-left: 30px;
	font-style: italic;
	display: block;
}

.blockquote-quote {
	position: relative;
	font-size: 18px;
	border-left: 0 solid $primary;
	padding-left: 30px;
	font-style: italic;
	display: block;
	z-index: 1;
	p {
		position: relative;
	}

	i {
		position: absolute;
		z-index: 0;
		top: 20px;
		left: 0;
		font-size: 60px;
		line-height: 60px;
		color: $gray-2;
	}
}


// table
.table-bordered {
	td {
		border-color: $border-color;
	}

	th {
		border-color: $border-color;
	}
}

.table {
	thead {
		th {
			border-bottom-color: $border-color;
		}
	}
}

.table-striped {
	tbody {
		tr {
			&:nth-of-type(odd) {
				background: $gray-1;
			}
		}
	}
}

.table td, .table th {
	vertical-align: middle;
}

@media only screen and (min-width:768px) and (max-width:991px) {

	.container {
		max-width: 96%;
	}
}


@media (max-width:767px) {

	h1 {
		font-size: 32px;
	}

	h2 {
		font-size: 28px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 16px;
	}

	.container {
		max-width: 100%;
	}

}


@media (max-width:575px) {

	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 26px;
	}

	h3 {
		font-size: 24px;
	}

}
