/*****************************
  Feature Info
*****************************/
.feature-items {
  text-align: center;
  padding:40px 20px;
  background:$white;
  box-shadow:$box-shadow;
  border-radius:$border-radius;
  .feature-icon{
    margin-bottom: 10px;
    font-size: 60px;
    line-height: 60px;
    i{
      font-size: 60px;
      color: $primary;
    }
  }

  .feature-content{
    h6{
      margin-bottom: 7px;
      color: $gray-9;
      font-weight: 500;
    }
  }
}

.feature-items-style-02 {
  background:$white;
  padding: 40px 20px 40px 20px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  transition: all 0.5s ease;
  box-shadow:$box-shadow;
  border-radius:$border-radius;
  cursor: pointer;

  .feature-number {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 16px;
    color: rgba($primary, 0.5);
  }

  &:hover{
    background-color: $primary;
    color: $white;
    .feature-icon{
      i{
        color: $white;
      }
    }

    h6{
      color: $white;
    }

    .feature-number {
      color: rgba($secondary, 0.5);
    }
  }
}

.feature-items-style-03 {

  [class*="col-"] {
    border-right: 1px solid rgba($black, .05);
    border-bottom: 1px solid rgba($black, .05);
    &:nth-child(3) {
      border-right: none;
    }
    &:nth-child(n+4) {
      border-bottom: none;
    }
    &:last-child {
      border-right: none;
    }
  }

  .feature-items {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0px;
  }

}


/*service-items*/
  .service-items.active{
    box-shadow: 0 0.1rem 1rem rgba($black, 0.1);
    .service-content{
      .icon-btn{
        opacity: 1;
      }
    }
  }


.service-items{
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 30px;
  .service-img{
   img{
    border-radius: 4px 4px 0 0;
   }
  }
  &:hover{
    box-shadow:$box-shadow;
    .service-content{
      border-radius: 0 0 4px 4px;
      .icon-btn{
        opacity: 1;
      }
    }
  }
  .service-content{
    padding: 30px;
    span{
      color: $secondary;
      margin-bottom: 5px;
      display: block;
      font-size: 12px;
    }
    h5{
      color: $primary;
      margin-bottom: 20px;
    }
    .icon-btn {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 40px;
      line-height: 40px;
      background: $primary;
      color: $white;
      border-radius: 50px;
      opacity: 0;
    }
  }
}

.service-items-style-02 {
  border-radius: $border-radius-10;
  overflow: hidden;
  .service-content {
    position: absolute;
    top: 50%;
    background: rgba($primary, 0.7);
    width: 100%;
    height: 101%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    .icon-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      color: $primary;
      border-radius: $border-radius-10;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }
  &:hover{
    .service-content {
      opacity: 1;
      visibility: visible;
    }

    .icon-btn{
      bottom:15px;
    }
  }
}


/* service-detail */
.service-detail{
  .service-items{
    margin-bottom: 30px;
  }
}

.sidebar{
  .widget{
    .widget-services{
      ul.list-style-underline{
        li{
          padding-bottom: 0;
          margin-bottom: 0;
          a{
            padding:15px;
            transition: all 0.5s ease;
            &:hover{
              background-color:$primary;
              color: $white;
            }
          }
        }
      }
    }

    .widget-worktime{
      ul{
        li{
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .widget-services-care{
      .services-care-icon{
        i{
          font-size: 80px;
          color: $white;
          line-height: 80px;
        }
      }

      .services-care-title{
        font-weight: 700;
      }
    }
  }
}

.departments-items{
  overflow: hidden;
  box-shadow: $box-shadow;
  background-color: $white;
  border-radius: $border-radius;
  .departments-content{
    padding: 75px 30px 40px;
    position: relative;
    .departments-icon{
      height: 70px;
      width: 70px;
      line-height: 70px;
      display: inline-block;
      text-align: center;
      background: $primary;
      margin-bottom: 25px;
      border-radius: $border-radius;
      position: absolute;
      top: -35px;
      i{
        font-size:40px;
        color: $white;
      }
    }
    .departments-icon-right{
       i{
        font-size:120px;
        line-height: 1;
        color: rgba($primary, .05);
        position: absolute;
        right: -25px;
        top: 20px;
      }
    }
  }
}


@media (max-width:767px) {
  .feature-items-style-03 {

    [class*="col-"] {

      &:nth-child(3) {
        border-right: 1px solid rgba($black, .05);
      }
      &:nth-child(n+4) {
        border-bottom: 1px solid rgba($black, .05);
      }
      &:nth-child(n+5) {
        border-bottom: none;
      }
      &:nth-child(2n-2) {
        border-right: none;
      }
    }
  }

}


@media (max-width:575px) {
  .feature-items-style-03 {

    [class*="col-"] {
      &:nth-child(1n-1) {
        border-right: none;
      }
      &:nth-child(n+5) {
        border-bottom: 1px solid rgba($black, .05);
      }
      &:last-child {
        border-bottom: none;
      }
    }

  }
}
