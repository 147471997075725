/*****************************
  Counter
*****************************/
.counter {
  align-items: center;
	.counter-icon {
		margin-bottom: 15px;
		i {
			color: $primary;
			margin-right: 20px;
			font-size: 50px;
			line-height: 50px;
		}
	}

	.counter-content {
		align-self: center;
		.timer {
			position: relative;
			font-size: 40px;
			line-height: 40px;
			font-weight: 700;
			color: $gray-8;
			margin-bottom: 20px;
			display: block;
		}
		label {
			display: block;
			color: $gray-3;
			margin-bottom: 0;
		}
	}
}


/* counter with border */
.counter.rounded {
	border-color: rgba($white, .2) !important;
}


@media (max-width:767px) {

	.counter {
	  margin-bottom: 30px;
	}

}
