/*****************************
	Portfolio
*****************************/
.my-shuffle-container{
    margin-left: -15px;
    margin-right: -15px;

    .grid-item {
		width: 33.33%;
		padding: 15px;
	}
}

.portfolio-item{
	position: relative;

	.portfolio-overlay {
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    align-items: flex-end;
    transition: all 0.5s ease;

    .portfolio-description{
  	  position: absolute;
  	  width: 100%;
	    height: 100%;
	    top: 0%;
	    left:0%;

    	.portfolio-info{
	    	position: absolute;
    		top: 50%;
		    left: 50%;
		    transform: translate(-50%, -50%);
		    z-index: 99;
		    transition: all 0.5s ease;
		    text-align: center;

			  .portfolio-title{
		    	font-size: 20px;
		    	font-weight: 700;
		    	display: block;
		    	color: $white;
		    	margin-bottom: 15px;
		    }

		    .icon-btn{
		    	width: 40px;
			    height: 40px;
			    line-height: 40px;
			    display: inline-block;
			    text-align: center;
			    background: $primary;
			    color: $white;
			    border-radius: 50px;
		    	i{
				    font-size: 14px;
						color: $white;
			    }
		    }
	    }
		}
	}
	&:hover{
		.portfolio-overlay{
			opacity: 1;
			background: rgba($primary,0.5);
		}
	}
}

.filters-group{
	display: table;
    margin: 0 auto 50px;
	text-align: center;
	button{
	  margin: 0 10px 10px;
      padding: 14px 25px;
      cursor: pointer;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      background-color:$white;
      color: $gray-8;
		  border: 2px solid $gray-2;
		  border-radius: $border-radius;
      transition: all 0.3s ease;
    &:hover{
    	background-color: $primary;
    	color: $white;
		border: 2px solid rgba( $primary,1);
     }
	}
	button.active{
		background-color: $primary;
	  color: $white;
		border: 2px solid rgba( $primary,1);
	}
}


/*************************************
         shuffle
 *************************************/
.my-shuffle-container.columns-1 {
	.grid-item {
		width: 100%;
		padding: 15px;
	}
}

.my-shuffle-container.columns-2 {
	.grid-item {
		width: 50%;
		padding: 15px;
		float: left;
	}
}

.my-shuffle-container.columns-3 {
	.grid-item {
		width: 33.33333333%;
		padding: 15px;
	}
}

.my-shuffle-container.columns-4 {
	.grid-item {
		width: 25%;
		padding: 10px;
	}
}

.my-shuffle-container.columns-5 {
	.grid-item {
		width: 20%;
		padding: 15px;
	}
}

.my-shuffle-container.columns-6 {
	.grid-item {
		width: 16.666666%;
		padding: 0px;
	}
}

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
	width: 100%;
}


/* Responsive */

@media (max-width:991px) {

	.filters-group button{
		margin: 0 5px 10px;
	}

	.filters-group button:first-child{
		margin-left: 0;
	}

	.filters-group button:last-child{
		margin-right: 0;
	}

}


@media (max-width:767px) {

	.my-shuffle-container .grid-item,
	.my-shuffle-container.columns-3 .grid-item,
	.my-shuffle-container.columns-4 .grid-item,
	.my-shuffle-container.columns-5 .grid-item,
	.my-shuffle-container.columns-6 .grid-item {
		width: 50%;
	}

}


@media (max-width:575px) {

	.my-shuffle-container .grid-item,
	.my-shuffle-container.columns-2 .grid-item,
	.my-shuffle-container.columns-3 .grid-item,
	.my-shuffle-container.columns-4 .grid-item,
	.my-shuffle-container.columns-5 .grid-item,
	.my-shuffle-container.columns-6 .grid-item {
		width: 100%;
	}

}
