/*****************************
  Blog
*****************************/
.blog-post {
	margin-bottom: 25px;
	.blog-post-image{
		flex: 0 0 200px;
		position: relative;
		img{
			border-radius:$border-radius;
		}
	}

	.blog-post-meta {
		padding: 0px 0px 12px 0px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.blog-post-time,
		.blog-post-author,
		.blog-post-comment,
		.share-box {
			margin: 0 8px 0 0;
		}

		.blog-post-author {
			img {
				margin: 0 5px;
				height: 20px;
				border-radius: 50%;
			}
		}

		span {
			font-size: 13px;
		}

		a {
			font-size: 13px;
			color: $gray-3;

			&:hover {
				color: $primary;
			}
		}

		i {
			padding-right: 5px;
		}
	}

	.blog-post-content {
    padding: 20px 15px;
    background: #fff;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
    position: relative;
    top: -70px;
    margin: 15px;
    border-radius: 5px;

		.blog-post-details {
			padding: 25px 5px;
			.blog-post-title {
				margin-bottom: 0px;
				color: $primary;
				a{
					color: $gray-9;
				}
			}
		}

		.blog-post-info{
			display: flex;
			margin-bottom: 30px;
			div{
				margin-right: 20px;
				&:last-child{
					margin-right: 0px;
				}
				a{
					i{
						margin-right: 10px;
					}
				}
			}
		}
		.blog-post-social{
			display: flex;
			margin-bottom: 30px;
			span{
				padding-right:15px;
			}
			ul{
				margin-bottom: 0;
				li{
					display: inline-block;
					float: left;
					padding: 0 10px;
					a{
						color: $gray-8;
						font-size: 18px;
						&:hover{
							color:$primary;
						}
					}
				}
			}
		}
	}

	.blockquote{
		position: relative;
		padding-top: 40px;
		padding-bottom: 40px;
		padding-right: 30px;
		z-index: 9;
		border-radius: $border-radius;
		.blockquote-content{
			position: relative;
			.blockquote-quote{
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				i{
					font-size: 78px;
					line-height: 78px;
					top: 0;
					color: rgba($primary,0.2);
				}
			}
		}

		.blockquote-author{
			.blockquote-name{
				display: flex;
				.text-muted{
					margin-left: 10px;
				}
			}
		}
	}
}



/* blog post style 02 */
.blog-post-style-02 {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius;
    &:before{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, black 100%);
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    content: "";
     z-index: 1;
  }
 
  .blog-post-img{
    img{ 
       transition: all 0.3s ease-in-out;
    }
  }
  .blog-post-info{
    position: absolute;
    display: block;
    bottom: 0;
    padding: 30px;
    width: 100%;
    z-index: 2;
    .blog-post-category{
      display: inline-block;
      background: $primary;
      padding: 3px 10px; 
      font-size: 12px;
      margin-bottom: 10px;
      border-radius: $border-radius;
      a {
        display: inline-block;
        color: $white;
        margin-right: 3px;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          color: $gray-7;
        }
      }
    }
    .blog-post-title{
      color: $white;
      margin: 0;
      &:hover {
        a {
          color: $primary;

        }
      }
      a {
       	&:focus {
          	color: $primary;
          }
       }
    }
  }
  &:hover {
    .blog-post-img {
      img {
        transform: scale(1.1);
      }
    }
  }

}




/* blog-post-02 */
.blog-post-02{
	.blog-post-meta{
		.blog-post-author{
			position: relative;
			padding-right: 30px;

			&:before{
				position:absolute;
				content:"";
				height: 1px;
				width: 20px;
				background: $gray-2;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
			}
		}
	}
}


/* blog post style 03 */
.blog-post-style-03 {
	margin-bottom: 0;
	border-radius: $border-radius;
	overflow: hidden;
	.blog-post-image {
		img {
			border-radius: 0px;
		}
	}
	.blog-post-content {
		position: inherit;
		top: inherit;
		margin: 0;
		border-radius: 0;
		box-shadow: none;
		padding: 25px 25px;
		.blog-post-details {
			padding: 0;		
		}
	}
	
}

.blog-post-carousel{
	.blog-post-style-03{
      margin-bottom: 20px;
	}
}

/* blog-post-quote */
.blog-post-quote {
	.blockquote {
		padding: 40px 40px 0 40px;

		p {
			font-size: 14px;
			font-style: italic;
		}

		i {
			font-size: 30px;
			margin-bottom: 20px;
			color: darken($primary, 10%);
		}

		cite {
			font-size: 15px;
		}
	}

	.blog-post-link {
		a {
			color: $white;
			&:hover {
				color: $gray-8;
			}
		}
	}

	.blog-post-content {
		.blog-post-footer {
			border-color: darken($primary, 7%);

			a {
				color: $white;
				transition: all 0.3s ease-in-out;
				i {
					transition: all 0.3s ease-in-out;
				}
				&:hover {
					color: $gray-8;

					i {
						color: $gray-8;
					}
				}
			}
			span {
				color: $white;
			}
		}
	}

	.share-box {
		.share-box-social {
			background: $white;

			li {
				a {
					color: $gray-8;
					&:hover {
						color: $primary;
					}
				}
			}
			&:before {
				border-color: $white transparent transparent transparent;
			}
		}
	}
}

.blog-post-navigation{
	.post-navigation{
		.nav-link{
			position: relative;
			a{
				i{
					position: absolute;
					font-size: 24px;
					color:  $gray-8;
					top: 50%;
					transform: translateY(-50%);
				}
				span{
					display: block;
					color: $gray-8;
					transition: all 0.5s ease-in-out;
				}

				.nav-title{
					font-size: 18px;
				}
			}

			.nav-previous{
				position: absolute;
				left: 0;
				a{
					position: relative;
					display: block;
					padding-left: 90px;
					padding-top: 11px;
					padding-bottom: 11px;
					i{
						left: 20px;
					}
					&:before{
						position: absolute;
						content: "";
						top: 50%;
						transform: translateY(-50%);
						left: 0;
						width: 60px;
						height: 100%;
						background-color: $gray-1;
						border-radius: $border-radius;
						transition: all 0.5s ease-in-out;
					}
					&:hover{
						i{
							color:$white;
						}
						span{
							color:$primary;
						}
						&:before{
							background-color:$primary;
						}
					}
				}
			}
			.nav-next{
				position: absolute;
				right: 0;
				text-align: right;
				a{
					position: relative;
					display: block;
					padding-right: 90px;
					padding-top: 11px;
					padding-bottom: 11px;
					i{
						right: 20px;
					}
					&:before{
						position: absolute;
						content: "";
						top: 50%;
						transform: translateY(-50%);
						right: 0;
						width: 60px;
						height: 100%;
						background-color: $gray-1;
						border-radius: $border-radius;
						transition: all 0.5s ease-in-out;
					}
					&:hover{
						i{
							color:$white;
						}
						span{
							color:$primary;
						}
						&:before{
							background-color:$primary;
						}
					}
				}
			}
		}
	}
}

.blog-detail{
	.comments-01{
	  display: flex;
  	.comment-author{
  		width: 90px;
  		img{
	  		width: 100%;
	  		border-radius: $border-radius;
  		}
  	}

    .comment-content{
      border: 1px solid $gray-1;
      background-color: $gray-1;
      padding: 15px;
      margin-left: 20px;
      margin-bottom: 20px;
      width: 100%;
      border-radius: $border-radius;
      .reviews{
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        .meta{
        	font-size: 16px;
        	strong{
        		color: $gray-8;
        	}
        }
        p{
        	margin-bottom: 0;
        }

        .port-social{
          margin-left: auto;
          border-radius: 0;
          color: $primary;
          border:1px solid $primary;
          padding: 5px 15px;
          display: block;
          border-radius: $border-radius; 
        }
      }
    }
	}
	.comments-02{
	  display: flex;
	  padding-left: 100px;
	  .comment-author{
	  		width: 90px;
		  img{
	  		width: 100%;
	  		border-radius: $border-radius;
		  }
		}

    .comment-content{
      border: 1px solid $gray-1;
      padding: 15px;
      margin-left: 20px;
      margin-bottom: 20px;
      width: 100%;
      background-color: $gray-1;
      border-radius: $border-radius; 
      .reviews{
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        .meta{
        	font-size: 16px;
        	strong{
        		color: $gray-8;
        	}
        }

        p{
        	margin-bottom: 0;
        }

        .port-social{
          margin-left: auto;
          border-radius: 0;
          color: $primary;
          border:1px solid $primary;
          padding: 5px 15px;
          display: block;
          border-radius: $border-radius;
        }
      }
    }
	}
	.leave-reply-form{
		background-color:$white;
		box-shadow: $box-shadow;
		border-radius: $border-radius;
		padding: 40px 30px;
	}
}


/* Sidebar */
.sidebar {

	.widget {
		margin-bottom: 30px;
		padding: 40px 30px;
		background-color:$white;
		box-shadow: $box-shadow;
		border-radius: $border-radius; 
		&:last-child {
			margin-bottom: 0;
		}

		.widget-title {
			margin-bottom: 20px;
		}

		.search {
			position: relative;
			input {
				padding-right: 64px;
				height: 50px;
				padding-left: 20px;
			}
			i {
				position: absolute;
				right: 0;
				top: 0;
				background: $primary;
				color: $white;
				padding: 18px 20px;
				cursor: pointer;
				margin: 0;
				border-radius: 0 $border-radius $border-radius 0px;
			}
		}

		.recent-posts{
			a{
				color:$gray-8;
				&:hover {
					color: $primary;
				}
			}

			.date{
				color: $gray-4;
				margin-top: 10px;
			}
		}

		.widget-contact-info{
			ul{
				li{
					margin-bottom: 30px;
					i{
						font-size: 16px;
					}
					span{
						margin-left: 20px;
					}
				}
			}
		}

		.widget-follow{
			ul{
				margin-bottom: 0;
				li{
					display: inline-block;
					margin-right: 20px;
					&:last-child {
						margin-right: 0px;
					}
					a{
						font-size: 18px;
						color:  $gray-3;
						&:hover {
							color: $primary;
						}
					}
				}
			}
		}

		ul.list-style {
			li {
				a {
					display: flex;
					color: $gray-3;
					width: 100%;
					&:hover {
						color: $primary;
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.gallery {
			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 19%;
					margin: 0 1% 1% 0;

					&:nth-child(5n+5) {
						margin-right: 0;
					}
				}
			}
		}

		.social {
			ul {
				li {
					padding: 10px 10px 10px 20px;
					border: 1px solid $border-color;
					display: flex;
					margin-bottom: 10px;
					align-items: center;
					a {
						color: $gray-8;
						font-weight: 600;
						text-transform: capitalize;

						i {
							width: 15px;
						}
					}

					.follow {
						background: $gray-1;
						padding: 3px 18px;
						font-size: 12px;

						&:hover {
							background: $gray-8;
							color: $white;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				.facebook {
					a {
						color: #466ca9;
					}

					.follow {
						&:hover {
							background: #466ca9;
						}
					}
				}

				.twitter {
					a {
						color: #20b5e6;
					}

					.follow {
						&:hover {
							background: #20b5e6;
						}
					}
				}

				.youtube {
					a {
						color: #d92c20;
					}

					.follow {
						&:hover {
							background: #d92c20;
						}
					}
				}

				.linkedIn {
					a {
						color: #13799f;
					}

					.follow {
						&:hover {
							background: #13799f;
						}
					}
				}
			}
		}

		.popular-tag {
			ul {
				li {
					display: inline-block;
					margin-bottom: 4px;

					a {
						padding: 6px 20px;
						display: block;
						border: 1px solid $border-color;
						color: $gray-3;

						&:hover {
							color: $primary;
							border-color: $primary;
						}
					}
				}
			}
		}
	}
}

ul.list-style-underline{
	li{
		border-bottom: 1px solid $border-color;
		padding-bottom: 15px;
		margin-bottom: 15px;
		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0px;
		}
	}
}

.blog-detail {
	.blog-post-content {
		text-align: left;
	}
}

.navigation {
	.nav-links {
		display: flex;
		.nav-previous {
			width: 50%;
			border: 1px solid $border-color;
			margin-right: 30px;
			transition: all 0.5s ease-in-out;

			a {
				display: flex;
				color: $gray-3;

				&:hover {
					.pagi-text {
						color: $white;
						background: $primary;
					}
				}
			}

			.nav-title {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.pagi-text {
				border-right: 1px solid $border-color;
				transition: all 0.5s ease-in-out;
			}

			&:hover {
				background: $gray-1;
			}
		}

		.nav-next {
			width: 50%;
			border: 1px solid $border-color;
			margin-right: 30px;
			margin-right: 0;
			text-align: right;
			transition: all 0.5s ease-in-out;

			a {
				display: flex;
				color: $gray-3;
				padding-left: 15px;

				&:hover {
					.pagi-text {
						color: $white;
						background: $primary;
					}
				}
			}

			.nav-title {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				margin-left: auto;
			}

			.pagi-text {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				border-left: 1px solid $border-color;
			}

			&:hover {
				background: $gray-1;
			}
		}

		.pagi-text {
			display: inline-block;
			padding: 12px 25px;
			color: $gray-3;
			transition: all 0.5s ease-in-out;
		}

		.nav-title {
			margin: 12px 20px;
		}
	}
}

@media (max-width:1199px) {
	.blog-post .blog-post-content .blog-post-details {
	  padding: 10px 10px;
	}

	.blog-post-style-03 {
		.blog-post-content {
			.blog-post-details {
				padding: 0;
			}
		}
	}

}
@media (max-width:767px) {

	.blog-post-navigation .post-navigation .nav-link .nav-previous a{
		padding-left: 70px;
	}

	.blog-post-navigation .post-navigation .nav-link .nav-next a{
		padding-right: 70px;
	}

	.blog-post-style-03 {
		.blog-post-content {
			padding: 20px 15px;
		}
	}

}


@media (max-width:575px) {

	.blog-post-navigation .post-navigation .nav-link .nav-previous{
		position: relative;
	}

	.blog-post-navigation .post-navigation .nav-link .nav-previous a{
		padding-left: 75px;
	}

	.blog-post-navigation .post-navigation .nav-link .nav-next a{
		padding-right: 75px;
		margin-top: 15px;
	}

	.blog-detail .comments-01 .comment-content .reviews .meta,
	.blog-detail .comments-02 .comment-content .reviews .meta{
		font-size: 14px;
	}

	.blog-detail .comments-02{
		padding-left: 10px;
	}

	.pagination .page-item .page-link{
		font-size: 14px;
    font-weight: 600;
    padding: 8px 10px;
    margin-right: 12px;
	}
}
