/*****************************
  Countdown
*****************************/
.countdown {
	padding:20px;
	box-shadow: $box-shadow;
	text-align: center;
	background: $primary;
	border-radius: $border-radius;
	span {
		font-size: 49px;
		line-height: 60px;
		font-weight: 700;
		color: $white;
	}
	p {
		font-size: 18px;
		text-transform: capitalize;
		margin-bottom: 0;
		font-weight: 700;
		color: $white;
	}
}


@media (max-width:991px) {

	.countdown span {
		font-size: 36px;
	}
}


@media (max-width:767px) {

	.countdown {
    padding: 20px 20px;
		span{
	    font-size: 32px;
	    font-weight: 600;
		}
	}
}
