/*****************************
	Testimonial
*****************************/
.testimonial-item {
	position: relative;
	align-items: center;
	padding: 60px 55px;
	.testimonial-avatar{
		flex:0 0 105px;
		width:105px;
		height:105px;
		border: 5px solid $white;
		border-radius: 100%;
		margin: 0 auto;
		margin-bottom:20px;
	}

	.testimonial-content{
		flex:0 0 67%;
		font-size: 18px;
		line-height: 24px;
		font-style: italic;
		margin-left: 30px;
		margin-right: 50px;
	}

	.testimonial-author{
		.testimonial-name{
			.testimonial-quote{
				font-size: 48px;
				line-height: 48px;
				color: rgba($secondary,0.3);
			}

			h6{
				color:$secondary;
		    font-size: 18px;
			}

			span{
				color:$gray-9;
				font-weight: 700;
			}
		}
	}
}

.testimonial-item.text-center{
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 30px;

  .testimonial-avatar{
    display: inline-block;
    flex: inherit;
    margin-bottom: 20px;
    box-shadow: $box-shadow;
  }

  .testimonial-content{
    flex: inherit;
    color: $gray-8;
    margin-bottom: 30px;
    margin-right: 0;
    margin-left: 0;
  }

  .testimonial-author{
    .testimonial-name{
      span{
        color: $primary;
      }
    }
  }
}

.testimonial-style-02 {
	.testimonial-item {
		padding: 0;
		text-align: left;
		.testimonial-content {
			background-color: $white;
			font-size: 15px;
			padding: 30px;
			line-height: 26px;
			margin: 0;
			font-style: normal;
			border-radius: $border-radius-10;
			position: relative;
			&:after {
				top: 100%;
				left: 60px;
				border: solid transparent;
				content: "";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(136, 183, 213, 0);
				border-top-color: $white;
				border-width: 10px;
			}
			&:before {
				top: 100%;
				left: 50%;
				content: "";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(194, 225, 245, 0);
				border-top-color: #c2e1f5;
				border-width: 36px;
				margin-left: -36px;
			}
			p {
				margin-bottom: 0;
			}
		}
		.testimonial-author {
			display: flex;
			align-items: center;
			margin: 30px 0px 0 30px;			
			.testimonial-avatar {				
				flex: 0 0 80px;
				margin: 0px 20px 0px 0px;
				width: 80px;
				height: 80px;
				border: none;
			}
			.testimonial-name {
				h6 {
					color: $white;
					font-size: 16px;
					font-weight: normal;
				}
				span {
					color: $white;
					font-weight: normal;
				}
			}
		}
	}
}

.testimonial-style-03{
	.testimonial-item{ 
		.testimonial-content{ 
			margin-left: 0px;
			p{
				font-size: 22px;
				color: $white;
			}
		}
		.testimonial-author{
			display: flex;
			align-items:center;
				.testimonial-avatar{
					-webkit-box-flex: 0;
			    -ms-flex: 0 0 105px;
			    flex: 0 0 75px;
			    width: 75px;
			    height: 75px;
			    border: 5px solid $white;
			    border-radius: 100%;
			    margin:0;
			    margin-bottom:0px;
			    margin-right: 20px;
				}
				.testimonial-name{
					span{
						color: $white;
					}
					h6{
						color: $gray-9;
					}
				}
			}
		}
	}

@media (max-width:1350px) {

	.testimonial-item .testimonial-content{
		flex: 0 0 68%;
	}

}


@media (max-width:1199px) {

	.testimonial-item .testimonial-content{
		flex: 0 0 60%;
	}

}


@media (max-width:991px) {

	.testimonial-item .testimonial-content{
		flex: 0 0 56%;
	}

	.testimonial-style-02 {
		.owl-nav {
			display: none;
		}
	}

}


@media (max-width:767px) {

	.testimonial-item{
		display: block;
		text-align: center;
		padding: 50px 30px;
	}

	.testimonial-item .testimonial-avatar{
		margin: 0 auto;
		margin-bottom:20px;
	}

	.testimonial-item .testimonial-content{
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.testimonial-style-02 {
		.testimonial-item {
			.testimonial-content {
				font-size: 14px;
				padding: 15px;
				line-height: 24px;
			}
		}
	}

	.testimonial-style-03 .testimonial-item .testimonial-content{
		text-align: left;
	}
	.testimonial-style-03 .testimonial-item{
		text-align: left;
	}

}


@media (max-width:575px) {

	.testimonial-item{
		padding: 60px 20px;
	}
	.testimonial-item .testimonial-content{
		margin: 0 auto;
	}

}