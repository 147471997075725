// Font family
$font-base: 		'Fira Sans', sans-serif;
$font-hedding:	'Fira Sans', sans-serif;

// Colors
$body-color:		#666666;
$primary:				#0576ee;
$secondary:     #0576ee;  //secondary color
$secondary-02:  #06816c;  //secondary color
$white:					#ffffff;
$gray-1: 				#f6f6f6; 	// bg light
$gray-2: 				#dfdfdf;
$gray-3: 				#666666; 	// body-text
$gray-4: 				#999999;
$gray-5: 				#3a4957;
$gray-6: 				#293745;
$gray-7: 				#1d2936;
$gray-8: 				#333333; 	// Hedding color
$gray-9: 				#000f2d; 	// bg color
$black:  				#000000;

$border-color: 	#eeeeee;

$bg-success:    #28a745;  //secondary color
$bg-info:       #d0edeb;  //secondary color

// For button and input border radius
$border-radius: 4px;
$border-radius-10: 10px;

// Box shadow
$box-shadow:	  0px 1px 30px 0px rgba($black, .08);
